import type { AxiosRequestConfig } from "axios";
import { api } from "@/api";
import { type Template } from "@cna/api/editor";

import {
  type BodyTextsProps,
  type FooterTextsProps,
  INITIAL_TEXTS,
  type TextFieldValues,
  useCommonGeneralStore,
} from "@cna/common";
import type { Ref } from "vue";

export const useTextsStore = (currentProjectId: Ref<number | null>) => {
  const commonGeneral = useCommonGeneralStore();

  const fetchTexts = async (language: string, config?: AxiosRequestConfig) => {
    // For new languageKey we should setup init values
    commonGeneral.texts[commonGeneral.currentLanguage] = INITIAL_TEXTS["en"];
    commonGeneral.texts[commonGeneral.currentLanguage] = (
      await api.v1.projects
        .project(currentProjectId.value!)
        .texts.getLang(language, config)
    ).data.result as unknown as TextFieldValues[string];
  };

  const getFieldTextsForAllLanguages = async (
    templateName: Template,
    fieldName: string
  ) => {
    const { data } = await api.v1.projects
      .project(currentProjectId.value!)
      .texts.getField(templateName, fieldName);

    return data.result.values;
  };

  const updateTextField = async (
    values: Partial<BodyTextsProps | FooterTextsProps>,
    templateName: Template,
    fieldName: string
  ) => {
    await api.v1.projects
      .project(currentProjectId.value!)
      .texts.postField(templateName, {
        field_key: fieldName,
        values,
      });
    await fetchTexts(commonGeneral.currentLanguage);
  };

  return {
    fetchTexts,
    getFieldTextsForAllLanguages,
    updateTextField,
  };
};
