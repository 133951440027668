<template>
  <Page id="email-registration-page" class="email-registration-page">
    <EmailRegistrationPageBodyTwoColumn v-if="isTwoColumn" />
    <EmailRegistrationPageBody v-else />
  </Page>
</template>

<script lang="ts">
import { useEditorStore } from "@/stores/editor";
import {
  EmailRegistrationPageBody,
  EmailRegistrationPageBodyTwoColumn,
  Page,
} from "@cna/common";
import { storeToRefs } from "pinia";
import { defineComponent } from "vue";

export default defineComponent({
  name: "EmailRegistrationPage",
  components: {
    EmailRegistrationPageBody,
    EmailRegistrationPageBodyTwoColumn,
    Page,
  },
  setup() {
    const editorStore = useEditorStore();
    const { isTwoColumn } = storeToRefs(editorStore);

    return {
      isTwoColumn,
    };
  },
});
</script>
