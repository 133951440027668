<template>
  <div class="buttons-group flex-row-center">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.buttons-group:deep(button) {
  margin-right: 5px;
  margin-left: 5px;
}
.buttons-group:deep(button:first-child) {
  margin-right: 5px;
  margin-left: 0px;
}
.buttons-group:deep(button:last-child) {
  margin-left: 5px;
  margin-right: 0px;
}
</style>
