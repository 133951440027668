<template>
  <SettingsCreateRow
    :row-quantity="rowQuantity"
    @create="onCreate"
    :show-create-row="showNewLanguageRow"
  >
    <template #button-title> + Add Language </template>
    <template #default>
      <div class="languages-section__select-new-language flex-row-center">
        <div class="languages-section__column">
          <span class="languages-section__language-title"> New language </span>
        </div>
        <EditorDropdown
          class="languages-section__language-dropdown"
          :items="availableForSelectionLanguageTags"
          :on-change="onSelectLanguage"
          :current-option-key="availableForSelectionLanguageTags[0].key"
        />
        <EButtonGroup>
          <EButton size="small" color="accent" @click="onCancel">
            Cancel
          </EButton>
          <EButton size="small" @click="onAdd">Add</EButton>
        </EButtonGroup>
      </div>
    </template>
  </SettingsCreateRow>
</template>

<script lang="ts">
import { EButton, EButtonGroup } from "@/components/button";
import EditorDropdown from "@/components/dropdown/EditorDropdown.vue";
import useAvailableFoSelectionLanguageTags from "@/routes/settings/languageSection/useAvailableFoSelectionLanguageTags";
import SettingsCreateRow from "@/routes/settings/sectionElements/SettingsCreateRow.vue";
import { useEditorStore } from "@/stores/editor";
import { storeToRefs } from "pinia";
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  name: "AddNewLanguageRow",
  components: {
    EditorDropdown,
    EButton,
    EButtonGroup,
    SettingsCreateRow,
  },
  emits: ["update:option"],
  setup(props, { emit }) {
    const showNewLanguageRow = ref(false);
    const editorStore = useEditorStore();
    const { sortedLanguages } = storeToRefs(editorStore);
    const newLanguageTag = ref();
    const { availableForSelectionLanguageTags } =
      useAvailableFoSelectionLanguageTags(sortedLanguages);

    const onCancel = () => (showNewLanguageRow.value = false);
    const onAdd = () => {
      newLanguageTag.value
        ? emit("update:option", newLanguageTag.value)
        : emit("update:option", availableForSelectionLanguageTags.value[0].key);
      showNewLanguageRow.value = false;
    };

    const rowQuantity = computed(() => sortedLanguages.value.length);

    const onCreate = () => {
      showNewLanguageRow.value = true;
    };

    const onSelectLanguage = (value: string) => {
      newLanguageTag.value = value;
      editorStore.fetchAllLanguages();
    };

    return {
      rowQuantity,
      onCreate,
      availableForSelectionLanguageTags,
      onSelectLanguage,
      showNewLanguageRow,
      onCancel,
      onAdd,
    };
  },
});
</script>
