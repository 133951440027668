<template>
  <div class="options">
    <button
      class="option"
      :class="{
        'option--active':
          option.value === valueSync ||
          (!valueSync && option.value === defaultValue),
      }"
      v-for="option in options"
      @click="valueSync = option.value"
      @keyup.enter="valueSync = option.value"
      :key="option.value"
    >
      {{ option.label }}
    </button>
  </div>
</template>

<script lang="ts">
import { useVModel } from "@vueuse/core";
import { defineComponent, type PropType } from "vue";

export const editWidthOptions = [
  { value: "100%", label: "Full width" },
  { value: "64rem", label: "Auto" },
];

export default defineComponent({
  name: "OptionsGroup",
  props: {
    options: {
      type: Array as PropType<Array<{ label: string; value: string }>>,
      required: true,
    },
    defaultValue: {
      type: String,
    },
    value: {
      type: String,
      required: true,
    },
  },
  emits: {
    "update:value": (v: string) => v,
  },
  setup(props, ctx) {
    const valueSync = useVModel(props, "value", ctx.emit, { passive: true });

    return {
      valueSync,
    };
  },
});
</script>

<style lang="scss" scoped>
.options {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
}

.option {
  --border: 1px solid #dbe5e9;
  border: var(--border);
  padding: 8px 16px;
  cursor: pointer;
  flex: 1;
  text-align: center;
  border-collapse: collapse;

  &:hover,
  &:focus {
    outline: none;
    background-color: $grey2;
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:not(:first-child) {
    margin-left: -0.5px;
    border-left-color: transparent;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:last-child:not(:nth-child(2)) {
    flex: 0;
  }

  &--active {
    background-color: $dark-blue;
    border-color: $dark-blue;
    color: $white;
    &:hover,
    &:focus {
      background-color: $grey2;
      color: $dark-blue;
      border-color: #dbe5e9;
      border-left-color: transparent;
    }
  }
}
</style>
