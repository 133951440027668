<template>
  <Teleport to="#right-panel">
    <div class="right-panel-header">{{ title }}</div>
    <div class="right-panel-content" :class="{ 'overflow-scroll-y': scrolled }">
      <slot />
    </div>
    <div class="right-panel__actions">
      <EButton
        title="Apply Changes"
        color="primary"
        size="small"
        block
        @click="onApplyChanges"
      />
    </div>
  </Teleport>
</template>

<script lang="ts">
import EButton from "@/components/button/EButton.vue";
import { defineComponent, type PropType } from "vue";

export default defineComponent({
  name: "RightPanelModal",
  components: {
    EButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    scrolled: {
      type: Boolean,
      required: false,
      default: false,
    },
    onApplyChanges: {
      type: Function as PropType<(...args: any[]) => any>,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.right-panel-header {
  padding: 12px 20px;
  gap: 10px;
  color: #1d4671;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: -0.6px;
  font-family: $primary-font;
}

.right-panel-content {
  height: calc(100vh - 112px);
  color: #1d4671;
  background-color: $white;
}

.right-panel-content:deep(.range-slider) {
  display: flex;
  width: 100%;
  padding: 0;
}

.right-panel__actions {
  display: flex;
  justify-content: flex-end;
  padding: 8px 20px;
  border-top: 1px solid $grey3;
}
</style>
