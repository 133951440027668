<template>
  <div
    v-if="isShowLanguageDropdown"
    v-click-outside="closeMenu"
    class="language-dropdown"
  >
    <div
      ref="selectedItemRef"
      v-debounce:300="selectItemBySearch"
      class="language-dropdown__selected-language"
      tabindex="0"
      @click="toggle"
      @keydown.enter="toggle"
      @keydown.space="toggle"
      @keydown.exact="comboboxKeyboardHandler"
      @keydown.esc="closeMenu"
      @keydown.shift.tab="closeMenu"
      @keyup="searchByCharacterHandler"
    >
      <span class="language-dropdown__icon-globe icon-globe"></span>
      <span class="language-dropdown__selected-country-iso">
        {{ currentOptionKey }}
      </span>
      <span class="language-dropdown__icon-arrow icon-dropdown"></span>
    </div>
    <ul v-if="areMenuItemsShown" class="language-dropdown__menu-list">
      <li
        v-for="(item, index) in items"
        ref="menuItemsRefs"
        :key="item.key"
        v-debounce:300="selectItemBySearch"
        class="language-dropdown__menu-item"
        :class="menuItemClass(item)"
        :tabindex="index"
        @keydown.tab.exact="moveNext"
        @keydown.shift.tab="movePrev"
        @keydown.down="moveNext"
        @keydown.up="movePrev"
        @keydown.esc="closeMenu"
        @click="onSelectItem(item.key)"
        @keyup.enter.exact="onSelectItem(item.key)"
        @keyup="searchByCharacterHandler"
      >
        <span>{{ item.value }}</span>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import vClickOutside from "click-outside-vue3";
import { computed, defineComponent, type PropType } from "vue";
import { vue3Debounce } from "vue-debounce";
import { useKeyboardEventsHandler } from "../../hooks/useKeyboardEventsHandler";
import { type LanguageItem, type LanguageItems } from "./types";
import useDropdownSearchHandler from "./useDropdownSearchHandler";

export default defineComponent({
  name: "LanguageDropdown",
  directives: {
    clickOutside: vClickOutside.directive,
    debounce: vue3Debounce({ lock: true }),
  },
  props: {
    items: {
      required: true,
      type: Array as PropType<LanguageItems>,
    },
    onChange: {
      required: true,
      type: Function as PropType<(value: string) => void>,
    },
    placeholder: {
      required: false,
      type: String,
      default: "",
    },
    currentOptionKey: {
      type: String || Number,
      required: false,
      default: "",
    },
  },
  setup(props) {
    const isShowLanguageDropdown = computed(() => props.items.length > 1);

    const {
      searchValue,
      areMenuItemsShown,
      menuItemsRefs,
      selectedItemRef,
      comboboxKeyboardHandler,
      movePrev,
      moveNext,
      closeMenu,
      searchByCharacterHandler,
      toggle,
    } = useKeyboardEventsHandler();

    const { selectItemBySearch } = useDropdownSearchHandler(
      searchValue,
      props.items,
      props.currentOptionKey,
      props.onChange
    );

    const menuItemClass = (item: LanguageItem) =>
      item.key === props.currentOptionKey
        ? "language-dropdown__menu-item--selected icon-tick"
        : "";

    const onSelectItem = (itemKey: string) => {
      props.onChange(itemKey);
      closeMenu();
      selectedItemRef.value.focus();
    };

    return {
      areMenuItemsShown,
      menuItemsRefs,
      selectedItemRef,
      isShowLanguageDropdown,
      searchValue,
      closeMenu,
      comboboxKeyboardHandler,
      movePrev,
      moveNext,
      toggle,
      onSelectItem,
      menuItemClass,
      searchByCharacterHandler,
      selectItemBySearch,
    };
  },
});
</script>

<style lang="scss" scoped>
.language-dropdown {
  position: relative;
  cursor: pointer;
}

.language-dropdown__selected-language {
  position: relative;
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 5px 0 6px;
  z-index: 2;

  &:focus {
    outline: none;
  }

  &:hover:before,
  &:focus:before {
    position: absolute;
    content: "";
    display: block;
    height: calc(100% + 1px);
    width: calc(100% + 1px);
    top: 0;
    left: -50%;
    transform: translateX(50%);
    border: 1px solid var(--header_text_color);
    border-radius: 4px;
    opacity: 0.5;
  }
}

.language-dropdown__icon-globe {
  margin-right: 5px;
  font-size: 18px;
  opacity: 0.6;
  user-select: none;
}

.language-dropdown:hover .language-dropdown__icon-globe {
  opacity: 1;
}

.language-dropdown__selected-language:focus-within
  .language-dropdown__icon-globe {
  opacity: 1;
}

.language-dropdown__icon-arrow {
  margin-left: 5px;
  font-size: 12px;
  user-select: none;
}

.language-dropdown__selected-country-iso {
  position: relative;
  font-size: 16px;
  line-height: 1.5;
  text-transform: uppercase;
  user-select: none;
}

.language-dropdown__menu-list {
  @include reset-list;
  position: absolute;
  top: -2px;
  right: -2px;
  border-radius: 10px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px $grey11;
  background-color: $grey12;
  color: $black;
  z-index: 11;
  overflow: hidden;
}

.language-dropdown__menu-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 13px 12px 27px;
  font-size: 16px;

  &:first-child,
  &:last-child {
    margin: 0;
  }

  &:focus {
    outline: none;
    background-color: $grey-transparent;
  }

  &:hover {
    background-color: $grey-transparent;
  }

  &--selected:before {
    position: absolute;
    left: 10px;
    font-size: 10px;
  }

  span {
    font-family: var(--paragraphs);
  }
}

.language-dropdown__icon-check {
  position: absolute;
  top: 50%;
  left: -13px;
  transform: translateY(-50%);
  font-size: 7px;
}
</style>
