<template>
  <Page id="returning-page" class="returning-page">
    <ReturningPageBody />
  </Page>
</template>

<script lang="ts">
import { Page, ReturningPageBody } from "@cna/common";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ReturningPage",
  components: { ReturningPageBody, Page },
});
</script>
