import { watch } from "vue";
import { useIsPreview } from "./useIsPreview";

export const usePreviewClass = (selector: string) => {
  const isPreview = useIsPreview();

  watch(isPreview, (val) => {
    const app = document.querySelector(selector);
    if (val) {
      app?.classList.add("preview");
      return;
    }

    app?.classList.remove("preview");
  });
};
