import { api } from "@/api";
import { useEditorStore } from "@/stores/editor";
import { useCommonGeneralStore } from "@cna/common";
import type { NotificationReactive } from "naive-ui";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useNotification } from "./use-notification";
import { useIsPreview } from "./useIsPreview";
import { usePermissions } from "./usePermissions";

const toggleFullWidthSecondaryPageFeature = (value: boolean) => {
  const editorStore = useEditorStore();
  const projectId = editorStore.currentProjectId;

  if (!projectId) return;

  api.v1.projects
    .project(projectId)
    .postFeatures({ full_width_secondary_page: value });
};

let currentNotification: NotificationReactive;

export const useFullWidthSecondaryPageLayout = () => {
  const isPreview = useIsPreview();
  const editorStore = useEditorStore();
  const { isDBPortalType } = storeToRefs(editorStore);

  const commonGeneral = useCommonGeneralStore();
  const notification = useNotification();

  const { projectdesignwrite } = usePermissions();
  const { features } = storeToRefs(commonGeneral);
  const route = useRoute();

  const fullWidthFeatureCheck = computed(
    () => features.value.full_width_secondary_page === undefined
  );

  const offerNewLayout = () => {
    currentNotification = notification.confirm({
      title: "New layout available",
      description:
        "Check our improved secondary page layout - try it out today!",
      confirmButtonTitle: "Try now",
      closeButtonTitle: "Close",
      onConfirm: () => {
        toggleFullWidthSecondaryPageFeature(true);
        localStorage.setItem("newLayoutConfirmed", "true");
        window.location.reload();
      },
      onCancel: () => {
        toggleFullWidthSecondaryPageFeature(false);
        notification.destroyAll();
      },
    });
  };

  const confirmNewLayout = () => {
    currentNotification = notification.confirm({
      title: "Confirm your layout choice",
      description:
        "Do you want to keep the new layout or revert to the old one?",
      confirmButtonTitle: "Keep & save",
      closeButtonTitle: "Revert",
      onConfirm: () => {
        localStorage.removeItem("newLayoutConfirmed");
        notification.destroyAll();
      },
      onCancel: () => {
        toggleFullWidthSecondaryPageFeature(false);
        localStorage.removeItem("newLayoutConfirmed");
        notification.destroyAll();
        window.location.reload();
      },
    });
  };

  const handleNewLayoutNotification = () => {
    if (!projectdesignwrite.value) {
      return;
    }

    if (currentNotification) {
      return;
    }

    const isAdminPage = computed(() => route.path?.includes("admin"));

    if (
      !isDBPortalType.value &&
      !isPreview.value &&
      fullWidthFeatureCheck.value &&
      !isAdminPage.value
    ) {
      localStorage.removeItem("newLayoutConfirmed");
      offerNewLayout();
    }

    if (localStorage.getItem("newLayoutConfirmed")) {
      confirmNewLayout();
    }
  };

  return { handleNewLayoutNotification };
};
