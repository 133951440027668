import { defineAsyncComponent, type Component } from "vue";
import * as props from "./components";
import { type default as ConfirmationModalType } from "./components/common/ConfirmationModal.vue";
import { type default as FormsEditingModalType } from "./components/common/FormsEditingModal.vue";
import { type default as LocalPublishVersionModalType } from "./components/common/LocalPublishVersionModal.vue";
import { type default as PlainTextModalType } from "./components/common/PlainTextModal.vue";
import { type default as VehiclesMonitoringModalType } from "./components/common/VehiclesMonitoringModal.vue";
import { type default as ButtonStylesEditingModalType } from "./components/rightPanel/ButtonStylesEditingModal.vue";
import { type default as CheckboxThemeModalType } from "./components/rightPanel/CheckboxThemeModal.vue";
import { type default as EmailRegistrationInputSettingsModalType } from "./components/rightPanel/EmailRegistrationInputSettingsModal.vue";
import { type default as ImagesEditingModalType } from "./components/rightPanel/ImagesEditingModal.vue";
import { type default as PageBackgroundEditingModalType } from "./components/rightPanel/PageBackgroundEditingModal.vue";
import { type default as PageMainBlockEditingModalType } from "./components/rightPanel/PageMainBlockEditingModal.vue";
import { type default as RadioButtonThemeModalType } from "./components/rightPanel/RadioButtonThemeModal.vue";
import { type default as BlockStylesEditingModalType } from "./components/rightPanel/BlockStylesEditingModal.vue";
import { type default as TrafficUsageMessageEditingModalType } from "./components/rightPanel/TrafficUsageMessageEditingModal.vue";
import { type default as BackButtonBlockEditingModalType } from "./components/rightPanel/BackButtonBlockEditingModal.vue";

const rightPanelModal = <T extends Component>(name: string) =>
  defineAsyncComponent<T>(() => import(`./components/rightPanel/${name}.vue`));

const commonModal = <T extends Component>(name: string) =>
  defineAsyncComponent<T>(() => import(`./components/common/${name}.vue`));

export const ButtonStylesEditingModal = rightPanelModal<
  typeof ButtonStylesEditingModalType
>("ButtonStylesEditingModal");

export const ConfirmationModal =
  commonModal<typeof ConfirmationModalType>("ConfirmationModal");

export const EmailRegistrationInputSettingsModal = rightPanelModal<
  typeof EmailRegistrationInputSettingsModalType
>("EmailRegistrationInputSettingsModal");

export const FormsEditingModal =
  commonModal<typeof FormsEditingModalType>("FormsEditingModal");

export const ImagesEditingModal =
  rightPanelModal<typeof ImagesEditingModalType>("ImagesEditingModal");

export const LocalPublishVersionModal = commonModal<
  typeof LocalPublishVersionModalType
>("LocalPublishVersionModal");

export const PageBackgroundEditingModal = rightPanelModal<
  typeof PageBackgroundEditingModalType
>("PageBackgroundEditingModal");

export const PageMainBlockEditingModal = rightPanelModal<
  typeof PageMainBlockEditingModalType
>("PageMainBlockEditingModal");

export const PlainTextModal =
  commonModal<typeof PlainTextModalType>("PlainTextModal");

export const BlockStylesEditingModal = rightPanelModal<
  typeof BlockStylesEditingModalType
>("BlockStylesEditingModal");

export const VehiclesMonitoringModal = commonModal<
  typeof VehiclesMonitoringModalType
>("VehiclesMonitoringModal");

export const CheckboxThemeModal =
  rightPanelModal<typeof CheckboxThemeModalType>("CheckboxThemeModal");

export const RadioButtonThemeModal = rightPanelModal<
  typeof RadioButtonThemeModalType
>("RadioButtonThemeModal");

export const TrafficUsageMessageEditingModal = rightPanelModal<
  typeof TrafficUsageMessageEditingModalType
>("TrafficUsageMessageEditingModal");

export const BackButtonBlockEditingModal = rightPanelModal<
  typeof BackButtonBlockEditingModalType
>("BackButtonBlockEditingModal");

export const modalMap = {
  ButtonStylesEditingModal: [
    ButtonStylesEditingModal,
    props.buttonStylesEditingModalProps,
  ],
  ConfirmationModal: [ConfirmationModal, props.confirmationModalProps],
  EmailRegistrationInputSettingsModal: [
    EmailRegistrationInputSettingsModal,
    props.emailRegistrationInputSettingsModalProps,
  ],
  FormsEditingModal: [FormsEditingModal, props.formsEditingModalProps],
  ImagesEditingModal: [ImagesEditingModal, props.imagesEditingModalProps],
  LocalPublishVersionModal: [
    LocalPublishVersionModal,
    props.localPublishVersionModalProps,
  ],
  PageBackgroundEditingModal: [
    PageBackgroundEditingModal,
    props.pageBackgroundEditingModalProps,
  ],
  PageMainBlockEditingModal: [
    PageMainBlockEditingModal,
    props.pageMainBlockEditingModalProps,
  ],
  PlainTextModal: [PlainTextModal, props.plainTextModalProps],
  BlockStylesEditingModal: [
    BlockStylesEditingModal,
    props.blockStylesEditingModalProps,
  ],
  VehiclesMonitoringModal: [
    VehiclesMonitoringModal,
    props.vehiclesMonitoringModalProps,
  ],
  CheckboxThemeModal: [CheckboxThemeModal, props.checkboxThemeModalProps],
  RadioButtonThemeModal: [
    RadioButtonThemeModal,
    props.radioButtonThemeModalProps,
  ],
  TrafficUsageMessageEditingModal: [
    TrafficUsageMessageEditingModal,
    props.trafficUsageMessageEditingModalProps,
  ],
  BackButtonBlockEditingModal: [
    BackButtonBlockEditingModal,
    props.backButtonBlockEditingModalProps,
  ],
} as const;

export const fieldEditingModalMap = {
  ButtonStylesEditingModal,
  CheckboxThemeModal,
  EmailRegistrationInputSettingsModal,
  ImagesEditingModal,
  PageBackgroundEditingModal,
  PageMainBlockEditingModal,
  BlockStylesEditingModal,
  RadioButtonThemeModal,
  TrafficUsageMessageEditingModal,
};

export type ModalName = keyof typeof modalMap;
export type FieldEditingModalName = keyof typeof fieldEditingModalMap;
