import { api } from "@/api";
import { isNotAuthenticated } from "@/api/helpers";
import router from "@/routes/router";
import { handleFeatures } from "@/utils/featuresUtils";
import { useCommonGeneralStore } from "@cna/common";
import { AxiosError, type AxiosRequestConfig } from "axios";
import { defineStore } from "pinia";
import { useEmailRegistrationStore } from "./hooks/emailRegistration";
import { useImagesStore } from "./hooks/images";
import { useLanguagesStore } from "./hooks/languages";
import { useProjectsStore } from "./hooks/projects";
import { useStylesStore } from "./hooks/styles";
import { useTextsStore } from "./hooks/texts";
import { useVersionsHistoryStore } from "./hooks/versionsHistory";

export const useEditorStore = defineStore("editor", () => {
  const commonGeneral = useCommonGeneralStore();
  const projects = useProjectsStore();
  const languages = useLanguagesStore(projects.currentProjectId);
  const emailRegistration = useEmailRegistrationStore(
    projects.currentProjectId
  );
  const images = useImagesStore(projects.currentProjectId);
  const styles = useStylesStore(projects.currentProjectId);
  const versionsHistory = useVersionsHistoryStore(
    projects.currentProject,
    projects.currentProjectId
  );
  const texts = useTextsStore(projects.currentProjectId);

  const fetchCurrentProject = async () => {
    await api.v1.projects
      .project(projects.currentProjectId.value!)
      .get()
      .then((response) => {
        projects.currentProject.value = response.data.result;
        const { templates, features } = projects.currentProject.value;
        commonGeneral.templates = templates;
        commonGeneral.features = features;
      })
      .catch((error) => {
        if (error instanceof AxiosError && isNotAuthenticated(error.status)) {
          router.push({ name: "login" });
        } else {
          projects.removeCurrentProjectId();
          router.push({ name: "home" });
        }

        throw error;
      })
      .then(() => emailRegistration.fetchAllEmailRegistrationFields());
  };

  const initProjectData = async (versionID?: string) => {
    projects.isProjectDataLoaded.value = false;
    const config: AxiosRequestConfig = { params: {} };

    if (versionID) {
      config.params.version_id = versionID;
    }

    await fetchCurrentProject()
      .then(() => {
        return Promise.all([
          languages.initLanguagesSettings(config),
          styles.fetchStyles(config),
          images.fetchImagesData(config),
          versionsHistory.fetchPublishedDomains(),
          emailRegistration.fetchAllProjectFields(config),
        ]);
      })
      .then(async ([lang]) => {
        await texts.fetchTexts(lang, config);
        handleFeatures(projects.currentProject.value.features);
      })
      .then(() => (projects.isProjectDataLoaded.value = true));
  };

  return {
    ...projects,
    ...languages,
    ...emailRegistration,
    ...images,
    ...styles,
    ...versionsHistory,
    ...texts,
    fetchCurrentProject,
    initProjectData,
  };
});
