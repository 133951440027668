import {
  PAGES_DEPENDS_ON_BODY_TYPE,
  PAGES_DEPENDS_ON_FOOTER_TYPE,
} from "@/constants";
import { type Pages } from "@/pages/types";
import { useEditorStore } from "@/stores/editor";

export const capitalizeFirstLetter = (value: string): string => {
  if (!value) {
    return value;
  }
  return value[0].toUpperCase() + value.slice(1);
};

const DEFAULT_PAGES: Array<Pages> = [
  "welcome",
  "now-connected",
  "contact-us",
  "terms-and-conditions",
  "privacy-policy",
];

export const getProjectPagesDependsOnFooterAndBodyTypes = () => {
  const editorStore = useEditorStore();
  const { footer, body } = editorStore.currentProject.templates;
  const footerPages = [
    ...(PAGES_DEPENDS_ON_FOOTER_TYPE.get(footer) as Pages[]),
  ];
  const bodyPages = PAGES_DEPENDS_ON_BODY_TYPE.get(body);
  if (bodyPages) {
    footerPages?.splice(1, 0, ...bodyPages);
  }
  editorStore.projectPages = footerPages || DEFAULT_PAGES;
};
