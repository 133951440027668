<template>
  <i role="image" :class="['svg-icon', position]">
    <slot />
  </i>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

export default defineComponent({
  props: {
    position: {
      type: String as PropType<"left" | "right" | "default">,
      default: "default",
    },
  },
});
</script>

<style scoped>
.svg-icon {
  line-height: 0;
}
.svg-icon svg {
  width: 100%;
  height: 100%;
}

.svg-icon.left {
  margin-right: 8px;
}
.svg-icon.right {
  margin-left: 8px;
}
</style>
