<template>
  <div
    v-if="show"
    class="traffic-usage-message"
    v-theme-action-box.styles="trafficUsageMessageProps"
  >
    <span class="info">
      <Markdown
        :data="limitMessage"
        :vars="{ used: used, limit: usageData.limit }"
        field="traffic_usage_message_info"
        template="header"
      />
    </span>
    <ProgressBar
      class="progress-bar"
      :ratio-done="used / usageData.limit"
      :bg-color="progressBarBackgroundColor"
      :color="progressBarColor"
      :height="progressBarHeight"
    />
  </div>
</template>

<script lang="ts">
import {
  useCommonGeneralStore,
  useCommonStylesStore,
} from "@cna/common/stores";
import { defaultStyles } from "@cna/common/utils";
import { storeToRefs } from "pinia";
import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";
import ProgressBar from "./ProgressBar.vue";
import {
  injectFetchUsageData,
  injectShowTrafficUsageMessage,
  type UsageData,
} from "./providables";
import { trafficUsageMessageProps } from "../../../utils/themeActionBoxElementProps";

const useTrafficUsageMessageStyles = () => {
  const commonStyles = useCommonStylesStore();

  const { headerStyles } = storeToRefs(commonStyles);

  const styles = computed(() => headerStyles.value.traffic_usage_message ?? {});

  const progressBarBackgroundColor = computed(
    () =>
      styles.value.progress_bar_background_color ??
      defaultStyles.traffic_usage_message_progress_bar_background_color
  );
  const progressBarColor = computed(
    () =>
      styles.value.progress_bar_color ??
      defaultStyles.traffic_usage_message_progress_bar_color
  );
  const progressBarHeight = computed(
    () =>
      styles.value.progress_bar_height ??
      defaultStyles.traffic_usage_message_progress_bar_height
  );

  return {
    progressBarBackgroundColor,
    progressBarColor,
    progressBarHeight,
    trafficUsageMessageProps,
  };
};

const defaultInfoMsg = `
  <p style='font-weight: bold; line-height: 1.43; font-size:14px'>
    You have used {{ used }} MB out of your {{ limit }} MB
      of complimentary data
  </p>`;

const defaultLimitUsedMessage = `
  <p style="line-height: calc(1.4em + 6px); margin-block: 0em;">
    <span style="font-size: 14px;">
      <strong>
        You have used all your data, but you can still surf with limited speed.
      </strong>
    </span>
  </p>`;

export default defineComponent({
  components: {
    ProgressBar,
  },
  setup() {
    const isVisible = injectShowTrafficUsageMessage();
    const fetchUsageData = injectFetchUsageData();

    const commonGeneral = useCommonGeneralStore();

    const infoMsg = computed(
      () =>
        commonGeneral.headerTexts?.traffic_usage_message_info || defaultInfoMsg
    );

    const enabled = computed(
      () => !!commonGeneral.features.traffic_usage_message && isVisible.value
    );
    const usageDataLoading = ref(true);
    const initalFetchCompleted = ref(false);
    const usageData = ref<UsageData>({ limit: 100, used: 0 });

    const getUsageData = () => {
      usageDataLoading.value = true;
      return fetchUsageData()
        .then((res) => {
          usageData.value = res;
        })
        .finally(() => {
          usageDataLoading.value = false;
        });
    };

    let interval: any = undefined;
    onMounted(() => {
      if (!enabled.value) {
        return;
      }
      getUsageData().finally(() => {
        initalFetchCompleted.value = true;
      });

      const intervalTime = __TRAFFIC_USAGE_INTERVAL_SECONDS__;
      interval = setInterval(getUsageData, intervalTime * 1000);
    });

    onUnmounted(() => {
      clearInterval(interval);
    });

    const styles = useTrafficUsageMessageStyles();

    const show = computed(() => enabled.value && initalFetchCompleted.value);

    const used = computed(() =>
      usageData.value.used > usageData.value.limit
        ? usageData.value.limit
        : usageData.value.used
    );

    const totalReached = computed(() => used.value === usageData.value.limit);
    const limitMessage = computed(() =>
      totalReached.value ? defaultLimitUsedMessage : infoMsg.value
    );

    return {
      show,
      usageDataLoading,
      usageData,
      infoMsg,
      used,
      totalReached,
      limitMessage,
      ...styles,
    };
  },
});
</script>

<style scoped lang="scss">
.traffic-usage-message {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  gap: 10px;
  background-color: var(--traffic_usage_message_background_color);
}

.progress-bar {
  flex-grow: 1;
  margin: 5px 0px;
  min-width: 200px;
  max-width: 100%;
}

@media (min-width: 600px) {
  .traffic-usage-message {
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
  }
}
</style>
