<template>
  <div
    class="page__main-block"
    v-theme-action-box.styles="mainProps('now_connected')"
  >
    <div class="page__text-block">
      <div class="page__text">
        <div class="page__markdown">
          <Markdown
            :data="bodyTexts.now_connected_text"
            field="now_connected_text"
          />
          <div class="page__main-block-actions">
            <a v-show="isWithVenueRedirectButton" :href="buttonLink">
              <CommonButton
                :config="{ title: bodyTexts.venue_redirect_button_label }"
                field="venue_redirect_button_label"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useVenueRedirectHelper } from "../../../hooks/useVenueRedirectHelper";
import { useCommonGeneralStore } from "../../../stores/general";
import CommonButton from "../../button/CommonButton.vue";
import { mainProps } from "../../../utils/themeActionBoxElementProps";

export default defineComponent({
  name: "NowConnectedPageContent",
  components: {
    CommonButton,
  },
  setup() {
    const commonGeneral = useCommonGeneralStore();

    const bodyTexts = computed(() => commonGeneral.bodyTexts);
    const isWithVenueRedirectButton = computed(
      () => commonGeneral.isWithVenueRedirectButton
    );
    const { buttonLink } = useVenueRedirectHelper();

    return {
      bodyTexts,
      isWithVenueRedirectButton,
      buttonLink,
      mainProps,
    };
  },
});
</script>
