import {
  type ButtonItemProps,
  type ItemMenuListProps,
  type MenuListProps,
  type RenderProps,
} from "@/components/buttonWithMenuList/types";
import vClickOutside from "click-outside-vue3";
import { defineComponent, h, withDirectives, type PropType } from "vue";

export default defineComponent({
  name: "MenuListWithButtons",
  directives: {},
  props: {
    config: {
      type: Object as PropType<MenuListProps>,
      required: true,
    },
    onClick: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const renderEmpty = () =>
      h(
        "div",
        {
          class:
            "button-with-menu-list__list-item button-with-menu-list__no-items",
        },
        () => props.config.textIfNoItems
      );

    const renderButton = (item: ButtonItemProps) => [
      h(
        "button",
        {
          class: "button-with-menu-list__btn",
          onClick: item.onClick,
          disabled: item.disabled,
        },
        [
          item.icon &&
            h("i", {
              class: ["button-with-menu-list__btn--icon", item.icon],
            }),
          h("span", { class: "button-with-menu-list__btn-title" }, item.title),
        ]
      ),
      item.infoText &&
        h(
          "div",
          { class: "button-with-menu-list__info-text" },
          item.infoText()
        ),
    ];

    const renderManual = (item: RenderProps) => item.render();

    const renderItem = (item: ItemMenuListProps, index: number) => {
      if (item.type === "render") {
        return h(
          "li",
          { key: "render" + index, class: item.liClass, style: item.liStyle },
          renderManual(item)
        );
      }

      return h(
        "li",
        { key: item.title, class: "button-with-menu-list__list-item" },
        renderButton(item)
      );
    };

    const renderList = (items: MenuListProps["items"]) =>
      items.flatMap(renderItem);

    return () => {
      if (props.config.items.length === 0) {
        return renderEmpty();
      }

      const ul = withDirectives(h("ul"), [
        [vClickOutside.directive, props.onClick],
      ]);

      return h(ul, null, renderList(props.config.items));
    };
  },
});
