<template>
  <Page id="email-verification-page" class="email-verification-page">
    <EmailVerificationPageBody />
  </Page>
</template>

<script lang="ts">
import { EmailVerificationPageBody, Page } from "@cna/common";
import { defineComponent } from "vue";

export default defineComponent({
  name: "EmailVerificationPage",
  components: { EmailVerificationPageBody, Page },
});
</script>
