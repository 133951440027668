<template>
  <component :is="templateName" v-theme-action-box.styles="bodyProps" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useTemplateNameForLayout } from "../hooks/useTemplateNameForLayout";
import { bodyProps } from "../utils/themeActionBoxElementProps";
import { getTemplateComponent } from "./getTemplateComponent";

const bodyComponent = <C extends string>(component: C) =>
  getTemplateComponent("body", component);

export default defineComponent({
  name: "BodyLayout",
  components: {
    ...bodyComponent("ConnectWithCheckbox"),
    ...bodyComponent("ConnectWithCheckboxLogoLeftAligned"),
    ...bodyComponent("ConnectWithEmailRegistration"),
    ...bodyComponent("DeutscheBahnFernverkehr"),
    ...bodyComponent("EmailRegistrationTwoColumn"),
  },
  setup() {
    const { templateName } = useTemplateNameForLayout("body");

    return {
      templateName,
      bodyProps,
    };
  },
});
</script>
