<template>
  <component :is="component" v-bind="{ ...props, pageName }" />
</template>

<script lang="ts">
import { computed, defineComponent, type Ref } from "vue";
import { useRouteQuery } from "../../../hooks";
import { useCommonGeneralStore } from "../../../stores";
import { mdFieldAndTemplateProps } from "../../markdown/props";
import CenteredSecondaryPageBody from "./components/CenteredSecondaryPageBody.vue";
import FullWidthSecondaryPageBody from "./components/FullWidthSecondaryPageBody.vue";

export default defineComponent({
  name: "SecondaryPageBody",
  components: {
    CenteredSecondaryPageBody,
    FullWidthSecondaryPageBody,
  },
  props: {
    markdownData: {
      type: String,
      required: true,
    },
    ...mdFieldAndTemplateProps,
    isScrolled: {
      type: Boolean,
      required: false,
      default: false,
    },
    actionBlock: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    const commonGeneral = useCommonGeneralStore();
    const component = computed(() =>
      commonGeneral.features.full_width_secondary_page
        ? "FullWidthSecondaryPageBody"
        : "CenteredSecondaryPageBody"
    );

    const pageName = computed(() => {
      const page = useRouteQuery("page", {
        type: String,
        default: "",
      }) as Ref<string>;

      return !page.value ? "" : page.value.replace(/-/g, "_");
    });

    return {
      component,
      props,
      pageName,
    };
  },
});
</script>
