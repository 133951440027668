<template>
  <Popover ref="popoverRef" position="right" v-model:open="open">
    <template #trigger>
      <EButton
        qa-id="user-menu-trigger"
        @click="toggle"
        size="small"
        shape="square"
        type="ghost"
      >
        <Icon>
          <UserIcon />
        </Icon>
      </EButton>
    </template>
    <BaseUserMenu />
  </Popover>
</template>

<script lang="ts">
import { EButton } from "@/components/button";
import Icon from "@/components/icons/Icon.vue";
import UserIcon from "@/components/icons/UserIcon.vue";
import { Popover } from "@/components/popover";
import { onClickOutside } from "@vueuse/core";
import { defineComponent, ref } from "vue";
import BaseUserMenu from "./BaseUserMenu.vue";

export default defineComponent({
  name: "HeaderSubMenu",
  components: {
    EButton,
    Popover,
    Icon,
    UserIcon,
    BaseUserMenu,
  },
  setup() {
    const open = ref(false);
    const toggle = () => {
      open.value = !open.value;
    };

    const popoverRef = ref();

    onClickOutside(popoverRef, () => {
      open.value = false;
    });

    return {
      popoverRef,
      toggle,
      open,
    };
  },
});
</script>
