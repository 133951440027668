<template>
  <ButtonWithMenuList class="actions-button" :button-config="buttonConfig">
    <template #default="{ closeMenuList }">
      <MenuListWithButtons :config="menuListConfig" :on-click="closeMenuList" />
    </template>
  </ButtonWithMenuList>
</template>

<script lang="ts">
import ButtonWithMenuList from "@/components/buttonWithMenuList/ButtonWithMenuList.vue";
import MenuListWithButtons from "@/components/buttonWithMenuList/menuListsTypes/MenuListWithButtons";
import {
  type ButtonMenuListProps,
  type MenuListProps,
} from "@/components/buttonWithMenuList/types";
import { defineComponent, type PropType } from "vue";

export default defineComponent({
  name: "ActionsColumn",
  components: {
    MenuListWithButtons,
    ButtonWithMenuList,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    buttonConfig: {
      type: Object as PropType<ButtonMenuListProps>,
      required: true,
    },
    menuListConfig: {
      type: Object as PropType<MenuListProps>,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.actions-button:deep(.button-with-menu-list__menu-btn) {
  border-radius: 4px;
  border: solid 1px $grey3;
  padding: 5px 4px 3px;
  font-size: 9px;
}

.actions-button:deep(.button-with-menu-list__btn) {
  font-weight: bold;
}
</style>
