<template>
  <header class="modal-header">
    <h2 class="modal-header__title"><slot /></h2>
    <slot name="actions"> </slot>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ModalHeader",
});
</script>

<style lang="scss" scoped>
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $grey1;
  font-family: $primary-font;
  font-weight: bold;
  font-size: 16px;
  border-bottom: 1px solid $grey3;
  color: #1d4671;
  padding: 16px 21px 15px 21px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.modal-header__title {
  font-family: inherit;
  line-height: 20px;
  font-size: 16px;
}
</style>
