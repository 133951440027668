<template>
  <div
    :class="{
      page: true,
      'with-background': stretchBackground,
    }"
  >
    <HeaderLayout>
      <slot name="header"></slot>
    </HeaderLayout>
    <slot></slot>
    <FooterLayout />
  </div>
</template>

<script lang="ts">
import { useCommonGeneralStore } from "@cna/common/stores";
import { computed, defineComponent } from "vue";
import FooterLayout from "./FooterLayout.vue";
import HeaderLayout from "./HeaderLayout.vue";

export default defineComponent({
  components: {
    HeaderLayout,
    FooterLayout,
  },
  setup() {
    const commonGeneral = useCommonGeneralStore();

    const stretchBackground = computed(
      () => commonGeneral.features.stretch_background
    );

    return {
      stretchBackground,
    };
  },
});
</script>
