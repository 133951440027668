import { headerHeight } from "@cna/common/layouts/globals";
import { footerHeight } from "@cna/common/templates/footer/globals";
import { computed } from "vue";

export function useSecondaryPageHelper(isScrolled?: boolean) {
  const tabindex = computed(() => (isScrolled ? "0" : undefined));
  const footerHeightAsPx = computed(() => `${footerHeight.value}px`);
  const headerHeightAsPx = computed(() => `${headerHeight.value}px`);

  return {
    tabindex,
    footerHeightAsPx,
    headerHeightAsPx,
  };
}
