<template>
  <OneColumnLayout>
    <div
      class="page__main-block"
      v-theme-action-box.styles="mainProps('returning')"
    >
      <div class="page__text-block">
        <div class="page__text">
          <div class="page__markdown">
            <Markdown :data="texts.returning_text" field="returning_text" />
            <form
              class="page__form"
              method="get"
              @submit="onConnectButtonClick"
            >
              <div class="page__text">
                <TermsAndConditionsCheckbox />
              </div>
              <div class="page__main-block-actions">
                <CommonButton
                  :type="connectButtonType"
                  :config="buttonConfig"
                  field="returning_button_label"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </OneColumnLayout>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  type ButtonHTMLAttributes,
  type PropType,
} from "vue";
import type { ReturningPageTexts } from "../../../stores";
import { useCommonGeneralStore } from "../../../stores";
import CommonButton from "../../button/CommonButton.vue";
import { OneColumnLayout } from "../../columns";
import { mainProps } from "../../../utils/themeActionBoxElementProps";
import TermsAndConditionsCheckbox from "../../../templates/body/Content/TermsAndConditionsCheckbox.vue";

export default defineComponent({
  name: "ReturningPageBody",
  components: { TermsAndConditionsCheckbox, CommonButton, OneColumnLayout },
  props: {
    onConnectButtonClick: {
      type: Function as PropType<(event: Event) => void>,
      required: false,
      default: () => {},
    },
    termsLinkElementType: {
      type: String,
      required: false,
      default: "span",
    },
    connectButtonType: {
      type: String as PropType<ButtonHTMLAttributes["type"]>,
      required: false,
      default: "button",
    },
  },
  setup() {
    const commonGeneral = useCommonGeneralStore();
    const texts = computed(() => commonGeneral.bodyTexts as ReturningPageTexts);

    const buttonConfig = computed(() => ({
      title: texts.value.returning_button_label,
    }));

    return {
      buttonConfig,
      texts,
      mainProps,
    };
  },
});
</script>
