<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 4.14285C9.40364 4.14285 8.07141 5.47269 8.07141 7.16071C8.07141 8.84873 9.40364 10.1786 11 10.1786C12.5963 10.1786 13.9286 8.84873 13.9286 7.16071C13.9286 5.47269 12.5963 4.14285 11 4.14285ZM6.07141 7.16071C6.07141 4.41073 8.25694 2.14285 11 2.14285C13.743 2.14285 15.9286 4.41073 15.9286 7.16071C15.9286 9.91069 13.743 12.1786 11 12.1786C8.25694 12.1786 6.07141 9.91069 6.07141 7.16071Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.56054 15.6516C5.57385 16.3199 4.92856 17.3655 4.92856 19.0536C4.92856 19.6059 4.48084 20.0536 3.92856 20.0536C3.37627 20.0536 2.92856 19.6059 2.92856 19.0536C2.92856 16.7475 3.86626 15.0609 5.43892 13.9957C6.95144 12.9712 8.948 12.5893 11 12.5893C13.052 12.5893 15.0485 12.9712 16.5611 13.9957C18.1337 15.0609 19.0714 16.7475 19.0714 19.0536C19.0714 19.6059 18.6237 20.0536 18.0714 20.0536C17.5191 20.0536 17.0714 19.6059 17.0714 19.0536C17.0714 17.3655 16.4261 16.3199 15.4394 15.6516C14.3926 14.9425 12.8534 14.5893 11 14.5893C9.14653 14.5893 7.60737 14.9425 6.56054 15.6516Z"
      fill="white"
    />
  </svg>
</template>
