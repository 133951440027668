<template>
  <LogoImage v-theme-action-box.styles="headerLogoProps" :config="logoConfig" />
</template>

<script lang="ts">
import type { LogoConfig } from "@cna/common/components/types";
import {
  useCommonGeneralStore,
  useCommonImagesStore,
} from "@cna/common/stores";
import { computed, defineComponent } from "vue";
import LogoImage from "../../components/logoImage/LogoImage.vue";
import { headerLogoProps } from "../../utils/themeActionBoxElementProps";

export default defineComponent({
  components: {
    LogoImage,
  },
  setup() {
    const commonGeneral = useCommonGeneralStore();
    const commonImages = useCommonImagesStore();

    const logoConfig = computed(
      () =>
        ({
          imageUrl: commonImages.headerLogo.link,
          type: commonGeneral.typesOfLinksBlocks.headerLogoLink,
        } as LogoConfig)
    );

    return {
      logoConfig,
      headerLogoProps,
    };
  },
});
</script>
