<template>
  <!--Row if domain is not edited -->
  <div v-if="isShowNotEditRow" class="settings-section__row">
    <span class="flex-row-center">
      <span class="domains-section__full-url">{{ fullURL }}</span>
      <span v-tooltip="'Copy URL'" @click="copyUrl">
        <span class="icon-copy" />
      </span>
    </span>
    <EButton v-if="isEditableRow && !disabled" size="small" @click="editRow">
      Edit
    </EButton>
  </div>

  <!--Row if domain is creating or editing-->
  <div v-if="isEdited || isCreating" class="settings-section__row">
    <label>
      <span class="domains-section__protocol">{{ DOMAIN_PROTOCOL }}</span>
      <EditorInput :value="prefix" @update:value="onChangePrefix" />
      <span class="domains-section__suffix">{{ suffix }}</span>
    </label>
    <EButtonGroup>
      <EButton size="small" color="accent" @click="onCancel"> Cancel </EButton>
      <EButton size="small" @click="onSave">Save</EButton>
    </EButtonGroup>
  </div>
  <SettingsEmptyRow v-if="isShowEmptyRow" title="No domain set yet" />
  <ConfirmationModal
    v-if="showConfirmationModal && confirmationModalConfig"
    @close="showConfirmationModal = false"
    :config="confirmationModalConfig"
  />
</template>

<script lang="ts">
import { EButton, EButtonGroup } from "@/components/button";
import EditorInput from "@/components/editorInput/EditorInput.vue";
import { DOMAIN_PROTOCOL } from "@/constants";
import { useNotification } from "@/hooks/use-notification";
import { type ConfirmationModalProps } from "@/modals";
import SettingsEmptyRow from "@/routes/settings/sectionElements/SettingsEmptyRow.vue";
import { useEditorStore } from "@/stores/editor";
import type { Maybe } from "@cna/common";
import { storeToRefs } from "pinia";
import { computed, defineAsyncComponent, defineComponent, ref } from "vue";

export default defineComponent({
  name: "LanguagesSectionRow",
  components: {
    EButton,
    EButtonGroup,
    EditorInput,
    SettingsEmptyRow,
    ConfirmationModal: defineAsyncComponent(
      () => import("@/modals/components/common/ConfirmationModal.vue")
    ),
  },
  props: {
    domain: {
      type: String,
      required: false,
      default: "",
    },
    type: {
      type: String,
      required: false,
      default: "",
    },
    isCreating: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: Boolean,
  },
  emits: ["update:isCreating"],
  setup(props, { emit }) {
    const isEdited = ref(false);
    const editorStore = useEditorStore();
    const { publishedDomains } = storeToRefs(editorStore);
    const notification = useNotification();
    const suffix = computed(() => `.${publishedDomains.value.suffix}`);
    const prefix = ref(props.domain.replace(suffix.value, ""));
    const fullURL = computed(() => `https://${props.domain}`);
    const isEditableRow = computed(() => props.type !== "preview");
    const isShowNotEditRow = computed(() => props.domain && !isEdited.value);
    const isShowEmptyRow = computed(() => !props.domain && !props.isCreating);

    const onCancel = () => {
      isEdited.value = false;
      emit("update:isCreating", false);
    };

    const confirmationModalConfig = ref<ConfirmationModalProps["config"]>();
    const showConfirmationModal = ref(false);

    const onSave = () => {
      confirmationModalConfig.value = {
        confirmAction: async () => {
          prefix.value = prefix.value.toLowerCase();
          const newURL = prefix.value + suffix.value;
          await editorStore.createVersion(`changed domain to ${newURL}`);
          await editorStore.updateDomain(prefix.value);
          await editorStore.fetchCurrentProject();
          onCancel();
        },
        modalTitle: "Update domain?",
        modalText:
          "<p>The domain will be updated after you publish the latest version.</p>",
        buttonsConfigs: {
          confirmButton: {
            buttonTitle: "Confirm",
          },
          cancelButton: {
            buttonTitle: "Cancel",
          },
        },
        textArea: false,
      };

      if (!prefix.value) {
        notification.error({
          title: "Domain prefix can't be empty",
        });
        return;
      }
      showConfirmationModal.value = true;
    };

    const onChangePrefix = (value: Maybe<string>) => {
      prefix.value = (value || "").replace(/\s/g, "");
    };

    const copyUrl = () => {
      navigator.clipboard.writeText(fullURL.value);
      notification.success({
        title: "The URL was copied",
      });
    };
    const editRow = () => (isEdited.value = true);

    return {
      onCancel,
      onSave,
      isShowEmptyRow,
      isShowNotEditRow,
      isEditableRow,
      onChangePrefix,
      copyUrl,
      editRow,
      isEdited,
      prefix,
      suffix,
      fullURL,
      DOMAIN_PROTOCOL,
      showConfirmationModal,
      confirmationModalConfig,
    };
  },
});
</script>

<style lang="scss" scoped>
.domains-section__full-url {
  margin-right: 10px;
}

.domains-section__protocol {
  padding-right: 10px;
  color: $grey13;
}

.domains-section__suffix {
  padding-left: 10px;
  color: $grey13;
}

.settings-section__row:deep(input) {
  min-width: 380px;
}

.icon-copy {
  font-size: 22px;
}
</style>
