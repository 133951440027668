<template>
  <RightPanelModal :title="title" :on-apply-changes="saveInputSettings">
    <RightPanelSection title="Icon color" color-picker>
      <ColorPickerElement
        :color="
          formElementStyleCopy?.color ||
          defaultStyles.form_element_checkbox_style_color
        "
        @update:color="(v) => updateStyleOption('color', v)"
      />
    </RightPanelSection>

    <RightPanelSection title="Border" with-subsections>
      <RightPanelSubsection title="Default state" color-picker flex-row>
        <ColorPickerElement
          :color="
            formElementStyleCopy?.border_color ||
            defaultStyles.form_element_checkbox_style_border_color
          "
          @update:color="(v) => updateStyleOption('border_color', v)"
        />
        <RangeSlider
          :slider-params="widthSliderOptions"
          :init-value="borderWidth"
          @update:option="(v) => (borderWidth = v)"
        />
      </RightPanelSubsection>

      <RightPanelSubsection title="Hover state" color-picker flex-row>
        <ColorPickerElement
          :color="
            formElementStyleCopy?.hover_border_color ||
            defaultStyles.form_element_checkbox_style_hover_border_color
          "
          @update:color="(v) => updateStyleOption('hover_border_color', v)"
        />
        <RangeSlider
          :slider-params="widthSliderOptions"
          :init-value="hoverBorderWidth"
          @update:option="(v) => (hoverBorderWidth = v)"
        />
      </RightPanelSubsection>

      <RightPanelSubsection title="Focus state" color-picker flex-row>
        <ColorPickerElement
          :color="
            formElementStyleCopy?.focus_border_color ||
            defaultStyles.form_element_checkbox_style_focus_border_color
          "
          @update:color="(v) => updateStyleOption('focus_border_color', v)"
        />
        <RangeSlider
          :slider-params="widthSliderOptions"
          :init-value="focusBorderWidth"
          @update:option="(v) => (focusBorderWidth = v)"
        />
      </RightPanelSubsection>
    </RightPanelSection>

    <RightPanelSection title="Corner radius">
      <RangeSlider
        :slider-params="borderRadiusSliderOptions"
        :init-value="borderRadius"
        @update:option="(v) => (borderRadius = v)"
      />
    </RightPanelSection>

    <RightPanelSection title="Padding">
      <EditorInput qa-id="padding" v-model:value="padding" units="px" />
    </RightPanelSection>
  </RightPanelModal>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useEditorStore } from "@/stores/editor";

import EditorInput from "@/components/editorInput/EditorInput.vue";
import RangeSlider from "@/components/rangeSlider/RangeSlider.vue";
import { RangeSliderUnits } from "@/components/rangeSlider/types";

import { type Template } from "@cna/api/editor";
import {
  defaultStyles,
  useCommonStylesStore,
  type BodyStyles,
} from "@cna/common";

import { RightPanelModal } from "../../elements";
import { checkboxThemeModalProps } from "./CheckboxThemeModalProps";
import { useEditingStyleUtils } from "./hooks/useEditingStyleUtils";
import ColorPickerElement from "../../elements/rightPanel/ColorPickerElement.vue";
import RightPanelSection from "../../elements/rightPanel/RightPanelSection.vue";
import RightPanelSubsection from "../../elements/rightPanel/RightPanelSubsection.vue";

const borderRadiusSliderOptions = {
  minValue: 0,
  maxValue: 6,
  units: RangeSliderUnits.PX,
} as const;

const paddingSliderOptions = {
  minValue: 0,
  maxValue: 50,
  units: RangeSliderUnits.PX,
} as const;

const widthSliderOptions = {
  minValue: 0,
  maxValue: 10,
  units: RangeSliderUnits.PX,
} as const;

export default defineComponent({
  name: "CheckboxThemeModal",
  components: {
    RightPanelSubsection,
    RightPanelSection,
    RightPanelModal,
    ColorPickerElement,
    RangeSlider,
    EditorInput,
  },
  props: checkboxThemeModalProps,
  setup(props) {
    const styles = useCommonStylesStore().mainBlockStyles as BodyStyles;
    const editorStore = useEditorStore();

    const formElementStyle = ref(styles.form_element_checkbox_style);

    const {
      copy: formElementStyleCopy,
      getPixelProp,
      updateStyleOption,
    } = useEditingStyleUtils(formElementStyle);

    const borderWidth = getPixelProp(
      "border_width",
      defaultStyles.form_element_checkbox_style_border_width
    );
    const hoverBorderWidth = getPixelProp(
      "hover_border_width",
      defaultStyles.form_element_checkbox_style_hover_border_width
    );
    const focusBorderWidth = getPixelProp(
      "focus_border_width",
      defaultStyles.form_element_checkbox_style_focus_border_width
    );
    const borderRadius = getPixelProp(
      "border_radius",
      defaultStyles.form_element_checkbox_style_border_radius
    );
    const padding = getPixelProp("padding");

    const saveInputSettings = () => {
      editorStore
        .updateTemplateStyles(
          {
            form_element_checkbox_style: {
              ...formElementStyleCopy.value,
              border_style:
                defaultStyles.form_element_checkbox_style_border_style,
            },
          },
          props.templateName as Template
        )
        .then(() => editorStore.fetchStyles());
    };

    return {
      saveInputSettings,
      styles,
      formElementStyleCopy,
      updateStyleOption,
      borderRadiusSliderOptions,
      paddingSliderOptions,
      borderWidth,
      borderRadius,
      padding,
      hoverBorderWidth,
      focusBorderWidth,
      defaultStyles,
      widthSliderOptions,
    };
  },
});
</script>
