<template>
  <main class="secondary-page-content db-secondary-page__container">
    <DBPageHeader class="db-secondary-page__header" :logo-url="logoUrl" />
    <DBBackToLoginButton
      class="db-secondary-page__btn"
      :config="backToLoginButtonProps"
      field="back_button_label"
    />
    <!--    Slot for FAQ image-->
    <slot />
    <div class="db-secondary-page__content">
      <div class="db-secondary-page__content-column" />
      <div class="db-secondary-page__text">
        <Markdown :data="markdownData" :field="field" :template="template" />
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { injectAppName } from "@cna/common/providers";
import { useCommonGeneralStore } from "@cna/common/stores";
import { computed, defineComponent } from "vue";
import { useRouter } from "vue-router";
import { mdFieldAndTemplateProps } from "../../markdown/props";
import DBBackToLoginButton from "../button/DBBackToLoginButton.vue";
import type { ButtonProps } from "../button/types";
import DBPageHeader from "../pageHeader/DBPageHeader.vue";

export default defineComponent({
  name: "DBSecondaryPageBody",
  components: {
    DBPageHeader,
    DBBackToLoginButton,
  },
  props: {
    markdownData: {
      type: String,
    },
    ...mdFieldAndTemplateProps,
    logoUrl: {
      type: String,
    },
  },
  setup() {
    const router = useRouter();
    const commonGeneral = useCommonGeneralStore();

    const appName = injectAppName();

    const navigateToHome =
      appName === "portal" ? () => router.push("/") : () => {};

    const backToLoginButtonProps = computed(
      () =>
        ({
          title: commonGeneral.bodyTexts.back_button_label,
          onClick: navigateToHome,
        } as ButtonProps)
    );

    return {
      backToLoginButtonProps,
    };
  },
});
</script>
