import { refreshAllActionBoxes } from "@/components/editor/components/useStayFixedPlease";
import { useStretchBackground } from "@/hooks/useStretchBackground";
import { useEditorStore } from "@/stores/editor";
import type { Template } from "@cna/api/editor";
import {
  defaultStyles,
  useCommonGeneralStore,
  useCommonStylesStore,
  type StylesData,
} from "@cna/common";
import { computed, ref } from "vue";

export default function useStylesHandler(
  templateName: Template,
  fieldName: string,
  pageName?: string
) {
  const commonStyles = useCommonStylesStore();
  const editorStore = useEditorStore();

  const commonGeneral = useCommonGeneralStore();

  const { setStretchBackground } = useStretchBackground();

  const styles = ref();
  styles.value = {
    ...commonStyles.appliedStyles[templateName as keyof StylesData],
  };

  if (
    !styles.value[`main_${pageName}_width`] &&
    pageName &&
    commonGeneral.templatesKeys.body !== "email_registration_two_column" &&
    !["terms_and_conditions", "privacy_policy"].includes(pageName)
  ) {
    styles.value[`main_${pageName}_width`] = `${defaultStyles.main_width}px`;
  }

  const padding = computed({
    get() {
      if (!styles.value[`${fieldName}_padding`]) {
        const defaultPadding = defaultStyles[
          `${fieldName}_padding` as keyof typeof defaultStyles
        ] as string;
        return defaultPadding.replace(/px/g, "").split(" ", 4);
      } else {
        return styles.value[`${fieldName}_padding`]
          ?.replaceAll("px", "")
          .split(" ", 4);
      }
    },
    set(v: Array<string>) {
      if (!v) {
        styles.value[`${fieldName}_padding`] = undefined;
      } else {
        styles.value[`${fieldName}_padding`] = `${v.join("px ")}px`;
      }
    },
  });

  const mainBlockPosition = ref(
    `${styles.value.main_horizontal_position ?? ""} ${
      styles.value.main_vertical_position ?? ""
    }`
  );

  const gap = computed({
    get() {
      if (!styles.value.two_column_style?.gap) {
        return 40;
      }
      return Number(
        String(styles.value.two_column_style?.gap).replace("px", "")
      );
    },
    set(val) {
      if (!styles.value.two_column_style) {
        styles.value.two_column_style = {};
      }

      styles.value.two_column_style.gap = `${Number(val)}px`;
    },
  });

  const mainWidth = computed({
    get() {
      const val = styles.value[`main_${pageName}_width`];

      if (!val) return "";

      return Number(String(val).replace("px", ""));
    },
    set(v: string | number | undefined) {
      if (v === undefined || v === "") {
        styles.value[`main_${pageName}_width`] = undefined;
        return;
      }

      styles.value[`main_${pageName}_width`] = `${Number(v)}px`;
    },
  });

  const getStyleWithDefault = (key: string) =>
    styles.value[key] || defaultStyles[key as keyof typeof defaultStyles];

  const updateStyle = (key: string, value: string | number) => {
    styles.value[key] = value;
  };

  const saveStyles = () => {
    const [main_horizontal_position, main_vertical_position] =
      mainBlockPosition.value.split(" ").map((x) => (!x ? undefined : x));

    const bodyData = {
      two_column_style: styles.value.two_column_style,
      main_opacity:
        styles.value.main_background_color?.length > 7
          ? 100
          : styles.value.main_opacity,
      background_image_overlay_opacity:
        styles.value.background_image_overlay_color?.length > 7
          ? 100
          : styles.value.background_image_overlay_opacity,
      main_horizontal_position,
      main_vertical_position,
    };

    const headerAndFooterData = {
      [`${fieldName}_background_color`]:
        styles.value[`${fieldName}_background_color`],
      [`${fieldName}_text_color`]: styles.value[`${fieldName}_text_color`],
      [`${fieldName}_content_width`]:
        styles.value[`${fieldName}_content_width`],
      [`${fieldName}_padding`]: styles.value[`${fieldName}_padding`],
    };

    let data = {
      ...styles.value,
    };

    if (templateName === "body") {
      data = { ...data, ...bodyData };
    } else {
      data = { ...data, ...headerAndFooterData };
    }

    editorStore
      .updateTemplateStyles(data, templateName)
      .then(() => editorStore.fetchStyles())
      .then(setStretchBackground)
      .then(refreshAllActionBoxes);
  };

  return {
    styles,
    saveStyles,
    gap,
    mainBlockPosition,
    padding,
    getStyleWithDefault,
    updateStyle,
    mainWidth,
  };
}
