import { openModal, type ConfirmationModalProps } from "@/modals";
import { useEditorStore } from "@/stores/editor";

export default function useLanguagesSettingsHandlers() {
  const editorStore = useEditorStore();

  const addNewLanguage = async (lang_tag: string) => {
    await editorStore.addNewLanguage(lang_tag);
    await editorStore.fetchAllLanguages();
  };

  const deleteLanguage = async (lang_tag: string) => {
    const confirmationModalConfig: ConfirmationModalProps["config"] = {
      confirmAction: async () => {
        await editorStore.deleteLanguage(lang_tag);
        await editorStore.fetchAllLanguages();
      },
      modalTitle: "Delete language",
      modalText:
        "You’re about to delete a language. All the translation made to this language will be deleted.",
      buttonsConfigs: {
        confirmButton: { buttonTitle: "Delete", buttonIcon: "icon-delete" },
      },
    };

    openModal("ConfirmationModal", { config: confirmationModalConfig });
  };

  const setPrimaryLanguage = async (lang_tag: string) => {
    const confirmationModalConfig: ConfirmationModalProps["config"] = {
      confirmAction: async () => {
        await editorStore.updatePrimaryLanguage(lang_tag);
        await editorStore.fetchAllLanguages();
      },
      modalTitle: "Set primary",
      modalText: "You’re about to change a primary language. You are sure?",
      buttonsConfigs: { confirmButton: { buttonTitle: "Set primary" } },
    };

    openModal("ConfirmationModal", { config: confirmationModalConfig });
  };

  return {
    addNewLanguage,
    deleteLanguage,
    setPrimaryLanguage,
  };
}
