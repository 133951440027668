<template>
  <div v-click-outside="closeMenu" class="simple-dropdown">
    <div
      ref="selectedItemRef"
      v-debounce:300="selectItemBySearch"
      :class="selectedItemClass"
      tabindex="0"
      @click.prevent="toggle"
      @keydown.enter="toggle"
      @keydown.space="toggle"
      @keydown.exact="comboboxKeyboardHandler"
      @keydown.esc="closeMenu"
      @keydown.shift.tab="closeMenu"
      @keyup="searchByCharacterHandler"
    >
      {{ currentItem }}
      <span v-if="!currentItem" class="simple-dropdown__placeholder">
        {{ placeholder }}
      </span>
      <span :class="chevronClass"></span>
    </div>
    <ul v-if="areMenuItemsShown" class="simple-dropdown__menu-list">
      <li
        v-for="(item, index) in items"
        ref="menuItemsRefs"
        :key="item.key"
        v-debounce:300="selectItemBySearch"
        class="simple-dropdown__menu-item dropdown-modal"
        :class="menuItemClass(item.value)"
        :tabindex="index"
        @keydown.tab.exact="moveNext"
        @keydown.shift.tab="movePrev"
        @keydown.down="moveNext"
        @keydown.up="movePrev"
        @keydown.esc="closeMenu"
        @click.prevent="onSelectItem(item.value)"
        @keyup.enter.exact="onSelectItem(item.value)"
        @keyup="searchByCharacterHandler"
      >
        <span v-if="item.value === currentItem" class="icon-tick" />
        <span>{{ item.value }}</span>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import vClickOutside from "click-outside-vue3";
import { computed, defineComponent, type PropType } from "vue";
import { vue3Debounce } from "vue-debounce";
import {
  type DropdownItems,
  type InputConfig,
  useKeyboardEventsHandler,
  useDropdownSearchHandler,
} from "@cna/common";

export default defineComponent({
  name: "SimpleDropdown",
  directives: {
    clickOutside: vClickOutside.directive,
    debounce: vue3Debounce({ lock: true }),
  },
  props: {
    items: {
      required: true,
      type: Array as PropType<DropdownItems>,
    },
    onChange: {
      required: true,
      type: Function as PropType<(value: string | number) => void>,
    },
    currentItem: {
      type: String,
      required: false,
      default: null,
    },
    config: {
      type: Object as PropType<InputConfig>,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(props) {
    const selectedItemClass = computed(() =>
      areMenuItemsShown.value
        ? "simple-dropdown__selected-item simple-dropdown__selected-item--open-menu"
        : "simple-dropdown__selected-item"
    );
    const chevronClass = computed(() =>
      areMenuItemsShown.value
        ? "simple-dropdown__icon-arrow icon-dropdown-up"
        : "simple-dropdown__icon-arrow icon-dropdown"
    );
    const {
      searchValue,
      areMenuItemsShown,
      menuItemsRefs,
      selectedItemRef,
      comboboxKeyboardHandler,
      movePrev,
      moveNext,
      closeMenu,
      searchByCharacterHandler,
      toggle,
    } = useKeyboardEventsHandler();
    const { selectItemBySearch } = useDropdownSearchHandler(
      searchValue,
      props.items,
      props.currentItem,
      props.onChange
    );
    const onSelectItem = (item: string | number) => {
      props.onChange(item);
      closeMenu();
      selectedItemRef.value.focus();
    };
    const menuItemClass = (item: string | number) => {
      return item === props.currentItem
        ? "simple-dropdown__menu-item--selected"
        : "";
    };
    return {
      areMenuItemsShown,
      menuItemsRefs,
      selectedItemRef,
      chevronClass,
      selectedItemClass,
      closeMenu,
      comboboxKeyboardHandler,
      movePrev,
      moveNext,
      toggle,
      onSelectItem,
      menuItemClass,
      searchByCharacterHandler,
      selectItemBySearch,
    };
  },
});
</script>

<style lang="scss" scoped>
.simple-dropdown {
  position: relative;
  cursor: pointer;
}

.simple-dropdown__selected-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 20px;
  @include input-styles;

  &--open-menu {
    &:focus-within {
      outline: none;
      border-width: 1px;
    }
  }
}

.simple-dropdown__placeholder {
  opacity: 0.4;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000;
}

.simple-dropdown__icon-arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  user-select: none;
}

.simple-dropdown__menu-list {
  @include reset-list;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: calc(100% - 3px);
  background-color: $white;
  border: 1px solid $black;
  width: 100%;
  color: $black;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  z-index: 5;
  border-top: transparent;

  &:focus-within {
    outline: none;
  }
}

.simple-dropdown__menu-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 13px 12px 27px;
  font-size: 16px;

  &:first-child,
  &:last-child {
    margin: 0;
  }

  &:focus {
    outline: none;
    background-color: $grey-transparent;
  }

  &:hover {
    background-color: $grey-transparent;
  }
}

.icon-tick:before {
  position: absolute;
  top: 16px;
  left: 10px;
  font-size: 10px;
}

.simple-dropdown__icon-check {
  position: absolute;
  top: 50%;
  left: -13px;
  transform: translateY(-50%);
  font-size: 7px;
}
</style>
