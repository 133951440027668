<template>
  <header
    v-if="templateName !== ''"
    ref="headerEl"
    :class="{ 'fixed-header': isSecondaryPage && isFixedHeaderEnabled }"
  >
    <component :is="templateName">
      <slot />
    </component>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  injectIsFixedSecondaryPageHeaderEnabled,
  injectIsSecondaryPage,
} from "../components/pageBodies/secondaryPageBody/providables";
import { useTemplateNameForLayout } from "../hooks/useTemplateNameForLayout";
import WithCenteredLogo from "../templates/header/WithCenteredLogo.vue";
import WithLeftAlignedLogo from "../templates/header/WithLeftAlignedLogo.vue";
import { headerEl } from "./globals";

export default defineComponent({
  name: "HeaderLayout",
  components: {
    WithLeftAlignedLogo,
    WithCenteredLogo,
  },
  setup() {
    const { templateName } = useTemplateNameForLayout("header");

    const isSecondaryPage = injectIsSecondaryPage();
    const isFixedHeaderEnabled = injectIsFixedSecondaryPageHeaderEnabled();

    return {
      isSecondaryPage,
      isFixedHeaderEnabled,
      templateName,
      headerEl,
    };
  },
});
</script>

<style>
header.fixed-header {
  position: fixed;
  z-index: 10000;
  top: var(--preview-nav-height, 0px);
  left: 0;
  right: 0;
}
</style>
