<template>
  <span :class="['tag', `tag-${color}`]">
    <slot></slot>
  </span>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

export default defineComponent({
  props: {
    color: {
      type: String as PropType<"neon">,
      default: "neon",
    },
  },
});
</script>

<style>
.tag {
  padding: 0px 4px;
  line-height: 1.5;
  font-size: 12px;
  font-weight: bold;
  border-radius: 2px;
  width: fit-content;
}
.tag-neon {
  color: #1d4671;
  background-color: #4ae3bd;
}
</style>
