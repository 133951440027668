<template>
  <div
    class="button-wrapper"
    :class="stylesButtonType"
    v-theme-action-box.styles="buttonProps"
  >
    <button
      :type="type"
      class="page__btn"
      :class="buttonClass"
      :style="config.styles"
      :disabled="isDisabled"
      @click="config.onClick"
    >
      <slot>
        <Markdown
          class="page__btn-title"
          :m-if="!config.loading"
          tag="span"
          :data="config.title"
          :field="field"
          :template="template"
          enter="div"
        >
          <Spinner />
        </Markdown>
      </slot>
    </button>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  type ButtonHTMLAttributes,
  type PropType,
} from "vue";
import { buttonProps } from "../../utils/themeActionBoxElementProps";
import { mdFieldAndTemplateProps } from "../markdown/props";
import Spinner from "../spinner/Spinner.vue";
import { type ButtonConfig } from "../types";

export default defineComponent({
  name: "CommonButton",
  components: { Spinner },
  props: {
    ...mdFieldAndTemplateProps,
    config: {
      type: Object as PropType<ButtonConfig>,
      required: true,
    },
    type: {
      type: String as PropType<ButtonHTMLAttributes["type"]>,
      required: false,
      default: "button",
    },
    stylesButtonType: {
      type: String,
      required: false,
      default: "button",
    },
  },
  setup(props) {
    const isDisabled = computed(
      () => props.config.disabled || props.config.loading
    );
    const buttonClass = computed(() => ({ disabled: props.config.disabled }));

    return { isDisabled, buttonClass, buttonProps };
  },
});
</script>

<style lang="scss" scoped>
.button-wrapper {
  position: relative;
  width: var(--buttons_props_width, 100%);
}

.button-wrapper.text-button {
  width: fit-content;
}

.text-button .page__btn {
  padding: 10px;
  font-size: 16px;
  color: inherit;
  cursor: pointer;

  &:hover {
    text-shadow: 0 0 1px;
  }
}
</style>

<style>
.page__btn-title {
  position: relative;
  z-index: 0;
}
</style>
