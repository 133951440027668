<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.2929 16.1357C16.9024 15.7451 16.9024 15.112 17.2929 14.7215L20.0144 12L17.2929 9.27852C16.9024 8.888 16.9024 8.25484 17.2929 7.86431C17.6834 7.47379 18.3166 7.47379 18.7071 7.86431L22.8428 12L18.7071 16.1357C18.3166 16.5262 17.6834 16.5262 17.2929 16.1357Z"
      fill="#1D4671"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.42859 12C8.42859 11.4477 8.8763 11 9.42859 11H21.4286C21.9809 11 22.4286 11.4477 22.4286 12C22.4286 12.5523 21.9809 13 21.4286 13H9.42859C8.8763 13 8.42859 12.5523 8.42859 12Z"
      fill="#1D4671"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.42859 3.42859C2.42859 2.8763 2.8763 2.42859 3.42859 2.42859H13.7143C14.2666 2.42859 14.7143 2.8763 14.7143 3.42859V7.7143C14.7143 8.26659 14.2666 8.7143 13.7143 8.7143C13.162 8.7143 12.7143 8.26659 12.7143 7.7143V4.42859H4.42859V19.5714H12.7143V16.2865L13.7143 16.2857L14.7143 16.2858V20.5714C14.7143 21.1237 14.2666 21.5714 13.7143 21.5714H3.42859C2.8763 21.5714 2.42859 21.1237 2.42859 20.5714V3.42859ZM13.7143 16.2857L14.7143 16.2858C14.7143 15.7335 14.2666 15.2857 13.7143 15.2857C13.162 15.2857 12.7143 15.7343 12.7143 16.2865L13.7143 16.2857Z"
      fill="#1D4671"
    />
  </svg>
</template>
