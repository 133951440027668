import { defaultStyles, useCommonImagesStore } from "@cna/common";
import { computed, type Ref } from "vue";
import type { AxiosRequestConfig } from "axios";
import { api } from "@/api";
import { type PostImageAttributesInput } from "@cna/api/editor";

export const useImagesStore = (currentProjectId: Ref<number | null>) => {
  const commonImages = useCommonImagesStore();
  const bodyBackground = computed(() => {
    const { background_color } = commonImages.imagesData.body.background.props;
    if (!background_color) {
      commonImages.imagesData.body.background.props.background_color =
        defaultStyles.background_color;
    }
    return commonImages.imagesData.body.background;
  });

  const fetchImagesData = async (config?: AxiosRequestConfig) => {
    commonImages.imagesData = (
      await api.v1.projects.project(currentProjectId.value!).images.get(config)
    ).data.result;
  };

  const updateImage = async (
    templateName: string,
    fieldName: string,
    image: File
  ) => {
    await api.v1.projects
      .project(currentProjectId.value!)
      .images.post(templateName, fieldName, image);
    await fetchImagesData();
  };

  const updateImageAttributes = async (
    templateName: string,
    fieldName: string,
    imageProps: PostImageAttributesInput["props"]
  ) => {
    await api.v1.projects
      .project(currentProjectId.value!)
      .images.postAttributes(templateName, fieldName, { props: imageProps });
    await fetchImagesData();
  };

  const updateImageWithAttributes = async (
    templateName: string,
    fieldName: string,
    image: File,
    imageProps: PostImageAttributesInput["props"]
  ) => {
    return Promise.all([
      updateImage(templateName, fieldName, image),
      updateImageAttributes(templateName, fieldName, imageProps),
    ]).then(() => fetchImagesData());
  };

  const deleteImage = async (templateName: string, fieldName: string) => {
    await api.v1.projects
      .project(currentProjectId.value!)
      .images.postEmpty(templateName, fieldName);
  };

  const updateBackgroundColorWithoutImage = async (
    templateName: string,
    fieldName: string,
    backgroundColor: string
  ) => {
    return Promise.all([
      deleteImage(templateName, fieldName),
      updateImageAttributes(templateName, fieldName, {
        background_color: backgroundColor,
      }),
    ]).then(() => fetchImagesData());
  };

  return {
    bodyBackground,
    fetchImagesData,
    updateImage,
    deleteImage,
    updateImageAttributes,
    updateImageWithAttributes,
    updateBackgroundColorWithoutImage,
  };
};
