import { type Template } from "@cna/api/editor";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useCommonGeneralStore } from "../stores";

const templateComponents = {
  body: {
    connect_with_checkbox: "ConnectWithCheckbox",
    connect_with_checkbox_logo_leftAligned:
      "ConnectWithCheckboxLogoLeftAligned",
    db_fv: "DeutscheBahnFernverkehr",
    connect_with_email_registration: "ConnectWithEmailRegistration",
    email_registration_two_column: "EmailRegistrationTwoColumn",
  },
  header: {
    with_left_aligned_logo: "WithLeftAlignedLogo",
    with_centered_logo: "WithCenteredLogo",
  },
  footer: {
    with_contact_terms_privacy: "WithContactTermsPrivacy",
    with_contact_terms_privacy_faq_customSelect:
      "WithContactTermsPrivacyFAQCustomSelect",
  },
} as const;

// the caller should import all possible components that can be set up here for his layout
export const useTemplateNameForLayout = (
  layout: Exclude<Template, "general">
) => {
  const commonGeneral = useCommonGeneralStore();
  const { templatesKeys } = storeToRefs(commonGeneral);

  const templateName = computed(() => {
    const template = templatesKeys.value[layout];
    const componentMap = templateComponents[layout];

    if (template in componentMap) {
      return componentMap[template as keyof typeof componentMap];
    }

    return "";
  });

  return {
    templateName,
  };
};
