<template>
  <RightPanelModal :title="title" :on-apply-changes="saveInputSettings">
    <RightPanelSection title="Label Color" color-picker>
      <ColorPickerElement
        :color="
          formElementStyleCopy?.label_color ||
          defaultStyles.form_element_radio_button_style_label_color
        "
        @update:color="(v) => updateStyleOption('label_color', v)"
      />
    </RightPanelSection>

    <RightPanelSection title="Icon color" color-picker>
      <ColorPickerElement
        :color="
          formElementStyleCopy?.color ||
          defaultStyles.form_element_radio_button_style_color
        "
        @update:color="(v) => updateStyleOption('color', v)"
      />
    </RightPanelSection>

    <RightPanelSection title="Border" with-subsections>
      <RightPanelSubsection title="Default state" color-picker flex-row>
        <ColorPickerElement
          :color="
            formElementStyleCopy?.border_color ||
            defaultStyles.form_element_radio_button_style_border_color
          "
          @update:color="(v) => updateStyleOption('border_color', v)"
        />
        <EditorInput v-model:value="borderWidth" units="px" />
      </RightPanelSubsection>

      <RightPanelSubsection title="Hover state" color-picker flex-row>
        <ColorPickerElement
          :color="
            formElementStyleCopy?.hover_border_color ||
            defaultStyles.form_element_radio_button_style_hover_border_color
          "
          @update:color="(v) => updateStyleOption('hover_border_color', v)"
        />
        <EditorInput v-model:value="hoverBorderWidth" units="px" />
      </RightPanelSubsection>

      <RightPanelSubsection title="Focus state" color-picker flex-row>
        <ColorPickerElement
          :color="
            formElementStyleCopy?.focus_border_color ||
            defaultStyles.form_element_radio_button_style_focus_border_color
          "
          @update:color="(v) => updateStyleOption('focus_border_color', v)"
        />
        <EditorInput v-model:value="focusBorderWidth" units="px" />
      </RightPanelSubsection>
    </RightPanelSection>

    <RightPanelSection title="Padding">
      <EditorInput qa-id="padding" v-model:value="padding" units="px" />
    </RightPanelSection>
  </RightPanelModal>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useEditorStore } from "@/stores/editor";
import { type Template } from "@cna/api/editor";

import EditorInput from "@/components/editorInput/EditorInput.vue";
import { RangeSliderUnits } from "@/components/rangeSlider/types";
import {
  defaultStyles,
  useCommonStylesStore,
  type BodyStyles,
} from "@cna/common";

import { RightPanelModal } from "../../elements";
import { radioButtonThemeModalProps } from "./RadioButtonThemeModalProps";
import { useEditingStyleUtils } from "./hooks/useEditingStyleUtils";
import ColorPickerElement from "../../elements/rightPanel/ColorPickerElement.vue";
import RightPanelSection from "../../elements/rightPanel/RightPanelSection.vue";
import RightPanelSubsection from "../../elements/rightPanel/RightPanelSubsection.vue";

const borderRadiusSliderOptions = {
  minValue: 0,
  maxValue: 25,
  units: RangeSliderUnits.PX,
} as const;

const paddingSliderOptions = {
  minValue: 0,
  maxValue: 50,
  units: RangeSliderUnits.PX,
} as const;

export default defineComponent({
  name: "RadioButtonThemeModal",
  components: {
    RightPanelSubsection,
    RightPanelSection,
    RightPanelModal,
    ColorPickerElement,
    EditorInput,
  },
  props: radioButtonThemeModalProps,
  setup(props) {
    const styles = useCommonStylesStore().mainBlockStyles as BodyStyles;
    const editorStore = useEditorStore();

    const formElementStyle = ref(styles.form_element_radio_button_style);

    const {
      copy: formElementStyleCopy,
      getPixelProp,
      updateStyleOption,
    } = useEditingStyleUtils(formElementStyle);

    const borderWidth = getPixelProp("border_width", 1);
    const padding = getPixelProp("padding");
    const hoverBorderWidth = getPixelProp(
      "hover_border_width",
      defaultStyles.form_element_radio_button_style_hover_border_width
    );
    const focusBorderWidth = getPixelProp(
      "focus_border_width",
      defaultStyles.form_element_radio_button_style_focus_border_width
    );

    const saveInputSettings = () => {
      editorStore
        .updateTemplateStyles(
          {
            form_element_radio_button_style: {
              ...formElementStyleCopy.value,
              border_style: "solid",
            },
          },
          props.templateName as Template
        )
        .then(() => editorStore.fetchStyles());
    };

    return {
      saveInputSettings,
      styles,
      formElementStyleCopy,
      updateStyleOption,
      borderRadiusSliderOptions,
      paddingSliderOptions,
      borderWidth,
      padding,
      hoverBorderWidth,
      focusBorderWidth,
      defaultStyles,
    };
  },
});
</script>
