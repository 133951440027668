<template>
  <component
    :is="link ? 'a' : 'button'"
    :class="{
      button: true,
      [`button-block`]: block,
      [`button-color--${color}`]: true,
      [`button-type--${type}`]: true,
      [`button-shape--${shape}`]: true,
      [`button-size--${size}`]: true,
    }"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <slot name="icon">
      <i v-if="icon" class="button__icon" :class="icon" />
    </slot>
    <slot>
      {{ title }}
    </slot>
  </component>
</template>

<script lang="ts">
import {
  defineComponent,
  type ExtractPublicPropTypes,
  type PropType,
} from "vue";

const component = defineComponent({
  name: "EButton",
  emits: ["click"],
  props: {
    title: {
      type: String,
    },
    link: Boolean,
    color: {
      type: String as PropType<"primary" | "secondary" | "accent">,
      default: "secondary",
    },
    type: {
      type: String as PropType<"default" | "text" | "ghost">,
      default: "default",
    },
    shape: {
      type: String as PropType<"default" | "square">,
      default: "default",
    },
    size: {
      type: String as PropType<"medium" | "small">,
      default: "medium",
    },
    icon: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    block: Boolean,
  },
});
export default component;

export type EButtonProps = ExtractPublicPropTypes<
  InstanceType<typeof component>["$props"]
>;
</script>

<style lang="scss" scoped>
.button-type--default,
.button-type--ghost {
  display: flex;
  align-items: center;
  border-radius: var(--ico-radius-m);
  font-weight: 700;
  cursor: pointer;
  position: relative;
  z-index: 1;
  overflow: hidden;
  line-height: 1.5;
  transition: all 120ms ease-in-out;
}

.button-size--medium {
  padding: var(--ico-spacing-s) var(--ico-spacing-l);
  font-size: 16px;
}

.button-size--medium.button-shape--square {
  padding: var(--ico-spacing-s);
}

.button-size--small {
  padding: var(--ico-spacing-xs) var(--ico-spacing-m);
  font-size: 14px;
}

.button-size--small.button-shape--square {
  padding: var(--ico-spacing-xs);
}

.button-type--text {
  cursor: pointer;
  padding: var(--ico-spacing-l) 0;
  font-size: 16px;
  line-height: 20px;
}

.button-block {
  display: flex;
  width: 100%;
  justify-content: center;
}

.button__icon {
  font-size: 18px;
  margin-right: 7px;
  margin-top: -2px;
}

.button-type--default {
  &.button-color--accent {
    background-color: hsl(var(--ico-accent));
    color: hsl(var(--ico-secondary));

    &:hover,
    &:focus {
      background-color: hsl(var(--ico-accent-dark));
    }

    &[disabled=""] {
      background-color: hsl(var(--ico-accent-lightest));
      cursor: auto;
    }
  }

  &.button-color--secondary {
    background-color: hsl(var(--ico-secondary));
    color: hsl(var(--ico-white));

    &:hover,
    &:focus {
      background-color: hsl(var(--ico-secondary-dark));
    }

    &[disabled=""] {
      background-color: hsl(var(--ico-secondary-lightest));
      cursor: auto;
    }
  }

  &.button-color--primary {
    background-color: hsl(var(--ico-primary));
    color: hsl(var(--ico-white));

    &:hover,
    &:focus {
      background-color: hsl(var(--ico-primary-dark));
    }

    &[disabled=""] {
      background-color: hsl(var(--ico-primary-lightest));
      cursor: auto;
    }
  }
}

.button-type--ghost {
  &.button-color--accent {
    color: hsl(var(--ico-secondary));

    &:hover {
      background-color: hsl(var(--ico-accent));
    }
  }

  &.button-color--secondary {
    color: white;

    &:hover {
      background-color: hsl(var(--ico-secondary-lighter));
    }

    &:focus {
      background-color: hsl(var(--ico-secondary-light));
    }
  }
}
</style>
