import { ref, watch, type Ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import type { Maybe } from "../types";
import { cloneDeep, isArray } from "../utils";

type InternalOpts<C, V> = {
  type: C;
  default?: V;
};

export type BooleanRouteOptions = InternalOpts<BooleanConstructor, boolean>;
export type NumberRouteOptions = InternalOpts<NumberConstructor, number>;
export type StringRouteOptions = InternalOpts<StringConstructor, string>;
export type DateRouteOptions = InternalOpts<DateConstructor, Date>;
export type ObjectRouteOptions<O> = InternalOpts<ObjectConstructor, O>;

export type UseRouteQueryOptions<O = never> =
  | BooleanRouteOptions
  | NumberRouteOptions
  | StringRouteOptions
  | DateRouteOptions
  | ObjectRouteOptions<O>;

type ValueType<T, O = unknown> = T extends BooleanConstructor
  ? boolean
  : T extends NumberConstructor
  ? number
  : T extends StringConstructor
  ? string
  : T extends DateConstructor
  ? Date
  : O;

export const useRouteQuery = <Options extends UseRouteQueryOptions<any>>(
  paramName: string,
  opts: Options
) => {
  const route = useRoute();
  const router = useRouter();

  const value: Ref<
    Options["default"] extends unknown
      ? Maybe<ValueType<Options["type"]>>
      : ValueType<Options["type"], Options["default"]> | Options["default"]
  > = ref(undefined);

  watch(
    () => route?.query[paramName],
    (val) => {
      if (isArray(val)) {
        console.error("Multiple query values for " + paramName);
        return;
      }

      let res: any = opts.default;

      switch (opts.type) {
        case String:
          res = val;
          break;
        case Boolean:
          res = val === "true";
          break;
        case Number:
          res = val ? Number(val) : opts.default;
          break;
        case Date:
          res = val ? new Date(val) : opts.default;
          break;
        case Object:
          res = val ? JSON.parse(val) : opts.default;
          break;
      }

      if (value.value !== res) {
        value.value = res;
      }
    },
    {
      immediate: true,
    }
  );

  watch(value, (val: any) => {
    let res: string | undefined = undefined;

    switch (opts.type) {
      case String:
        res = (val as string) || undefined;
        break;
      case Boolean:
        res = val !== undefined ? (val as boolean).toString() : undefined;
        break;
      case Number:
        res = val !== undefined ? (val as number).toString() : undefined;
        break;
      case Date:
        res = val ? (val as Date).toISOString() : undefined;
        break;
      case Object:
        res = val ? JSON.stringify(val) : undefined;
    }

    const queryCopy = cloneDeep(route.query);
    if (res === undefined) {
      delete queryCopy[paramName];
    } else {
      queryCopy[paramName] = res;
    }

    router.replace({
      query: queryCopy,
    });
  });

  return value;
};
