import type { EmailRegistrationField, GetImagesResult } from "@cna/api/editor";
import type {
  BodyStyles,
  CommonBodyTexts,
  DBBodyStyles,
  DBFooterStyles,
  DBFooterTexts,
  EmailRegistrationFormData,
  EmailRegistrationPageTexts,
  EmailVerificationPageTexts,
  Features,
  FontsStyles,
  FooterStyles,
  FooterTexts,
  GeneralTexts,
  HeaderStyles,
  OTPVerificationPageTexts,
  PhoneNumberWithCountryData,
  ReturningPageTexts,
  StylesData,
  TextFieldValues,
} from "./types";

export const INITIAL_FOOTER_TEXTS: FooterTexts = {
  contact_us_label: "",
  footer_text: "",
  privacy_policy_label: "",
  privacy_policy_body: "",
  terms_and_conditions_label: "",
  contact_us_body: "",
  terms_and_conditions_body: "",
  contact_us_label_rich_text: "",
  privacy_policy_label_rich_text: "",
  terms_and_conditions_label_rich_text: "",
};

export const INITIAL_DB_FOOTER_TEXTS: DBFooterTexts = {
  contact_us_label: "",
  footer_text: "",
  privacy_policy_label: "",
  privacy_policy_body: "",
  terms_and_conditions_label: "",
  contact_us_body: "",
  terms_and_conditions_body: "",
  faq_label: "",
  faq_body: "",
};

export const INITIAL_COMMON_BODY_TEXTS: CommonBodyTexts = {
  back_button_label: "Back",
  connect_button: "",
  login_text: "",
  login_text_below_form: "",
  login_text_terms_label: "",
  login_text_terms_link: "",
  now_connected_text: "",
  logout_button_label: "",
  venue_redirect_button_label: "",
  venue_redirect_button_link: "",
  below_main_block_content: "",
  full_width_back_button_label:
    '<p style="font-size: 16px; font-weight: 700; margin-block: 0em; line-height: 24px;">Back</p>',
  login_text_terms_rich_text:
    '<p style="margin-block: 0em;">I accept the <a href=/terms-and-conditions><u>Terms & Conditions</u></a></p>',
};

export const INITIAL_GENERAL_TEXTS: GeneralTexts = {
  page_title: "",
};

export const INITIAL_RETURNING_PAGE_TEXTS: ReturningPageTexts = {
  ...INITIAL_COMMON_BODY_TEXTS,
  returning_button_label: "",
  returning_text: "",
  returning_text_terms_label: "",
  returning_text_terms_link: "",
};

export const INITIAL_OTP_VERIFICATION_PAGE_TEXTS: OTPVerificationPageTexts = {
  ...INITIAL_COMMON_BODY_TEXTS,
  verification_text: "",
  verification_button_label: "",
  resend_button_label: "",
  edit_phone_button_label: "",
  resend_button_label_if_resent: "",
  verification_resent_text: "",
};

export const INITIAL_EMAIL_REGISTRATION_PAGE_TEXTS: EmailRegistrationPageTexts =
  {
    ...INITIAL_COMMON_BODY_TEXTS,
    email_registration_text: "",
  };

export const INITIAL_EMAIL_VERIFICATION_PAGE_TEXTS: EmailVerificationPageTexts =
  {
    ...INITIAL_COMMON_BODY_TEXTS,
    email_verification_text: "",
  };

export const INITIAL_TEXTS: TextFieldValues = {
  en: {
    body: {
      ...INITIAL_COMMON_BODY_TEXTS,
      ...INITIAL_RETURNING_PAGE_TEXTS,
      ...INITIAL_OTP_VERIFICATION_PAGE_TEXTS,
      ...INITIAL_EMAIL_REGISTRATION_PAGE_TEXTS,
      ...INITIAL_EMAIL_VERIFICATION_PAGE_TEXTS,
    },
    footer: { ...INITIAL_FOOTER_TEXTS, ...INITIAL_DB_FOOTER_TEXTS },
    general: { ...INITIAL_GENERAL_TEXTS },
  },
};

export const INITIAL_FEATURES: Features = {
  returning_page: false,
  with_phone_input: {
    countries: ["IT", "DE", "US", "FR", "GB", "CN", "CH", "AF"],
  },
  with_below_main_block_content: false,
  auto_redirect: false,
  without_icomera_logo: false,
  full_width_secondary_page: true,
};

export const INITIAL_SELECTED_PHONE_NUMBER_WITH_COUNTRY_CODE: PhoneNumberWithCountryData =
  {
    code: "",
    phoneNumber: "",
    iso: "",
  };

export const INITIAL_HEADER_STYLES: HeaderStyles = {
  header_background_color: "",
  header_text_color: "",
};

export const INITIAL_BODY_STYLES: BodyStyles = {
  buttons_props: {
    background_color: "",
    border_color: "",
    border_width: 0,
    corners_radius: 0,
    text_color: "",
    disabled_text_color: "",
    disabled_border_color: "",
    disabled_background_color: "",
    width: "100%",
  },
  full_width_back_button: {
    background_color: "#F9F9F9",
    content_width: "100%",
    icon_display: "block",
    padding: "8px 20px 8px 20px",
    icon_color: "#000000",
  },
  label_type: "standard",
  main_background_color: "",
  main_text_color: "",
  main_width: 400,
  main_opacity: 100,
  main_padding: "0px 15px 15px",
  main_border_radius: 0,
  background_image_overlay_color: "#000000",
  background_image_overlay_opacity: 20,
  main_t_and_c_checkbox_alignment: "",
  background_position: "center center",
  main_horizontal_position: "center",
  main_vertical_position: "center",
};

export const INITIAL_DB_BODY_STYLES: DBBodyStyles = {
  connect_button_props: {
    border_color: "",
    border_width: 0,
    corners_radius: 8,
    focus_box_shadow: "0 0 0 0.2rem rgb(239 38 57 / 50%)",
    enabled_text_color: "#ffffff",
    focus_border_color: "#b90011",
    focus_border_width: 1,
    disabled_text_color: "#676c77",
    hover_background_color: "#9a0000",
    enabled_background_color: "#ec0016",
    disabled_background_color: "#e1e6ec",
  },
  logout_button_props: {
    border_color: "",
    border_width: 0,
    focus_outline: ".5px solid #ec0016",
    corners_radius: 8,
    focus_box_shadow: "0 0 0 0.2rem rgb(239 38 57 / 50%)",
    laptop_text_color: "#ffffff",
    mobile_text_color: "#3c414b",
    hover_background_color: "#ec0016",
    laptop_background_color: "#3c414b",
    mobile_background_color: "#ffffff",
  },
  main_laptop_background_color: "#ffffff",
  main_laptop_text_color: "#3c414b",
  main_mobile_bottom_background_color: "rgba(19, 24, 33, 0.75)",
  main_mobile_text_color: "#ffffff",
  main_mobile_top_background_color: "rgba(19, 24, 33, 0.4)",
  background_position: "center center",
  tablet_background_position: "center top",
  mobile_background_position: "center top",
};

export const INITIAL_FOOTER_STYLES: FooterStyles = {
  footer_background_color: "",
  footer_text_color: "",
};

export const INITIAL_DB_FOOTER_STYLES: DBFooterStyles = {
  footer_background_color: "",
  footer_text_color: "",
  footer_hover_text_color: "",
};

export const INITIAL_FONTS_STYLES: FontsStyles = {
  list: [
    {
      title: "Montserrat",
      value: "Montserrat, sans-serif",
    },
  ],
  default: {
    headings: "Montserrat, sans-serif",
    paragraphs: "Montserrat, sans-serif",
  },
};

export const INITIAL_STYLES: StylesData = {
  body: { ...INITIAL_BODY_STYLES, ...INITIAL_DB_BODY_STYLES },
  header: {
    ...INITIAL_HEADER_STYLES,
  },
  footer: {
    ...INITIAL_FOOTER_STYLES,
    ...INITIAL_DB_FOOTER_STYLES,
  },
  general: {
    fonts: { ...INITIAL_FONTS_STYLES },
  },
};

export const INITIAL_IMAGES: GetImagesResult["result"] = {
  header: {
    logo: {
      link: "",
      props: {},
    },
  },
  body: {
    background: {
      link: "",
      props: {
        background_color: "",
      },
    },
    background_mobile: {
      link: "",
      props: {
        background_color: "",
      },
    },
    background_tablet: {
      link: "",
      props: {
        background_color: "",
      },
    },
  },
  footer: {
    faq_top_image: {
      link: "",
      props: {},
    },
  },
};

export const DEFAULT_EMAIL_REGISTRATION_FIELD: EmailRegistrationField = {
  key: "",
  kind: "email",
  required: false,
  display_name: "",
  payload: {
    texts: {
      en: {
        label: "",
      },
    },
  },
  enabled: false,
  ordinal_num: 0,
};

export const DEFAULT_EMAIL_REGISTRATION_FORM_DATA: EmailRegistrationFormData = {
  consents: {},
  info: {},
};
