<template>
  <div
    v-if="enabled"
    class="secondary-page-back-button"
    v-theme-action-box.styles="fullWidthBackButtonProps"
  >
    <div class="secondary-page-back-button__content">
      <button class="secondary-page-back-button__button" @click="onClick">
        <span class="secondary-page-back-button__icon icon-chevron-left" />
        <Markdown
          tag="span"
          :data="title"
          field="full_width_back_button_label"
          template="body"
          enter="div"
        />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { useCommonGeneralStore } from "@cna/common";
import { computed, defineComponent } from "vue";
import { fullWidthBackButtonProps } from "../../../utils";

export default defineComponent({
  name: "SecondaryPageBackButton",
  setup() {
    const commonGeneral = useCommonGeneralStore();
    const enabled = computed(
      () => commonGeneral.features.full_width_secondary_page
    );
    const title = computed(
      () => commonGeneral.bodyTexts.full_width_back_button_label
    );

    return {
      enabled,
      fullWidthBackButtonProps,
      onClick: commonGeneral.buttonsActions.onBackButtonClick,
      title,
    };
  },
});
</script>

<style lang="scss" scoped>
.secondary-page-back-button {
  background-color: var(--full_width_back_button_background_color);
  width: 100%;
  height: 100%;
  border-top: 1px solid #e8e8e8;
}

.secondary-page-back-button__content {
  width: 100%;
  margin: 0 auto;
  max-width: var(--full_width_back_button_content_width);
  padding: var(--full_width_back_button_padding_top)
    var(--full_width_back_button_padding_right)
    var(--full_width_back_button_padding_bottom)
    var(--full_width_back_button_padding_left);
}

.secondary-page-back-button__button {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.secondary-page-back-button__icon {
  display: var(--full_width_back_button_icon_display);
  color: var(--full_width_back_button_icon_color);
  font-size: 24px;
}
</style>
