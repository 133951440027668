<template>
  <section class="forms settings-section">
    <SettingsSectionHeader heading="Forms" icon="icon-forms">
      <template #default>
        <span v-if="projectformwrite" class="form-section__heading-info">
          All the elements are editable in the Editor
        </span>
      </template>
    </SettingsSectionHeader>
    <draggable
      v-model="orderedProjectFields"
      item-key="order"
      draggable=".draggable"
      @change="changeOrder"
      :disabled="!projectformwrite"
    >
      <template #item="{ element }">
        <FormsSectionRow :data="element" />
      </template>
    </draggable>
    <SettingsCreateRow
      v-if="projectformwrite"
      @create="onCreate"
      :button-props="{ type: 'default', size: 'small' }"
      :row-quantity="enabledEmailRegistrationFields.length"
    >
      <template #button-title> Edit form </template>
    </SettingsCreateRow>
  </section>
  <FormsEditingModal v-if="showFormsModal" @close="showFormsModal = false" />
</template>

<script lang="ts">
import { usePermissions } from "@/hooks/usePermissions";
import FormsSectionRow from "@/routes/settings/formsSection/rows/FormsSectionRow.vue";
import SettingsCreateRow from "@/routes/settings/sectionElements/SettingsCreateRow.vue";
import SettingsSectionHeader from "@/routes/settings/sectionElements/SettingsSectionHeader.vue";
import { useEditorStore } from "@/stores/editor";
import { useCommonEmailRegistrationStore } from "@cna/common";
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  ref,
  watch,
} from "vue";
import draggable from "vuedraggable";

export default defineComponent({
  name: "FormsSection",
  components: {
    SettingsSectionHeader,
    FormsSectionRow,
    draggable,
    SettingsCreateRow,
    FormsEditingModal: defineAsyncComponent(
      () => import("@/modals/components/common/FormsEditingModal.vue")
    ),
  },
  setup() {
    const { projectformwrite } = usePermissions();
    const editorStore = useEditorStore();

    const showFormsModal = ref(false);

    const commonEmailRegistration = useCommonEmailRegistrationStore();
    const enabledEmailRegistrationFields = computed(
      () => commonEmailRegistration.allProjectFields || []
    );
    const orderedProjectFields = ref(enabledEmailRegistrationFields.value);

    const onCreate = () => {
      showFormsModal.value = true;
    };

    const updateFields = () =>
      (orderedProjectFields.value = enabledEmailRegistrationFields.value);
    const changeOrder = () => {
      editorStore.reorderFields(
        orderedProjectFields.value.map((element, index) => ({
          key: element.key,
          // minimal ordinal_num starts from 1
          ordinal_num: ++index,
        }))
      );
    };

    watch(
      () => enabledEmailRegistrationFields.value,
      () => {
        updateFields();
      }
    );

    return {
      enabledEmailRegistrationFields,
      projectformwrite,
      showFormsModal,
      changeOrder,
      onCreate,
      orderedProjectFields,
    };
  },
});
</script>

<style lang="scss" scoped>
.settings-section {
  overflow: hidden;
}

.form-section__heading-info {
  font-family: Nunito, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.14;
  letter-spacing: normal;
  color: #002232;
}
</style>
