<template>
  <Page id="now-connected" class="now-connected-page">
    <NowConnectedPageBodyTwoColumn v-if="isTwoColumn" />
    <NowConnectedPageBody v-else />
  </Page>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

import { useEditorStore } from "@/stores/editor";
import { storeToRefs } from "pinia";

import {
  NowConnectedPageBody,
  NowConnectedPageBodyTwoColumn,
  Page,
  provideShowTrafficUsageMessage,
} from "@cna/common";

export default defineComponent({
  name: "NowConnectedPage",
  components: {
    NowConnectedPageBody,
    NowConnectedPageBodyTwoColumn,
    Page,
  },
  setup() {
    const editorStore = useEditorStore();
    const { isTwoColumn } = storeToRefs(editorStore);

    provideShowTrafficUsageMessage(ref(true));

    return {
      isTwoColumn,
    };
  },
});
</script>
