<template>
  <div ref="editorHeader" class="editor-header">
    <DropdownWithSearch
      :items="projectsDropdownOptions"
      :selected-project="currentProject"
      @select="redirectToProject"
      @change="onChangeSearch"
      @close="onChangeSearch('')"
      fixed-popup-width="400px"
    />
    <HeaderNavigation class="header-navigation" />
    <div class="more-buttons flex-row-center">
      <EButton
        v-if="projectversionwrite"
        size="small"
        color="primary"
        @click="onSaveVersion"
      >
        Save version
      </EButton>
      <PopUserMenu />
    </div>
  </div>
  <ConfirmationModal
    v-if="showConfirmationModal && confirmationModalConfig"
    @close="showConfirmationModal = false"
    :config="confirmationModalConfig"
  />
</template>

<script lang="ts">
import EButton from "@/components/button/EButton.vue";
import { type ConfirmationModalProps } from "@/modals";
import PopUserMenu from "./components/UserMenu/PopUserMenu.vue";

import { useNotification } from "@/hooks/use-notification";
import { usePermissions } from "@/hooks/usePermissions";
import { useEditorStore } from "@/stores/editor";
import { useOnceAtATime } from "@cna/common/hooks/useOnceAtATime";
import { useElementBounding } from "@vueuse/core";
import { defineAsyncComponent, defineComponent, ref } from "vue";
import DropdownWithSearch from "../dropdown/DropdownWithSearch.vue";
import { usePagesDropdownHelper } from "../dropdown/usePagesDropdownHelper";
import HeaderNavigation from "./components/HeaderNavigation.vue";

const editorHeader = ref();

export const editorHeaderBounds = useElementBounding(editorHeader);

export default defineComponent({
  name: "EditorHeader",
  components: {
    HeaderNavigation,
    DropdownWithSearch,
    ConfirmationModal: defineAsyncComponent(
      () => import("@/modals/components/common/ConfirmationModal.vue")
    ),
    EButton,
    PopUserMenu,
  },
  setup() {
    const { projectversionwrite } = usePermissions();
    const editorStore = useEditorStore();

    const showConfirmationModal = ref(false);
    const confirmationModalConfig = ref<ConfirmationModalProps["config"]>();
    const notification = useNotification();

    const onSaveVersion = () => {
      confirmationModalConfig.value = {
        confirmAction: useOnceAtATime(async (value: string) => {
          await editorStore.createVersion(value).then(() => {
            notification.success();
          });
          await editorStore.fetchVersions();
        }),
        modalTitle: "Save version",
        modalText:
          "You’re about to create an updated version of the Portal. This new version will be saved to the Version history. Please add a description of the changes made to this version:",
        textArea: true,
        buttonsConfigs: {
          confirmButton: {
            buttonTitle: "Save",
          },
          cancelButton: {
            buttonTitle: "Cancel",
          },
        },
      };

      showConfirmationModal.value = true;
    };
    const {
      redirectToProject,
      projectsDropdownOptions,
      onChangeSearch,
      currentProject,
    } = usePagesDropdownHelper();

    return {
      editorHeader,
      showConfirmationModal,
      confirmationModalConfig,
      onSaveVersion,
      redirectToProject,
      projectsDropdownOptions,
      onChangeSearch,
      currentProject,
      projectversionwrite,
    };
  },
});
</script>

<style lang="scss" scoped>
.editor-header {
  padding: 12px 20px;
  background-color: $dark-blue;
  color: $white;
  font-family: $primary-font;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.header-navigation {
  justify-content: center;
}
.more-buttons {
  gap: 10px;
  justify-content: end;
}

.editor-header:deep(.dropdown__select) {
  height: 32px;
  padding: 0 12px;
  font-family: $primary-font;
  font-size: 14px;
  font-weight: bold;
}

.editor-header:deep(.custom-dropdown__selected-item) {
  background-color: transparent;
  color: $white;
  border-color: #6888ab;
  width: fit-content;
}
</style>
