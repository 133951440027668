<template>
  <div class="header flex-row-center" v-theme-action-box.styles="headerProps">
    <div class="header__content flex-row-center-space-between">
      <Logo class="header__logo" />
      <LanguageDropdown />
    </div>
  </div>
  <slot />
  <TrafficUsageMessage />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LanguageDropdown from "./LanguageDropdown.vue";
import Logo from "./Logo.vue";
import TrafficUsageMessage from "./TrafficUsageMessage/TrafficUsageMessage.vue";
import { headerProps } from "../../utils";

export default defineComponent({
  name: "WithLeftAlignedLogo",
  components: {
    Logo,
    LanguageDropdown,
    TrafficUsageMessage,
  },
  setup() {
    return {
      headerProps,
    };
  },
});
</script>
