import { computed, ref, type Ref } from "vue";
import { storeToRefs } from "pinia";
import type { AxiosRequestConfig } from "axios";
import { api } from "@/api";
import type {
  GetLanguageTagsResult,
  Language,
  PutReorderSequenceEntry,
} from "@cna/api/editor";
import { useCommonGeneralStore } from "@cna/common";

export const useLanguagesStore = (currentProjectId: Ref<number | null>) => {
  const commonGeneral = useCommonGeneralStore();
  const { currentLanguage, primaryLanguage } = storeToRefs(commonGeneral);
  const languages: Ref<Language[]> = ref([]);
  const allLanguageTags: Ref<GetLanguageTagsResult["result"]> = ref({});

  const sortedLanguages = computed(() =>
    languages.value.sort((a: any, b: any) => (a.order > b.order ? 1 : -1))
  );

  const fetchAllLanguages = async (config?: AxiosRequestConfig) => {
    const data = (
      await api.v1.projects
        .project(currentProjectId.value!)
        .languages.get(config)
    ).data.result;
    languages.value = [...data];
  };

  const fetchPrimaryLanguage = async (config?: AxiosRequestConfig) => {
    const data = (
      await api.v1.projects
        .project(currentProjectId.value!)
        .languages.getPrimary(config)
    ).data.result;
    primaryLanguage.value = data.lang_tag;
  };

  const updatePrimaryLanguage = async (langTag: string) => {
    await api.v1.projects
      .project(currentProjectId.value!)
      .languages.postPrimaryLanguage(langTag);
  };

  const fetchAllLanguageTags = async () => {
    allLanguageTags.value = (await api.v1.languageTags.get()).data.result;
  };

  const initLanguagesSettings = async (config?: AxiosRequestConfig) => {
    return Promise.all([
      fetchPrimaryLanguage(config),
      fetchAllLanguages(config),
      fetchAllLanguageTags(),
    ]).then(() => {
      if (!currentLanguage.value) {
        currentLanguage.value = primaryLanguage.value;
      }
      return currentLanguage.value;
    });
  };

  const addNewLanguage = async (languageTag: string) => {
    await api.v1.projects
      .project(currentProjectId.value!)
      .languages.postLanguage(languageTag);
  };

  const reorderLanguages = async (data: PutReorderSequenceEntry[]) => {
    await api.v1.projects
      .project(currentProjectId.value!)
      .languages.putReorder({
        new_sequence: data,
      });
  };

  const deleteLanguage = async (languageTag: string) => {
    await api.v1.projects
      .project(currentProjectId.value!)
      .languages.deleteLanguage(languageTag);
  };

  return {
    languages,
    allLanguageTags,
    sortedLanguages,
    primaryLanguage,
    fetchAllLanguages,
    fetchPrimaryLanguage,
    updatePrimaryLanguage,
    fetchAllLanguageTags,
    initLanguagesSettings,
    addNewLanguage,
    reorderLanguages,
    deleteLanguage,
  };
};
