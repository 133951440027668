import { adminRoute } from "@/admin/routes";
import { api } from "@/api";
import CenterLayout from "@/layouts/CenterLayout.vue";
import MainLayout from "@/layouts/MainLayout.vue";
import ProjectsLayout from "@/layouts/ProjectsLayout.vue";
import { WithProjectId } from "@/layouts/WithProjectId";
import { useEditorStore } from "@/stores/editor";
import { getLocalstorageItem } from "@cna/common";
import { AxiosError } from "axios";
import {
  createRouter,
  createWebHistory,
  type RouteLocationNormalized,
  type RouteRecordRaw,
} from "vue-router";

import Editor from "./editor/Editor.vue";
import Home from "./home.vue";
import Login from "./Login.vue";
import Settings from "./settings/Settings.vue";
import VersionsHistory from "./VersionsHistory.vue";

const UserActions = () => import("./UserActions.vue");
const ErrorLayout = () => import("../layouts/ErrorLayout.vue");
const Preview = () => import("./preview/Preview.vue");

export const metaKeyOrEditor = (to: RouteLocationNormalized) =>
  to.meta?.key ?? "editor";

const legacyRouteRedirects = ["editor", "settings", "versions-history"].map(
  (key): RouteRecordRaw => ({
    path: `/${key}`,
    meta: {
      key,
    },
    redirect: (to) => {
      const project = useEditorStore();
      if (!project.currentProjectId) {
        return { path: "/" };
      }

      return {
        path: `/${project.currentProjectId}/${metaKeyOrEditor(to)}`,
      };
    },
  })
);

const hasQueryParams = (route: RouteLocationNormalized) => {
  return !!Object.keys(route.query).length;
};

const preserveQuery: RouteRecordRaw["beforeEnter"] = (to, from, next) => {
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    return next({ ...to, query: from.query });
  }

  return next();
};

const goToProjectIfExists: RouteRecordRaw["beforeEnter"] = (_, __, next) => {
  const projectId = getLocalstorageItem("projectId");
  if (projectId) {
    next({
      path: `/${projectId}/editor`,
    });
  }
  return next();
};

const getAuthStatus = () =>
  api.v1.me
    .getAuthenticated()
    .then((r) => r.status)
    .catch((e) => {
      if (e instanceof AxiosError) {
        return e.status;
      }

      return -1;
    });

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    component: CenterLayout,
    props: {
      title: "You are logged out",
    },
    children: [
      {
        path: "",
        name: "login",
        component: Login,
      },
    ],
    async beforeEnter(_, __, next) {
      const authStatus = await getAuthStatus();

      if (authStatus === 200) {
        return next("/");
      }

      return next();
    },
  },
  {
    path: "/",
    component: ProjectsLayout,
    async beforeEnter(_, __, next) {
      const authStatus = await getAuthStatus();

      if (authStatus === 200) {
        return next();
      }

      return next("/login");
    },
    children: [
      {
        path: "",
        component: CenterLayout,
        props: {
          title: "Portal Editor",
          maxWidth: "100%",
        },
        children: [
          {
            path: "",
            name: "home",
            component: Home,
            beforeEnter: goToProjectIfExists,
          },
        ],
      },
      {
        path: "/:projectId",
        component: WithProjectId,
        props: true,
        children: [
          adminRoute,
          {
            path: "",
            component: MainLayout,
            children: [
              {
                path: "settings",
                component: Settings,
                beforeEnter: preserveQuery,
                meta: {
                  key: "settings",
                },
              },
              {
                path: "editor",
                component: Editor,
                name: "editor",
                beforeEnter: preserveQuery,
                meta: {
                  key: "editor",
                },
              },
              {
                path: "versions-history",
                component: VersionsHistory,
                beforeEnter: preserveQuery,
                meta: {
                  key: "versions-history",
                },
              },
              {
                path: "user-actions",
                component: UserActions,
                beforeEnter: preserveQuery,
                meta: {
                  key: "user-actions",
                },
              },
            ],
          },
          {
            path: "preview",
            component: Preview,
            meta: {
              key: "preview",
            },
          },
        ],
      },
    ],
  },
  ...legacyRouteRedirects,
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: ErrorLayout,
    props: {
      status: "404",
      title: "404 Not Found",
      description: "We couldn't find what you're looking for, sorry. :(",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
