<template>
  <Page id="welcome" class="welcome-page">
    <BodyLayout />
  </Page>
</template>

<script lang="ts">
import { BodyLayout, Page } from "@cna/common";
import { defineComponent } from "vue";

export default defineComponent({
  name: "WelcomePage",
  components: { Page, BodyLayout },
});
</script>
