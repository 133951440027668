<template>
  <div
    class="button-with-menu-list"
    :class="{ [size ?? 'mid']: !!size }"
    @mouseleave="hideMenuByHoverLeave()"
  >
    <span class="button-with-menu-list__label">{{ buttonConfig.label }}</span>
    <button
      class="button-with-menu-list__menu-btn hoverable-button-background"
      aria-label="toggle menu"
      @click="toggleMenuList()"
      @mouseover="showMenuByHover()"
    >
      <i
        :class="
          isShowMenuList
            ? buttonConfig.iconOpen ?? buttonConfig.iconClose
            : buttonConfig.iconClose
        "
        :style="{ 'font-size': buttonConfig.size }"
      />
    </button>
    <div
      v-if="isShowMenuList"
      v-click-outside="onClickOutside"
      class="button-with-menu-list__list"
      :style="menuListPosition"
    >
      <slot v-if="isShowMenuList" :closeMenuList="closeMenuList" />
    </div>
  </div>
</template>

<script lang="ts">
import { type ButtonMenuListProps } from "@/components/buttonWithMenuList/types";
import vClickOutside from "click-outside-vue3";
import { computed, defineComponent, type PropType, ref } from "vue";

export default defineComponent({
  name: "ButtonWithMenuList",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    buttonConfig: {
      type: Object as PropType<ButtonMenuListProps>,
      required: true,
    },
    size: {
      type: String as PropType<"small">,
    },
  },
  emits: ["update:option"],
  setup(props) {
    const isShowMenuList = ref(false);
    const toggleMenuList = () => (isShowMenuList.value = !isShowMenuList.value);
    const showMenuByHover = () => {
      if (props.buttonConfig.openMenuByHover) {
        isShowMenuList.value = true;
      }
    };

    const hideMenuByHoverLeave = () => {
      if (props.buttonConfig.openMenuByHover) {
        isShowMenuList.value = false;
      }
    };

    const onClickOutside = () => (isShowMenuList.value = false);

    const closeMenuList = () => {
      isShowMenuList.value = false;
    };

    const menuListPosition = computed(() => {
      return props.buttonConfig.menuPosition
        ? { [props.buttonConfig.menuPosition]: 0 }
        : { left: 0 };
    });

    return {
      onClickOutside,
      toggleMenuList,
      showMenuByHover,
      hideMenuByHoverLeave,
      isShowMenuList,
      menuListPosition,
      closeMenuList,
    };
  },
});
</script>
