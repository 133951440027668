<template>
  <p>To access the Portal Editor, you must log in with ICONIC first.</p>
  <EButton
    link
    size="small"
    block
    color="primary"
    href="/services/cna-editor/v1/login"
    >Log in</EButton
  >
</template>

<script lang="ts">
import { EButton } from "@/components/button";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    EButton,
  },
});
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}
</style>
