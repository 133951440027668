import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { INITIAL_STYLES } from "./INITIAL_VALUES";
import { type BodyStyles } from "./types";

export const useCommonStylesStore = defineStore("commonStyles", () => {
  const appliedStyles = ref(INITIAL_STYLES);

  const headerStyles = computed(() => appliedStyles.value.header);
  const mainBlockStyles = computed(
    () => appliedStyles.value.body as BodyStyles
  );
  const footerStyles = computed(() => appliedStyles.value.footer);
  const defaultFonts = computed(
    () => appliedStyles.value.general?.fonts?.default || {}
  );

  return {
    appliedStyles,
    headerStyles,
    mainBlockStyles,
    footerStyles,
    defaultFonts,
  };
});
