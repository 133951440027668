import { type ExtractPropTypes } from "vue";
import { modalProps } from "../../elements";

export type CreateEmailRegistrationFieldButtonStatuses = {
  consent_checkboxes: boolean;
  other: boolean;
};

type ToggledField = {
  key: string;
  enabled: boolean;
};
export type ToggledFields = Array<ToggledField>;

export enum EmailRegistrationTitlesDependsOnType {
  form_fields = "Form fields",
  consent_checkboxes = "Consent checkboxes",
  other = "Other",
}

export const formsEditingModalProps = {
  ...modalProps,
} as const;

export type FormsEditingModalProps = ExtractPropTypes<
  typeof formsEditingModalProps
>;
