import type { GetImagesResult } from "@cna/api/editor";
import parseSides from "parse-css-sides";
import { DEFAULT_BACKGROUND_COLOR } from "../constants";
import type {
  BodyStyles,
  DBBodyStyles,
  DBFooterStyles,
  DefaultFontsData,
  FooterStyles,
  HeaderStyles,
} from "../stores/types";
import { defaultStyles } from "./defaultStyles";
const root: HTMLElement | null = document.querySelector(":root");

export const getThemeValue = (key: string) => {
  return root?.style.getPropertyValue(`--${key}`);
};

const setPaddingVars = (padding: string, key: string) => {
  const paddingKeys = [
    "main_padding",
    "full_width_back_button_padding",
    "header_padding",
    "footer_padding",
  ];

  if (!paddingKeys.includes(key)) return;

  const styles = parseSides(padding);

  root?.style.setProperty(`--${key}_left`, styles.left);
  root?.style.setProperty(`--${key}_right`, styles.right);
  root?.style.setProperty(`--${key}_top`, styles.top);
  root?.style.setProperty(`--${key}_bottom`, styles.bottom);
};

export const setCssVariablesForStyles = (
  styles:
    | HeaderStyles
    | BodyStyles
    | DBBodyStyles
    | FooterStyles
    | DBFooterStyles
    | DefaultFontsData,
  fieldName?: string
) => {
  for (const [key, value] of Object.entries(styles)) {
    if (typeof value === "object") {
      setCssVariablesForStyles(value, key);
    } else {
      const cssKey = fieldName ? `${fieldName}_${key}` : key;
      setPaddingVars(value, cssKey);
      root?.style.setProperty(`--${cssKey}`, value);
    }
  }

  styleDeprecations();
};

export const resetStyles = () => {
  if (!root) return;
  // Remove all inline css variables
  root.style.cssText = "";

  Object.entries(defaultStyles).forEach(([key, value]) => {
    root?.style.setProperty(`--${key}`, String(value));
    setPaddingVars(String(value), key);
  });
};

export const setCssVariablesForImages = (
  imagesData: GetImagesResult["result"][string]
) => {
  for (const [key, value] of Object.entries(imagesData)) {
    const { props } = value;
    if (value.link) {
      root?.style.setProperty(`--${key}`, `url(${value.link})`);
    } else {
      root?.style.setProperty(`--${key}`, "");
    }

    root?.style.setProperty(
      `--${key}-color`,
      props?.background_color ?? DEFAULT_BACKGROUND_COLOR
    );
  }
};

export const getFlagIcon = (name: string) => {
  try {
    return new URL(
      `../assets/images/flags/${name.toLocaleLowerCase()}.webp`,
      import.meta.url
    ).href;
  } catch (e) {
    return "";
  }
};

const styleDeprecations = () => {
  deprecateBackgroundImageOverlayOpacity();
};

const deprecateBackgroundImageOverlayOpacity = () => {
  const backgroundImageColor = root?.style.getPropertyValue(
    "--background_image_overlay_color"
  );

  if (backgroundImageColor && backgroundImageColor.length > 7) {
    root?.style.setProperty(`--background_image_overlay_opacity`, "0");
  }
};
