import { api } from "@/api";
import { useEditorStore } from "@/stores/editor";
import { useCommonGeneralStore, type Features } from "@cna/common/stores";
import { entries } from "@gomedia/utils/dist/object";
import { storeToRefs } from "pinia";
import type { ConditionalKeys } from "type-fest";
import { computed, ref, watch, type ComputedRef, type Ref } from "vue";

type FeatureFlag = ConditionalKeys<Features, boolean | undefined>;

type ReadonlyMap<K extends FeatureFlag> = Record<
  `current_${K}`,
  ComputedRef<boolean>
>;
type WriteableMap<K extends FeatureFlag> = Record<K, Ref<boolean>>;

export const useFeatureFlags = <K extends FeatureFlag>(...feats: K[]) => {
  const editorStore = useEditorStore();
  const commonGeneral = useCommonGeneralStore();

  const { features } = storeToRefs(commonGeneral);

  const readonlyValues: Partial<ReadonlyMap<K>> = {};
  const writeValues: Partial<WriteableMap<K>> = {};

  feats.forEach((feature) => {
    const value = computed(() => features.value[feature] ?? false);

    const writeValue = ref(value.value);
    watch(value, (val) => {
      writeValue.value = val;
    });

    writeValues[feature] = writeValue;
    readonlyValues[`current_${feature}`] = value;
  });

  const save = () => {
    const req: Partial<Record<K, boolean>> = {};

    feats.forEach((feature) => {
      if (
        writeValues[feature]?.value ===
        readonlyValues[`current_${feature}`]?.value
      ) {
        return;
      }
      req[feature] = writeValues[feature]?.value;
    });

    const projectApi = api.v1.projects.project(editorStore.currentProjectId!);

    return projectApi.postFeatures(req).then(() => {
      entries(req).forEach(([feature, value]) => {
        features.value[feature] = value;
      });
    });
  };

  return {
    ...readonlyValues,
    ...writeValues,
    saveFeatures: save,
  } as WriteableMap<K> & ReadonlyMap<K> & { saveFeatures: typeof save };
};
