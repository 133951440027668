<template>
  <section class="domains settings-section">
    <SettingsSectionHeader heading="Domains" icon="icon-domain" />
    <DomainSectionRow
      v-for="(value, key) in domains"
      :key="key"
      :domain="value"
      :type="key"
      :is-creating="isCreating"
      @update:isCreating="onChangeIsCreating"
      :disabled="!projectdomainswrite"
    />
    <SettingsCreateRow
      v-if="!domains.production && projectdomainswrite"
      @click="onCreate"
    >
      <template #button-title> + Create Domain </template>
    </SettingsCreateRow>
  </section>
</template>

<script lang="ts">
import { usePermissions } from "@/hooks/usePermissions";
import DomainSectionRow from "@/routes/settings/domainsSection/DomainSectionRow.vue";
import SettingsCreateRow from "@/routes/settings/sectionElements/SettingsCreateRow.vue";
import SettingsSectionHeader from "@/routes/settings/sectionElements/SettingsSectionHeader.vue";
import { useEditorStore } from "@/stores/editor";
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  name: "DomainsSection",
  components: {
    SettingsSectionHeader,
    DomainSectionRow,
    SettingsCreateRow,
  },
  setup() {
    const { projectdomainswrite } = usePermissions();
    const editorStore = useEditorStore();
    const isCreating = ref(false);
    const domains = computed(() =>
      editorStore.domains?.production ? editorStore.domains : { production: "" }
    );

    const onCreate = () => {
      isCreating.value = true;
    };

    const onChangeIsCreating = (value: boolean) => (isCreating.value = value);

    return {
      projectdomainswrite,
      domains,
      onCreate,
      isCreating,
      onChangeIsCreating,
    };
  },
});
</script>

<style lang="scss" scoped>
.settings-section {
  overflow: hidden;
}
</style>
