<template>
  <main class="full-width-secondary-page-body">
    <div
      class="full-width-main-block"
      v-theme-action-box.styles="secondaryPageMainBlockProps()"
    >
      <div class="container" style="max-width: 992px">
        <div class="page__text-block">
          <div class="page__text">
            <div :tabindex="tabindex">
              <Markdown
                :data="markdownData"
                :field="field"
                :template="template"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { secondaryPageMainBlockProps } from "../../../../utils/themeActionBoxElementProps";
import { mdFieldAndTemplateProps } from "../../../markdown/props";
import { injectIsFixedSecondaryPageHeaderEnabled } from "../providables";
import { useSecondaryPageHelper } from "../useSecondaryPageHelper";

export default defineComponent({
  name: "FullWidthSecondaryPageBody",
  props: {
    markdownData: {
      type: String,
      required: true,
    },
    pageName: {
      type: String,
      required: true,
    },
    ...mdFieldAndTemplateProps,
  },
  setup() {
    const { tabindex, footerHeightAsPx, headerHeightAsPx } =
      useSecondaryPageHelper();

    const isFixedSecondaryPageHeaderEnabled =
      injectIsFixedSecondaryPageHeaderEnabled();

    const fixedHeaderHeightAsPx = computed(() =>
      isFixedSecondaryPageHeaderEnabled.value ? headerHeightAsPx.value : "0px"
    );

    return {
      secondaryPageMainBlockProps,
      footerHeightAsPx,
      headerHeightAsPx,
      tabindex,
      fixedHeaderHeightAsPx,
    };
  },
});
</script>

<style scoped lang="scss">
.full-width-main-block {
  padding-top: calc(var(--main_padding_top) + v-bind(fixedHeaderHeightAsPx));
  padding-bottom: var(--main_padding_bottom);
  padding-left: var(--main_padding_left);
  padding-right: var(--main_padding_right);
  min-height: calc(
    100vh - v-bind(footerHeightAsPx) - v-bind(headerHeightAsPx) - 48px
  );
  background-color: var(--main_background_color);
}

.page__text-block {
  padding-top: 20px;
}
</style>
