import { type TableRowConfig } from "@/components/table/types";
import { openModal, type ConfirmationModalProps } from "@/modals";

import { api } from "@/api";
import { usePermissions } from "@/hooks/usePermissions";
import { useEditorStore } from "@/stores/editor";
import { storeToRefs } from "pinia";
import { h } from "vue";
import { useRouter } from "vue-router";

export default function useTableButtonsConfigs(row: TableRowConfig) {
  const router = useRouter();
  const editorStore = useEditorStore();
  const {
    domains,
    isLocalPortalEnv,
    restoringVersionId,
    currentProjectId,
    currentProject,
  } = storeToRefs(editorStore);

  const { projectversionpublishpreview, projectversionpublishproduction } =
    usePermissions();

  const canPublish = domains.value?.production || isLocalPortalEnv.value;

  const getInfoText = (domain: string) =>
    canPublish
      ? undefined
      : () =>
          h("div", { style: { lineHeight: "1.43" } }, [
            "You need to create a domain in ",
            h("a", { class: "editor__link", href: "/settings" }, "Settings"),
            " before publishing to " + domain,
          ]);
  const publishToStgButtonConfig = {
    title: "Publish to Staging",
    infoText: getInfoText("Staging"),
    disabled: !(canPublish && projectversionpublishpreview),
    onClick: () => {
      const confirmationModalConfig: ConfirmationModalProps["config"] = {
        confirmAction: async () => {
          await editorStore.publishPreviewVersion(row.id);
        },
        modalTitle: "Publish to Staging",
        modalText:
          "You are about to publish this version to the Staging domain. You will then be able to preview it in your browser.",
        buttonsConfigs: {
          cancelButton: {
            buttonTitle: "Cancel",
          },
          confirmButton: {
            buttonTitle: "Publish",
          },
        },
      };

      openModal("ConfirmationModal", { config: confirmationModalConfig });
    },
  };

  const restoreButtonConfig = {
    title: "Restore version in Editor",
    onClick: () => {
      restoringVersionId.value = row.id;
      const confirmationModalConfig: ConfirmationModalProps["config"] = {
        confirmAction: async () => {
          await editorStore.restoreVersion(restoringVersionId.value);
          await editorStore.initProjectData();
        },
        modalTitle: "Restore version",
        modalText:
          "The version will be restored in the Editor. You will then be able to edit it and save a new version.",
        buttonsConfigs: {
          cancelButton: {
            buttonTitle: "Cancel",
          },
          confirmButton: {
            buttonTitle: "Restore",
            buttonIcon: "icon-restore",
          },
        },
      };

      openModal("ConfirmationModal", { config: confirmationModalConfig });
    },
  };

  const publishButtonConfig = {
    title: "Publish to Production",
    infoText: getInfoText("Production"),
    disabled: !(canPublish && projectversionpublishproduction),
    onClick: async () => {
      if (isLocalPortalEnv.value) {
        router.replace({
          query: {
            ...router.currentRoute.value.query,
            localPublishVersion: row.id,
          },
        });
      } else if (!row.published) {
        const confirmationModalConfig: ConfirmationModalProps["config"] = {
          confirmAction: async () => {
            await editorStore.publishVersion(row.id);
            await editorStore.fetchVersions();
          },
          modalTitle: "Publish to Production",
          modalText:
            "You are about to publish this version to the Production domain. This version will be pushed to vehicles and visible to passengers.",
          buttonsConfigs: {
            cancelButton: {
              buttonTitle: "Cancel",
            },
            confirmButton: {
              buttonTitle: "Publish",
            },
          },
        };

        openModal("ConfirmationModal", { config: confirmationModalConfig });
      }
    },
  };

  const setDefaultVersionConfig = {
    title: "Set as default version",
    disabled: !editorStore.versionIsPublishedToVehicles(row.id),
    infoText: editorStore.versionIsPublishedToVehicles(row.id)
      ? undefined
      : () =>
          "A version must be published to at least one vehicle before it can be set as the default version",
    onClick: () => {
      restoringVersionId.value = row.id;
      const confirmationModalConfig: ConfirmationModalProps["config"] = {
        confirmAction: async () => {
          await api.v1.projects.project(currentProjectId.value!).put({
            default_version: row.id,
          });

          currentProject.value.default_version = row.id;
        },
        modalTitle: `Set ${row.id} as the default version`,
        modalText:
          "The default version is deployed to all new trains that have not yet received a portal.",
        buttonsConfigs: {
          cancelButton: {
            buttonTitle: "Cancel",
          },
          confirmButton: {
            buttonTitle: "Confirm",
          },
        },
      };

      openModal("ConfirmationModal", { config: confirmationModalConfig });
    },
  };

  const previewButtonConfig = {
    title: "Preview version",
    onClick: () => {
      restoringVersionId.value = row.id;
      const newPath = `/${currentProjectId.value}/preview`;
      const newUrl = new URL(newPath, window.location.origin);
      newUrl.searchParams.set("version_id", String(row.id));
      window.open(newUrl.href, "_blank");
    },
  };

  return {
    publishToStgButtonConfig,
    restoreButtonConfig,
    publishButtonConfig,
    setDefaultVersionConfig,
    previewButtonConfig,
  };
}
