import { computed, type Ref } from "vue";
import type { AxiosRequestConfig } from "axios";
import { api } from "@/api";

import { type EditorFontsList } from "./types";
import {
  type BodyStyles,
  type FontData,
  type StylesData,
  useCommonStylesStore,
} from "@cna/common";
import { type PostStyleInput, type Template } from "@cna/api/editor";

export const useStylesStore = (currentProjectId: Ref<number | null>) => {
  const commonStyles = useCommonStylesStore();

  const buttonProps = computed(() => {
    const { buttons_props } = commonStyles.appliedStyles.body as BodyStyles;
    return buttons_props;
  });

  const fontsList = computed(() =>
    commonStyles.appliedStyles.general.fonts?.list?.reduce(
      (fontsList: EditorFontsList, element: FontData) => {
        return {
          ...fontsList,
          [element.value]: element.title,
        };
      },
      {}
    )
  );

  const fetchStyles = async (config?: AxiosRequestConfig) => {
    commonStyles.appliedStyles = (
      await api.v1.projects.project(currentProjectId.value!).styles.get(config)
    ).data.result as unknown as StylesData;
  };

  const updateTemplateStyles = async (
    data: PostStyleInput,
    templateName: Template
  ) => {
    await api.v1.projects
      .project(currentProjectId.value!)
      .styles.post(templateName, data);
  };

  return {
    buttonProps,
    fontsList,
    fetchStyles,
    updateTemplateStyles,
  };
};
