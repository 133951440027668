import { computed, type Ref } from "vue";
import { type Language } from "@cna/api/editor";
import { useEditorStore } from "@/stores/editor";

export default function useLanguagesOrderHandler(
  sortedLanguages: Ref<Array<Language>>
) {
  const editorStore = useEditorStore();
  const orderedList = computed(() => {
    return sortedLanguages.value.map((el: Language, index: number) => ({
      lang_tag: el.lang_tag,
      order: index,
    }));
  });

  const changeOrder = () => {
    editorStore
      .reorderLanguages(orderedList.value)
      .then(() => editorStore.fetchAllLanguages());
  };

  return {
    orderedList,
    changeOrder,
  };
}
