<template>
  <div class="pop-menu">
    <slot></slot>
  </div>
</template>

<style scoped>
.pop-menu {
  width: 220px;
  display: flex;
  flex-direction: column;
  background: hsl(var(--ico-white));
  color: hsl(var(--ico-secondary));
  padding: var(--ico-spacing-xs);
  border-radius: var(--ico-radius-m);
  box-shadow: 0 8px 16px 0 hsla(var(--ico-box-shadow-color));
}
</style>
