import { computed, ref, type Ref } from "vue";
import {
  INITIAL_DOMAINS_DATA,
  INITIAL_PROJECT_VEHICLES_EVENTS,
} from "../INITIAL_VALUES";
import type { Version, Project } from "@cna/api/editor";
import { api } from "@/api";
import { useNotification } from "@/hooks/use-notification";

export const useVersionsHistoryStore = (
  currentProject: Ref<Project>,
  currentProjectId: Ref<number | null>
) => {
  const versions: Ref<Array<Version>> = ref([]);
  const restoringVersionId = ref(-1);
  const publishedDomains = ref(INITIAL_DOMAINS_DATA);
  const projectVehiclesEvents = ref(INITIAL_PROJECT_VEHICLES_EVENTS);
  const VEHICLES_MONITORING_TABLE_PAGE_OFFSET = ref(20);
  const vehiclesMonitoringTableConfig = ref({
    currentPage: 1,
    pagesAmount: 0,
  });
  const selectedVehiclesIDs: Ref<Array<string>> = ref([]);
  const notification = useNotification();

  const lastSnapshotVersion = computed(() => versions.value[0].id);
  const isLocalPortalEnv = computed(
    () => currentProject.value.portal_env === "local"
  );
  const domains = computed(() => {
    const preview = publishedDomains.value.domains.preview;
    const previewObject = preview ? { preview } : {};
    return {
      ...(currentProject.value.domains || {
        production: "",
      }),
      ...previewObject,
    } as { production?: string; preview?: string };
  });

  const vehiclesWithVersion = (version: number) => {
    const vehiclesWithVersion = projectVehiclesEvents.value.events_list.filter(
      (x) => x.content_version === version
    );
    return vehiclesWithVersion;
  };

  const versionIsPublishedToVehicles = (version: number) => {
    const vehicles = vehiclesWithVersion(version);
    return vehicles.length > 0;
  };

  const createVersion = async (snapshotDescription: string) => {
    return (
      await api.v1.projects
        .project(currentProjectId.value!)
        .versions.postCreate({
          description: snapshotDescription,
        })
    ).data.result;
  };

  const publishVersion = async (versionID: number) => {
    await api.v1.projects
      .project(currentProjectId.value!)
      .publish.publishVersion({
        versionId: versionID,
        type: isLocalPortalEnv.value ? "local" : "cloud",
        system_ids: selectedVehiclesIDs.value,
      });
    await fetchPublishedDomains();
  };

  const restoreVersion = async (snapshotId: number) => {
    await api.v1.projects
      .project(currentProjectId.value!)
      .versions.postRestore(snapshotId)
      .then(() => {
        notification.success();
      });
  };

  const fetchVersions = async () => {
    versions.value = (
      await api.v1.projects.project(currentProjectId.value!).versions.get()
    ).data.result;
  };

  const fetchPublishedDomains = async () => {
    publishedDomains.value = (
      await api.v1.projects
        .project(currentProjectId.value!)
        .versions.getPublishedDomains()
    ).data.result;
  };

  const updateDomain = async (prefix: string) => {
    await api.v1.projects.project(currentProjectId.value!).postDomain({
      prefix,
    });
  };

  const fetchProjectVehiclesEvents = async () => {
    const res = await api.v1.projects
      .project(currentProjectId.value!)
      .vehicleEvents.get();

    projectVehiclesEvents.value = res.data.result;
  };

  const publishPreviewVersion = async (versionId: number) => {
    await api.v1.projects
      .project(currentProjectId.value!)
      .publish.postPreview(versionId)
      .then(() => {
        notification.success();
      });
    await fetchVersions();
    await fetchPublishedDomains();
  };

  return {
    versions,
    restoringVersionId,
    publishedDomains,
    projectVehiclesEvents,
    VEHICLES_MONITORING_TABLE_PAGE_OFFSET,
    vehiclesMonitoringTableConfig,
    selectedVehiclesIDs,
    lastSnapshotVersion,
    isLocalPortalEnv,
    domains,
    versionIsPublishedToVehicles,
    createVersion,
    publishVersion,
    restoreVersion,
    fetchVersions,
    fetchPublishedDomains,
    updateDomain,
    fetchProjectVehiclesEvents,
    publishPreviewVersion,
  };
};
