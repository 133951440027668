import {
  useDocumentVisibility,
  useIdle,
  usePageLeave,
  useWindowScroll,
  useWindowSize,
} from "@vueuse/core";
import { nextTick, onMounted, ref, watch, type Ref } from "vue";

const manualBoxRefresh = ref(Symbol());

export const refreshAllActionBoxes = () => {
  manualBoxRefresh.value = Symbol();
};
export const useStayFixedPlease = (
  value: Ref<string>,
  enabled: Ref<boolean>,
  cb: () => any
) => {
  onMounted(() => {
    nextTick(cb);
    setTimeout(cb, 250);
  });

  const windowScroll = useWindowScroll();
  const windowSize = useWindowSize();
  const hasLeft = usePageLeave();
  const documentVisible = useDocumentVisibility();
  const { idle } = useIdle(60 * 1000);

  watch(
    [
      windowScroll.y,
      windowScroll.x,
      windowSize.height,
      windowSize.width,
      hasLeft,
      idle,
      documentVisible,
      manualBoxRefresh,
    ],
    () => {
      nextTick(cb);
    },
    {
      deep: true,
    }
  );

  watch(
    [value, enabled],
    () => {
      nextTick(refreshAllActionBoxes);
    },
    {
      immediate: true,
      deep: true,
    }
  );

  return {
    refreshAllActionBoxes,
  };
};
