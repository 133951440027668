import { RGBtoHex } from "@/utils/colors";
import FroalaEditor from "froala-editor/js/froala_editor.pkgd.min.js";
import { ref, watch, type Ref } from "vue";
import { useCustomPopupHelper } from "./useCustomPopupHelper";

export const useColorPickerPlugin = (editor: Ref<any>) => {
  const {
    show: showColorPopup,
    leftPosition: colorPopupLeft,
    topPosition: colorPopupTop,
    close: baseCloseColorPopup,
    init: initColorPopup,
  } = useCustomPopupHelper();

  const defaultColor = ref("");
  const selectedColor = ref("");

  const closeColorPopup = (event?: any) => {
    baseCloseColorPopup(event);
    if (!showColorPopup.value) {
      editor.value.selection.restore();
    }
  };

  watch(showColorPopup, (value) => {
    if (value === false) {
      closeColorPopup();
    }
  });

  watch(selectedColor, (value) => {
    if (value === defaultColor.value) {
      return;
    }
    editor.value.selection.restore();
    editor.value.customColorPickerPlugin.applyColor(value);
  });

  FroalaEditor.RegisterCommand("textColor", {
    title: "Text color",
    icon: "textColor",
    undo: true,
    popup: false,
    focus: true,
    refreshAfterCallback: false,
    plugin: "customColorPickerPlugin",
    callback() {
      // Need to save selection that it was possible to restore it after selecting color in the color picker and apply styles
      this.selection.save();
      this.customColorPickerPlugin.togglePopup();
    },
  });

  FroalaEditor.PLUGINS.customColorPickerPlugin = function (editor: any) {
    const button = document.querySelector(
      `[id^="textColor"]`
    ) as HTMLButtonElement;
    initColorPopup(button);

    const setDefaultColor = () => {
      const DEFAULT_TEXT_COLOR = "rgb(0, 0, 0)";

      const rgbColor =
        getComputedStyle(editor.selection.element()).color ||
        DEFAULT_TEXT_COLOR;

      const hexColor = RGBtoHex(rgbColor);
      defaultColor.value = hexColor;
      selectedColor.value = hexColor;
    };

    const applyColor = (color: string) => {
      editor.colors.text(color);
      showColorPopup.value = false;
    };
    const togglePopup = () => {
      const isShownColorPicker = document.getElementsByClassName(
        "n-color-picker-panel"
      );

      if (isShownColorPicker.length) {
        showColorPopup.value = false;
      } else {
        showColorPopup.value = true;
        setDefaultColor();
      }
    };

    return {
      togglePopup,
      applyColor,
    };
  };

  return {
    showColorPopup,
    colorPopupLeft,
    colorPopupTop,
    selectedColor,
  };
};
