<template>
  <template v-if="!actionBoxEnabled">
    <markdown
      v-bind="$attrs"
      :m-if="mIf"
      :data="data"
      :tag="tag"
      :vars="vars"
      field=" "
    >
      <slot></slot>
    </markdown>
  </template>
  <template v-else>
    <Teleport to="body">
      <ActionBox
        ref="actionBox"
        :id="actionBoxId"
        :passthrough="editingCopy"
        @click="editingCopy = true"
      />
    </Teleport>

    <template v-if="!editingCopy">
      <markdown
        v-bind="$attrs"
        ref="readonlyContainer"
        :m-if="mIf"
        :data="data"
        :tag="tag"
        :vars="vars"
        field=" "
      >
        <div ref="emptyContent" style="min-width: 20px; min-height: 20px"></div>
      </markdown>
    </template>
    <template v-else>
      <Teleport to="body">
        <div
          class="toolbar-container"
          ref="toolbarContainer"
          :id="`toolbar-${idx}`"
        >
          <SaveButtonGroup @save="save" @cancel="cancel" />
        </div>
      </Teleport>

      <Editor
        ref="editorContainerNode"
        :toolbar-container="`#toolbar-${idx}`"
        :idx="`inline-editor-${idx}`"
        class="inline-editor"
        :is-now-connected-page="isNowConnectedPage"
        v-model:model-value="valueCopy"
        :enter="enter"
        @esc="cancel"
      />
    </template>
  </template>
</template>

<script lang="ts">
import { Markdown } from "@cna/common";
import { readonlyMdProps } from "@cna/common/components/markdown/props";
import { useElementBounding, useResizeObserver, useVModel } from "@vueuse/core";
import {
  computed,
  defineComponent,
  nextTick,
  onUnmounted,
  ref,
  toRefs,
  watch,
} from "vue";
import { injectEditingDisabled } from "../providables";
import ActionBox from "./ActionBox.vue";
import Editor from "./Editor.vue";
import { editId } from "./global";
import { onElementInvisible } from "./onElementInvisible";
import { editorProps } from "./props";
import SaveButtonGroup from "./SaveButtonGroup.vue";
import { useActionBox, useContainerPosition } from "./useActionBox";
import { useStayFixedPlease } from "./useStayFixedPlease";

export default defineComponent({
  components: {
    Editor,
    Markdown,
    ActionBox,
    SaveButtonGroup,
  },
  props: {
    ...editorProps,
    ...readonlyMdProps,
  },
  emits: {
    save: (_data: string) => true,
    "update:editing": (_editing: boolean) => true,
    "update:value": (_val: string) => true,
  },
  setup(props, ctx) {
    const { idx, text, padding } = toRefs(props);

    const editingCopy = useVModel(props, "editing", ctx.emit);

    const valueCopy = ref(text.value);

    watch(text, () => {
      valueCopy.value = text.value;
    });

    const save = () => {
      if (valueCopy.value === text.value) {
        editingCopy.value = false;
        return;
      }
      nextTick(() => {
        ctx.emit("save", valueCopy.value);
      });
    };

    const reset = () => {
      valueCopy.value = text.value;
    };
    const cancel = () => {
      reset();
      editingCopy.value = false;
    };

    const { editingDisabled } = injectEditingDisabled();
    const editorContainerNode = ref<{
      div: HTMLDivElement;
    } | null>(null);
    const editorContainer = computed(() => editorContainerNode.value?.div);
    const readonlyContainer = ref<{ content: HTMLDivElement } | null>(null);
    const emptyContent = ref<HTMLDivElement | null>(null);
    const toolbarContainer = ref<HTMLDivElement | null>(null);

    const readonlyContainerContent = computed(
      () => readonlyContainer.value?.content ?? emptyContent.value
    );

    const eitherContainer = computed(() =>
      editingCopy.value ? editorContainer.value : readonlyContainerContent.value
    );

    const { ...readonlyContainerBounds } = useElementBounding(
      readonlyContainerContent
    );

    const { ...editorContainerBounds } = useElementBounding(editorContainer);

    const { update: updateEitherContainerBounds, ...eitherContainerBounds } =
      useElementBounding(eitherContainer);

    const { update: updateToolbarContainerBounds, ...toolbarContainerBounds } =
      useElementBounding(toolbarContainer);

    const { getContainerPosition } = useContainerPosition(
      padding,
      () => eitherContainer.value,
      { ...eitherContainerBounds, update: updateEitherContainerBounds }
    );

    const { actionBox, actionBoxEnabled, positionActionBox } = useActionBox(
      idx,
      editingDisabled,
      getContainerPosition
    );
    const positionToolbar = () => {
      if (!editingCopy.value) {
        return;
      }

      if (!toolbarContainer.value) {
        return;
      }
      updateToolbarContainerBounds();

      const position = getContainerPosition();

      if (!position) {
        return;
      }

      const { top } = position;

      toolbarContainer.value.style.top = `max(calc(${top} - ${toolbarContainerBounds.height.value}px - 20px), 0px)`;

      toolbarContainer.value.style.left =
        Math.max(
          editorContainerBounds.x.value +
            editorContainerBounds.width.value / 2 -
            toolbarContainerBounds.width.value / 2,
          10
        ) + "px";
    };

    const positionToolbarAndActionBox = () => {
      positionToolbar();
      positionActionBox();
    };

    useStayFixedPlease(
      valueCopy,
      actionBoxEnabled,
      positionToolbarAndActionBox
    );

    useResizeObserver(editorContainer, positionToolbar);
    useResizeObserver(toolbarContainer, positionToolbar);
    useResizeObserver(readonlyContainerContent, positionActionBox);

    watch(Object.values(editorContainerBounds), positionToolbar);
    watch(Object.values(editorContainerBounds), positionActionBox);
    watch(Object.values(readonlyContainerBounds), positionActionBox);

    onElementInvisible(editorContainer, cancel);

    watch(editingCopy, (val) => {
      positionActionBox();
      if (val) {
        positionToolbar();
        editId.value = props.idx;
        return;
      }
      editId.value = -1;
    });

    onUnmounted(() => {
      editId.value = -1;
    });

    watch(
      valueCopy,
      (val) => {
        ctx.emit("update:value", val);
      },
      { immediate: true }
    );

    return {
      emptyContent,
      editId,
      readonlyContainer,
      actionBox,
      toolbarContainer,
      editorContainerNode,
      valueCopy,
      editingCopy,
      save,
      cancel,
      reset,
      actionBoxEnabled,
    };
  },
});
</script>

<style>
.inline-editor {
  position: relative;
}

.toolbar-container {
  position: fixed;
  width: 1240px;
  max-width: calc(100% - 20px);
  z-index: 105;
}

.toolbar-container .fr-toolbar {
  border: none;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
  margin: 0px;
  border-radius: 8px;
}
.inline-editor .fr-element {
  padding: 0px !important;
  font-family: unset !important;
  color: unset !important;
  font-size: unset !important;
  line-height: unset !important;

  box-sizing: unset !important;
  overflow-x: unset !important;
  text-align: unset !important;

  min-width: 150px !important;
  min-height: 20px !important;
}

.inline-editor .fr-wrapper {
  border: none !important;
  background: inherit !important;
}

.inline-editor .fr-second-toolbar {
  display: none !important;
}
</style>
