<template>
  <div :class="setCreateRowClass">
    <slot v-if="showCreateRow" />
    <EButton v-else @click="$emit('create')" type="text" v-bind="buttonProps">
      <slot name="button-title"></slot>
    </EButton>
  </div>
</template>

<script lang="ts">
import EButton, { type EButtonProps } from "@/components/button/EButton.vue";
import { computed, defineComponent, type PropType } from "vue";

export default defineComponent({
  name: "SettingsCreateRow",
  components: {
    EButton,
  },
  props: {
    showCreateRow: Boolean,
    rowQuantity: {
      type: Number,
      default: 1,
    },
    buttonProps: {
      type: Object as PropType<EButtonProps>,
      default: () => ({}),
    },
  },
  emits: ["create"],
  setup(props) {
    const setCreateRowClass = computed(() => {
      return props.rowQuantity % 2 != 0
        ? "settings-section__creating-row"
        : "settings-section__creating-row settings-section__creating-row--white";
    });

    return {
      setCreateRowClass,
    };
  },
});
</script>
