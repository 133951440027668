<template>
  <div class="edit-paddings-element__sizes">
    <OptionsGroup
      :options="sizeOptions"
      :value="selectedSize"
      @update:value="handleSizeClick"
    />
  </div>
  <div
    class="edit-paddings-element"
    v-if="isCustomSize"
    ref="customPaddingsBlock"
  >
    <EditorInput
      qa-id="top"
      :value="value[0]"
      @update:value="updatePadding(0, $event)"
      :units="units"
    />
    <div style="display: flex; gap: 58px">
      <EditorInput
        qa-id="left"
        :value="value[3]"
        @update:value="updatePadding(3, $event)"
        :units="units"
      />
      <EditorInput
        qa-id="right"
        :value="value[1]"
        @update:value="updatePadding(1, $event)"
        :units="units"
      />
    </div>
    <EditorInput
      qa-id="bottom"
      :value="value[2]"
      @update:value="updatePadding(2, $event)"
      :units="units"
    />
  </div>
</template>

<script lang="ts">
import EditorInput from "@/components/editorInput/EditorInput.vue";
import { capitalizeFirstLetter } from "@/utils";
import { useVModel } from "@vueuse/core";
import { computed, defineComponent, nextTick, type PropType, ref } from "vue";
import OptionsGroup from "./OptionsGroup.vue";

interface SizeValues {
  [key: string]: Array<string>;
}

const createArray = (value: string) => Array(4).fill(value);

const sizeValues: SizeValues = {
  s: createArray("20"),
  m: createArray("30"),
  l: createArray("40"),
};

export default defineComponent({
  name: "EditPaddingsElement",
  components: {
    OptionsGroup,
    EditorInput,
  },
  props: {
    value: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    sizes: {
      type: Array as PropType<Array<string>>,
      default: () => ["s", "m", "l"],
    },
  },
  emits: ["update:value"],
  setup(props, ctx) {
    const valueCopy = useVModel(props, "value", ctx.emit);
    const units = "px";
    const selectedSize = ref("");

    const customPaddingsBlock = ref();
    const isCustomSize = computed(() => selectedSize.value === "custom");

    const sizeOptions = computed(() => [
      ...props.sizes.map((x) => ({ label: x.toUpperCase(), value: x })),
      { label: "Custom", value: "custom" },
    ]);

    const updatePadding = (index: number, value: string) => {
      valueCopy.value = [
        ...valueCopy.value.slice(0, index),
        value,
        ...valueCopy.value.slice(index + 1),
      ];
    };

    const handleSizeClick = (value: string) => {
      selectedSize.value = value;
      if (value !== "custom") {
        valueCopy.value = sizeValues[value];
      } else {
        nextTick(() => {
          customPaddingsBlock.value?.scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
        });
      }
    };

    const arrayEquals = (a: string[], b: string[]) =>
      a.every((val, index) => val === b[index]);

    const determineSizeKey = () => {
      for (const [key, val] of Object.entries(sizeValues)) {
        if (arrayEquals(props.value, val)) {
          selectedSize.value = key;
          return;
        }
      }
      selectedSize.value = "custom";
    };

    determineSizeKey();

    return {
      units,
      updatePadding,
      valueCopy,
      handleSizeClick,
      selectedSize,
      capitalizeFirstLetter,
      customPaddingsBlock,
      sizeOptions,
      isCustomSize,
    };
  },
});
</script>

<style lang="scss" scoped>
.edit-paddings-element {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  font-weight: 400;
}

.edit-paddings-element:deep(input) {
  width: 58px;
}
</style>
