<template>
  <RightPanelModal :title="title" :on-apply-changes="saveStyles" scrolled>
    <RightPanelSection title="Block fill" color-picker>
      <ColorPickerElement
        :color="getStyleWithDefault(`${fieldName}_background_color`)"
        @update:color="updateStyle(`${fieldName}_background_color`, $event)"
        :opacity="getStyleWithDefault('main_opacity')"
        show-alpha
      />
    </RightPanelSection>

    <RightPanelSection title="Text color" color-picker>
      <ColorPickerElement
        :color="getStyleWithDefault(`${fieldName}_text_color`)"
        @update:color="updateStyle(`${fieldName}_text_color`, $event)"
      />
    </RightPanelSection>

    <RightPanelSection v-if="!isTwoColumn" title="Width">
      <EditorInput v-model:value="mainWidth" units="px" />
    </RightPanelSection>

    <RightPanelSection v-else title="Column Gap">
      <EditorInput qa-id="column-gap" v-model:value="gap" units="px" />
    </RightPanelSection>

    <RightPanelSection v-if="isWelcomePage" title="Padding">
      <EditPaddingsElement v-model:value="padding" />
    </RightPanelSection>

    <RightPanelSection v-if="isWelcomePage" title="Corner radius">
      <RangeSlider
        :slider-params="borderRadiusSliderParams"
        :init-value="getStyleWithDefault('main_border_radius')"
        @update:option="updateStyle('main_border_radius', $event)"
      />
    </RightPanelSection>

    <RightPanelSection v-if="isWelcomePage" title="Alignment">
      <AlignmentElement
        :value="getStyleWithDefault('main_t_and_c_checkbox_alignment')"
        @update:value="updateStyle('main_t_and_c_checkbox_alignment', $event)"
      />
    </RightPanelSection>
  </RightPanelModal>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useEditorStore } from "@/stores/editor";
import { storeToRefs } from "pinia";
import type { Template } from "@cna/api/editor";

import EditorInput from "@/components/editorInput/EditorInput.vue";
import {
  type RangeSliderProps,
  RangeSliderUnits,
} from "@/components/rangeSlider/types";
import RangeSlider from "@/components/rangeSlider/RangeSlider.vue";

import useStylesHandler from "./hooks/useStylesHandler";
import { RightPanelModal } from "../../elements";
import { pageMainBlockEditingModalProps } from "./PageMainBlockEditingModalProps";
import AlignmentElement from "../../elements/rightPanel/AlignmentElement.vue";
import EditPaddingsElement from "../../elements/rightPanel/EditPaddingsElement.vue";
import ColorPickerElement from "../../elements/rightPanel/ColorPickerElement.vue";
import RightPanelSection from "../../elements/rightPanel/RightPanelSection.vue";

const borderRadiusSliderParams = {
  minValue: 0,
  maxValue: 100,
  title: "",
  units: RangeSliderUnits.PX,
} as RangeSliderProps;

export default defineComponent({
  name: "PageMainBlockEditingModal",
  components: {
    RangeSlider,
    RightPanelSection,
    RightPanelModal,
    EditorInput,
    AlignmentElement,
    EditPaddingsElement,
    ColorPickerElement,
  },
  props: pageMainBlockEditingModalProps,
  setup(props) {
    const isWelcomePage = computed(() => props.pageName === "welcome");
    const {
      padding,
      saveStyles,
      getStyleWithDefault,
      updateStyle,
      gap,
      mainWidth,
    } = useStylesHandler(
      props.templateName as Template,
      props.fieldName,
      props.pageName
    );

    const editorStore = useEditorStore();
    const { isTwoColumn } = storeToRefs(editorStore);

    return {
      saveStyles,
      padding,
      getStyleWithDefault,
      updateStyle,
      gap,
      isTwoColumn,
      isWelcomePage,
      mainWidth,
      borderRadiusSliderParams,
    };
  },
});
</script>
