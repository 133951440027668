<template>
  <OneColumnLayout>
    <div
      class="page__main-block"
      v-theme-action-box.styles="mainProps('otp_verification')"
    >
      <div class="page__text-block">
        <div class="page__text">
          <div class="page__markdown">
            <Markdown
              :data="texts.verification_text"
              field="verification_text"
            />
            <div class="page__edit-block">
              <span>
                {{ phoneNumber.code }}{{ phoneNumber.phoneNumber }}&nbsp;
              </span>
              <component
                id="edit-phone-link"
                :is="linkType"
                to="/"
                @click="editPhoneButtonConfig.onClick"
              >
                <SimpleText field="edit_phone_button_label">
                  <span class="page__edit-phone">
                    {{ editPhoneButtonConfig.title || "+ Add Title" }}
                  </span>
                </SimpleText>
              </component>
            </div>
            <div class="page__form">
              <div class="page__verification-input">
                <CommonInput
                  v-model="verificationCodeProps"
                  :config="inputConfig"
                />
              </div>
              <div class="page__main-block-actions">
                <CommonButton
                  :config="verifyButtonConfig"
                  :type="verifyButtonType"
                  field="verification_button_label"
                />
                <div v-if="phoneNumber.phoneNumber" class="page__resend-block">
                  <div v-if="!loading" class="page__resend-buttons">
                    <span v-show="isResent" class="page__resend-code-text">
                      <span>
                        {{ texts.verification_resent_text }}
                      </span>
                    </span>
                    <CommonButton
                      v-show="isResent"
                      class="page__resend-code-btn-if-resent"
                      styles-button-type="text-button"
                      :config="resendButtonConfigProps"
                      field="resend_button_label_if_resent"
                    />
                    <CommonButton
                      v-show="!isResent"
                      class="page__resend-code-btn"
                      styles-button-type="text-button"
                      :config="resendButtonConfigProps"
                      field="resend_button_label"
                    />
                  </div>
                  <Spinner v-else />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </OneColumnLayout>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  ref,
  type ButtonHTMLAttributes,
  type PropType,
} from "vue";
import { type OTPVerificationPageTexts } from "../../../stores/types";
import CommonButton from "../../button/CommonButton.vue";
import { OneColumnLayout } from "../../columns";
import CommonInput from "../../input/CommonInput.vue";
import Spinner from "../../spinner/Spinner.vue";
import {
  type ButtonConfig,
  type InputConfig,
  type SelectedPhoneNumberWithCountryCodeAndISO,
} from "../../types";
import { mainProps } from "../../../utils/themeActionBoxElementProps";

export default defineComponent({
  name: "OTPVerificationPageBody",
  components: {
    CommonButton,
    CommonInput,
    Spinner,
    OneColumnLayout,
  },
  props: {
    texts: {
      type: Object as PropType<OTPVerificationPageTexts>,
      required: true,
    },
    verifyButtonConfig: {
      type: Object as PropType<ButtonConfig>,
      required: true,
    },
    verifyButtonType: {
      type: String as PropType<ButtonHTMLAttributes["type"]>,
      required: false,
      default: "button",
    },
    resendButtonConfig: {
      type: Object as PropType<ButtonConfig>,
      required: true,
    },
    editPhoneButtonConfig: {
      type: Object as PropType<ButtonConfig>,
      required: true,
    },
    linkType: {
      type: String,
      required: false,
      default: "span",
    },
    phoneNumber: {
      type: Object as PropType<SelectedPhoneNumberWithCountryCodeAndISO>,
      required: false,
      default: () => ({
        code: "",
        phoneNumber: "",
      }),
    },
    inputConfig: {
      type: Object as PropType<InputConfig>,
      required: true,
    },
    verificationCode: {
      type: String,
      required: false,
      default: "",
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["update:option"],
  setup(props, { emit }) {
    const isResent = ref(false);

    const verificationCodeProps = computed({
      get: () => props.verificationCode,
      set: (value) => emit("update:option", value),
    });

    const resendButtonConfigProps = computed(
      () =>
        ({
          title: isResent.value
            ? props.resendButtonConfig.title_if_resent
            : props.resendButtonConfig.title,
          onClick: () => {
            isResent.value = true;
            if (props.resendButtonConfig.onClick) {
              props.resendButtonConfig.onClick();
            }
          },
          styles: { textDecoration: "underline" },
        } as ButtonConfig)
    );

    // needed for android otp code autocomplete
    if ("OTPCredential" in window) {
      const credentials: any = { otp: { transport: ["sms"] } };
      navigator.credentials
        .get(credentials)
        .then((otp: any) => {
          emit("update:option", otp.code);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return {
      isResent,
      resendButtonConfigProps,
      verificationCodeProps,
      mainProps,
    };
  },
});
</script>

<style lang="scss" scoped>
.page__edit-block {
  display: flex;
  justify-content: center;
  margin-top: -10px;
}

.page__edit-phone {
  text-decoration: underline;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    text-shadow: 0 0 1px var(--main_text_color);
  }
}

.page__verification-input {
  margin-bottom: 24px;
}

.page__verification-input :deep(input) {
  letter-spacing: 20px;
  text-indent: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.page__resend-block {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  min-height: 38px;
}

.page__resend-buttons {
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    vertical-align: center;
  }
}
</style>
