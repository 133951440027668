<template>
  <div class="flex-row-center-space-between">
    <span><slot></slot></span>
    <EButtonGroup>
      <EButton size="small" color="accent" @click="$emit('cancel')"
        >Cancel</EButton
      >
      <EButton size="small" @click="$emit('delete')">Delete</EButton>
    </EButtonGroup>
  </div>
</template>

<script lang="ts">
import { EButton, EButtonGroup } from "@/components/button";
import { defineComponent } from "vue";

export default defineComponent({
  name: "SettingsConfirmationRow",
  emits: ["cancel", "delete"],
  components: {
    EButton,
    EButtonGroup,
  },
});
</script>
