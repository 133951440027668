<template>
  <PopMenu>
    <div qa-id="user-menu-details" class="user-details">
      <Tag>{{ role }}</Tag>
      <NEllipsis
        :tooltip="false"
        style="font-weight: normal; font-size: 12px; line-height: 1.5"
      >
        {{ email }}
      </NEllipsis>
    </div>

    <ul class="options">
      <li v-if="adminaccess">
        <EButton
          qa-id="user-menu-admin-btn"
          v-bind="buttonFormat"
          @click="openAdminPage"
        >
          <LeftIcon>
            <SettingsIcon v-bind="iconSize" />
          </LeftIcon>
          Editor Settings
        </EButton>
      </li>

      <li>
        <EButton
          qa-id="user-menu-logout-btn"
          v-bind="buttonFormat"
          @click="logOut"
        >
          <LeftIcon>
            <LogoutIcon v-bind="iconSize" />
          </LeftIcon>
          Logout
        </EButton>
      </li>
    </ul>
  </PopMenu>
</template>

<script lang="ts">
import { api, memoMe } from "@/api";
import { EButton, type EButtonProps } from "@/components/button";
import Icon from "@/components/icons/Icon.vue";
import LogoutIcon from "@/components/icons/LogoutIcon.vue";
import SettingsIcon from "@/components/icons/SettingsIcon.vue";
import { PopMenu } from "@/components/popover";
import Tag from "@/components/tag/Tag.vue";
import { usePermissions } from "@/hooks/usePermissions";
import { useEditorStore } from "@/stores/editor";
import type { UserMetadata } from "@cna/api/editor";
import { capitalizeFirstLetter } from "@gomedia/utils/dist/string";
import { NEllipsis } from "naive-ui";
import { computed, defineComponent, h, ref } from "vue";
import { useRouter } from "vue-router";

const iconSize = {
  width: "20",
  height: "20",
} as const;

const buttonFormat: EButtonProps = {
  size: "small",
  type: "ghost",
  shape: "square",
  color: "accent",
  block: true,
  style: "justify-content: start",
};

const LeftIcon = defineComponent(
  (_, ctx) => () => h(Icon, { position: "left" }, ctx.slots.default)
);

export default defineComponent({
  components: {
    PopMenu,
    NEllipsis,
    EButton,
    LeftIcon,
    SettingsIcon,
    LogoutIcon,
    Tag,
  },
  setup() {
    const { adminaccess } = usePermissions();

    const editorStore = useEditorStore();

    const me = ref<UserMetadata | null>(null);

    memoMe().then((res) => {
      me.value = res.data.result;
    });

    const router = useRouter();
    const logOut = async () => {
      await api.v1.me.postLogout().then(() => {
        router.replace({ name: "login" });
      });
    };

    const openAdminPage = async () => {
      router.push({
        name: "admin",
        params: { projectId: editorStore.currentProjectId },
      });
    };

    const role = computed(() =>
      capitalizeFirstLetter(
        me.value?.role_internal
          .replace(/internal|external/, "")
          .replace(/_/g, " ") ?? ""
      )
    );

    const email = computed(() => me.value?.email);

    return {
      iconSize,
      buttonFormat,
      logOut,
      openAdminPage,
      adminaccess,
      role,
      email,
    };
  },
});
</script>

<style lang="scss" scoped>
.user-details {
  padding: var(--ico-spacing-xs);
  display: flex;
  flex-direction: column;
  gap: var(--ico-spacing-xxs);
}

.options {
  list-style-type: none;
  margin: 0;
  padding: 0;
  gap: var(--ico-spacing-xs);
  display: flex;
  flex-direction: column;
}
</style>
