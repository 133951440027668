import { defaultStyles } from "@cna/common/utils";
import { type EditorSettings } from "../../../api/src/editor";
const root = document.querySelector(":root") as HTMLElement;

export const setCssVariablesForEditorSettings = (styles: EditorSettings) => {
  root?.style.setProperty(
    `--edit_text_hover_color`,
    styles.edit_text_hover_color || defaultStyles.edit_text_hover_color
  );

  root?.style.setProperty(
    `--edit_theme_hover_color`,
    styles.edit_theme_hover_color || defaultStyles.edit_theme_hover_color
  );
};
