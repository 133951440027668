import {
  useCommonGeneralStore,
  useCommonStylesStore,
} from "@cna/common/stores";
import { useFeatureFlags } from "./useFeatureFlags";

export const useStretchBackground = () => {
  const commonStyles = useCommonStylesStore();
  const commonGeneral = useCommonGeneralStore();

  const { stretch_background, saveFeatures } =
    useFeatureFlags("stretch_background");

  const setStretchBackground = () => {
    const headerOpaque = isColourOpaque(
      commonStyles.headerStyles.header_background_color
    );

    const footerOpaque = isColourOpaque(
      commonStyles.footerStyles.footer_background_color
    );

    const hasHeader = commonGeneral.templates.header !== "no_header";
    const hasFooter = commonGeneral.templates.footer !== "no_footer";

    if (headerOpaque && hasHeader && !hasFooter) {
      stretch_background.value = false;
    } else if (footerOpaque && hasFooter && !hasHeader) {
      stretch_background.value = false;
    } else if (!headerOpaque && hasHeader && !hasFooter) {
      stretch_background.value = true;
    } else if (!footerOpaque && hasFooter && !hasHeader) {
      stretch_background.value = true;
    } else if (hasHeader && hasFooter && headerOpaque && footerOpaque) {
      stretch_background.value = false;
    } else if (hasHeader && hasFooter && (!headerOpaque || !footerOpaque)) {
      stretch_background.value = true;
    }

    return saveFeatures();
  };

  return { setStretchBackground };
};

const isColourOpaque = (hex?: string) => {
  if (!hex) {
    return false;
  }

  if (hex.startsWith("#")) {
    hex = hex.replace("#", "");
  }

  if (hex.length === 6) {
    return true;
  }

  if (hex.length > 6) {
    const alpha = hex.substring(6, 8);
    return alpha.toUpperCase() === "FF";
  }

  return true;
};
