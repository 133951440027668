import { createDiscreteApi } from "naive-ui";
import { THEME_OVERRIDES } from "@/constants";
import type { NotificationApiInjection } from "naive-ui/es/notification/src/NotificationProvider";
import { v4 as uuidv4 } from "uuid";
import { h } from "vue";
import EButton from "@/components/button/EButton.vue";

const { notification } = createDiscreteApi(["notification"], {
  configProviderProps: {
    themeOverrides: THEME_OVERRIDES,
  },
});

type CreateFn = NotificationApiInjection["create"];
type ErrorFn = NotificationApiInjection["error"];
type SuccessFn = NotificationApiInjection["success"];
type WarningFn = NotificationApiInjection["warning"];
type InfoFn = NotificationApiInjection["info"];
type ConfirmFn = NotificationApiInjection["info"];

type NotificationFunction =
  | CreateFn
  | ErrorFn
  | SuccessFn
  | WarningFn
  | InfoFn
  | ConfirmFn;

type CreateOptions = Parameters<CreateFn>[0];
type ErrorOptions = Parameters<ErrorFn>[0];
type SuccessOptions = Parameters<SuccessFn>[0];
type WarningOptions = Parameters<WarningFn>[0];
type InfoOptions = Parameters<InfoFn>[0];
type ConfirmOptions = Parameters<ConfirmFn>[0] & {
  onConfirm: () => void;
  onCancel: () => void;
  confirmButtonTitle: string;
  closeButtonTitle: string;
};

type Notification = (
  | CreateOptions
  | ErrorOptions
  | SuccessOptions
  | WarningOptions
  | InfoOptions
  | ConfirmOptions
) & {
  id?: string;
  type?: CreateOptions["type"];
};

const localStoreKey = "local:notifications";

const notificationStore = {
  value(): Notification[] {
    return JSON.parse(localStorage.getItem(localStoreKey) ?? "[]");
  },
  prepend(value: Notification) {
    const v = this.value();
    value.id = uuidv4();
    v.unshift(value);
    localStorage.setItem(localStoreKey, JSON.stringify(v));
  },
};

const storeAndNotify = <O extends Notification>(
  fn: NotificationFunction,
  options: O
) => {
  notificationStore.prepend(options);
  return fn(options);
};

const defaultOptions = () => ({
  meta: new Date().toLocaleString(),
  duration: 5000,
});

export const useNotification = () => {
  return {
    create: (options: CreateOptions) =>
      storeAndNotify(notification.create, { ...defaultOptions(), ...options }),
    error: (options: ErrorOptions) =>
      storeAndNotify(notification.error, {
        ...defaultOptions(),
        duration: undefined,
        closable: true,
        type: "error",
        class: "custom-error-notification",
        ...options,
      }),
    success: (options?: SuccessOptions) =>
      storeAndNotify(notification.success, {
        ...defaultOptions(),
        type: "success",
        title: "Success",
        closable: false,
        class: "custom-success-notification",
        ...options,
      }),
    warning: (options: WarningOptions) =>
      storeAndNotify(notification.warning, {
        ...defaultOptions(),
        duration: undefined,
        closable: true,
        type: "warning",
        ...options,
      }),
    info: (options: InfoOptions) =>
      storeAndNotify(notification.info, {
        ...defaultOptions(),
        type: "info",
        ...options,
      }),
    confirm: (options: ConfirmOptions) =>
      storeAndNotify(notification.create, {
        ...defaultOptions(),
        ...options,
        closable: false,
        type: "default",
        duration: undefined,
        class: "custom-confirm-notification",
        action: () => [
          h(
            EButton,
            {
              size: "small",
              color: "accent",
              onClick: () => {
                options.onCancel();
              },
            },
            () => options.closeButtonTitle
          ),
          h(
            EButton,
            {
              size: "small",
              color: "primary",
              onClick: () => {
                options.onConfirm();
              },
            },
            () => options.confirmButtonTitle
          ),
        ],
      }),
    destroyAll: notification.destroyAll,
  };
};
