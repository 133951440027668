import { APP_NAME } from "@/constants";
import { useEditorStore } from "@/stores/editor";
import { type Language } from "@cna/api/editor";
import {
  injectedHooks,
  useCommonGeneralStore,
  useRouteQuery,
  type TypesOfButtons,
  type TypesOfLinksBlocks,
} from "@cna/common";
import type { AxiosRequestConfig } from "axios";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const useLanguagesHelper = () => {
  const commonGeneral = useCommonGeneralStore();
  const editorStore = useEditorStore();
  const versionId = useRouteQuery("version_id", { type: String });

  const { sortedLanguages, allLanguageTags } = storeToRefs(editorStore);

  const currentLanguageKey = computed(() => commonGeneral.currentLanguage);
  const currentLanguage = computed(() => ({
    key: commonGeneral.currentLanguage,
    value: allLanguageTags.value[commonGeneral.currentLanguage]?.longname,
  }));

  const dropdownOptions = computed(() => {
    return sortedLanguages.value.map((language: Language) => ({
      key: language.lang_tag,
      value: allLanguageTags.value[language.lang_tag].longname,
    }));
  });

  const onSelectLanguage = async (value: string) => {
    const config: AxiosRequestConfig = { params: {} };

    if (versionId.value) {
      config.params.version_id = versionId.value;
    }

    commonGeneral.currentLanguage = value;
    await editorStore.fetchTexts(value, config);
  };

  // check if we have this lang for the project
  const isLanguageAllowed =
    currentLanguageKey.value &&
    sortedLanguages.value
      .map((language) => language.lang_tag)
      .includes(currentLanguageKey.value);
  if (!isLanguageAllowed) {
    onSelectLanguage(commonGeneral.primaryLanguage);
  }

  return {
    dropdownOptions,
    onSelectLanguage,
    currentLanguage,
    currentLanguageKey,
  };
};

const TYPES_OF_LINKS_BLOCKS: TypesOfLinksBlocks = {
  headerLogoLink: "div",
  footerLink: "span",
  bodyTermsLink: "span",
};

const TYPES_OF_BUTTONS: TypesOfButtons = {
  connectButtonType: "button",
};

export default function saveGeneralDataToCommonStore() {
  const commonGeneral = useCommonGeneralStore();
  commonGeneral.appName = APP_NAME;
  injectedHooks.useLanguageDropdown = useLanguagesHelper;

  commonGeneral.typesOfLinksBlocks = TYPES_OF_LINKS_BLOCKS;
  commonGeneral.typesOfButtons = TYPES_OF_BUTTONS;
  commonGeneral.isVenueHealthy = true;
}
