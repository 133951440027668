import { computed, ref, type Ref } from "vue";
import { api } from "@/api";

import { type Pages } from "@/pages/types";
import type { ListProjectData } from "@cna/api/editor";
import {
  getLocalstorageItem,
  removeLocalstorageItem,
  setLocalstorageItem,
} from "@cna/common";
import { INITIAL_PROJECT, INITIAL_SWATCHES } from "../INITIAL_VALUES";

export const useProjectsStore = () => {
  const projects: Ref<ListProjectData[]> = ref([]);
  const currentProjectId: Ref<number | null> = ref(null);
  const isProjectDataLoaded = ref(false);
  const areProjectsInited = ref(false);
  const currentScreenSize = ref("desktop");
  const currentProject = ref(INITIAL_PROJECT);
  const activePageName = ref("");
  const projectPages: Ref<Array<Pages>> = ref([]);

  const isDBPortalType = computed(() =>
    ["connect_with_checkbox_logo_leftAligned", "db_fv"].includes(
      currentProject.value.templates.body
    )
  );

  const isTwoColumn = computed(
    () =>
      currentProject.value.templates.body === "email_registration_two_column"
  );

  const swatches = computed(
    () => currentProject.value.editor_settings?.swatches ?? INITIAL_SWATCHES
  );

  const editorSettings = computed(
    () => currentProject.value.editor_settings ?? {}
  );

  const fetchProjects = async () => {
    projects.value = (await api.v1.projects.get()).data.result;
  };

  const fetchCurrentProjectId = () => {
    const projectId = getLocalstorageItem("projectId");
    if (projectId != null) {
      currentProjectId.value = Number(projectId);
    }
  };

  const updateCurrentProjectId = (projectId: number) => {
    setLocalstorageItem("projectId", projectId);
    fetchCurrentProjectId();
  };

  const removeCurrentProjectId = () => {
    removeLocalstorageItem("projectId");
    currentProjectId.value = null;
  };

  const initProjectsData = async () => {
    isProjectDataLoaded.value = false;
    areProjectsInited.value = false;
    fetchCurrentProjectId();
    await fetchProjects();
    areProjectsInited.value = true;
  };

  const updateSwatches = async (colors: string[]) => {
    await api.v1.projects.project(currentProject.value.id).putEditorSettings({
      ...editorSettings.value,
      swatches: colors,
    });
  };

  return {
    projects,
    currentProjectId,
    isProjectDataLoaded,
    areProjectsInited,
    currentScreenSize,
    currentProject,
    activePageName,
    projectPages,
    isDBPortalType,
    isTwoColumn,
    swatches,
    editorSettings,
    fetchCurrentProjectId,
    fetchProjects,
    updateCurrentProjectId,
    removeCurrentProjectId,
    initProjectsData,
    updateSwatches,
  };
};
