<template>
  <div class="save-button-group">
    <div class="button-group">
      <EButton class="btn" @click="$emit('cancel')"
        ><i class="icon-close" style="font-size: 12px; color: white"></i
      ></EButton>
      <EButton class="btn" @click="$emit('save')" color="primary"
        ><i class="icon-tick" style="font-size: 12px; color: white"></i
      ></EButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import EButton from "../../button/EButton.vue";

export default defineComponent({
  components: {
    EButton,
  },
});
</script>

<style scoped>
.save-button-group {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.save-button-group .button-group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: white;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
  gap: 8px;
  padding: 6px 8px;
  padding-top: 8px;
  border-radius: 8px;
  margin-bottom: 8px;
}

.save-button-group .btn {
  padding: 6px;
  min-height: unset;
  font-size: 12px;
}
</style>
