<template>
  <DropdownWithSearch
    class="home-dropdown"
    style="width: 320px; max-width: 320px"
    fixed-popup-width="320px"
    v-if="areProjectsInited"
    :items="projectsDropdownOptions"
    :selected-project="selectedProject"
    @select="onSelectProject"
    @change="onChangeSearch"
    placeholder="Select a Project"
  />
  <LoaderWithText v-else style="padding: 8px 16px">
    Projects are loading
  </LoaderWithText>
  <RouterLink :to="`/${selectedProjectId}`" custom v-slot="{ navigate }">
    <EButton
      color="primary"
      block
      @click="navigate"
      :disabled="!selectedProjectId"
    >
      Open
    </EButton>
  </RouterLink>
</template>

<script lang="ts">
import EButton from "@/components/button/EButton.vue";
import { LoaderWithText } from "@cna/common";
import { defineComponent } from "vue";
import DropdownWithSearch from "../components/dropdown/DropdownWithSearch.vue";
import { usePagesDropdownHelper } from "../components/dropdown/usePagesDropdownHelper";

export default defineComponent({
  name: "InitialScreenModal",
  components: {
    DropdownWithSearch,
    EButton,
    LoaderWithText,
  },
  setup() {
    const {
      projectsDropdownOptions,
      onChangeSearch,
      currentProject,
      redirectToProject,
      selectedProject,
      onSelectProject,
      selectedProjectId,
      areProjectsInited,
    } = usePagesDropdownHelper();

    return {
      selectedProject,
      onSelectProject,
      areProjectsInited,
      projectsDropdownOptions,
      onChangeSearch,
      currentProject,
      redirectToProject,
      selectedProjectId,
    };
  },
});
</script>
