import { defineAsyncComponent } from "vue";
import type { MapProperty } from "../types";

export const getTemplateComponent = <T extends string, C extends string>(
  template: T,
  component: C
) =>
  ({
    [component]: defineAsyncComponent(
      () => import(`../templates/${template}/${component}.vue`)
    ),
  } as MapProperty<C, any>);
