import { type ExtractPropTypes } from "vue";
import { modalProps } from "../../elements";

export const blockStylesEditingModalProps = {
  fieldName: {
    type: String,
    required: true,
  },
  pageName: {
    type: String,
    required: true,
  },
  templateName: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: "Text and background color",
  },
  ...modalProps,
} as const;

export type BlockStylesEditingModalProps = ExtractPropTypes<
  typeof blockStylesEditingModalProps
>;
