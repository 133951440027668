<template>
  <div v-click-outside="onClose" class="custom-dropdown">
    <div
      ref="selectedItemRef"
      class="custom-dropdown__selected-item"
      tabindex="0"
      @click.prevent="toggleMenu"
      @keydown.enter="toggleMenu"
    >
      <span
        v-if="!areItemsSelected || areMenuItemsShown"
        class="custom-dropdown__placeholder"
      >
        {{ placeholder }}
      </span>
      <span v-else>{{ selectedItemsInfo }}</span>
      <span class="icon-dropdown" />
    </div>
    <div v-if="areMenuItemsShown" class="custom-dropdown__dropdown-items">
      <div class="custom-dropdown__selected-items-info">
        <span v-if="areItemsSelected" class="flex-row-center-space-between">
          <span>{{ selectedItemsInfo }}</span>
          <EButton size="small" color="accent" @click="$emit('change', [])">
            Clear
          </EButton>
        </span>
        <span v-else> No items selected </span>
      </div>

      <div class="custom-dropdown__search-item">
        <SearchInput
          ref="searchInputRef"
          :config="searchInputConfig"
          :value="searchValue"
          no-results-search-icon="icon-search-default"
          @update:searchInput="onSearchInputUpdate"
        />
      </div>

      <ul class="custom-dropdown__list">
        <template
          v-for="(item, index) in filteredItemsBySearch"
          :key="item.key"
        >
          <li
            ref="menuItemsRefs"
            class="custom-dropdown__list-item"
            :tabindex="index"
            @click.prevent="onSelectMenuItem(item)"
            @keydown.tab.exact="moveNext"
            @keydown.shift.tab="movePrev"
            @keydown.esc="closeMenu"
            @keyup.enter.exact="onSelectMenuItem(item)"
            @keydown.down="moveNext"
            @keydown.up="movePrev"
          >
            <EditorCheckbox :value="isItemSelected(item)">
              <NEllipsis
                :style="{ width: labelWidth, lineHeight: '1.2' }"
                :tooltip="{ contentStyle: 'color: white' }"
              >
                {{ item.value }}
              </NEllipsis>
            </EditorCheckbox>
          </li>
        </template>
      </ul>

      <div
        v-if="filteredItemsBySearch.length === 0"
        class="custom-dropdown__no-items"
      >
        <span>{{ textIfNoItems }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import EButton from "@/components/button/EButton.vue";
import EditorCheckbox from "@/components/checkbox/EditorCheckbox.vue";
import {
  SearchInput,
  type DropdownItem,
  type DropdownItems,
} from "@cna/common";
import vClickOutside from "click-outside-vue3";
import { NEllipsis } from "naive-ui";
import { computed, defineComponent, ref, toRefs, type PropType } from "vue";
import { useDropdownHelper } from "./useDropdownHelper";

export default defineComponent({
  name: "MultiSelectDropdown",
  components: { EButton, SearchInput, EditorCheckbox, NEllipsis },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    items: {
      type: Array as PropType<DropdownItems>,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    textIfNoItems: {
      type: String,
      required: false,
      default: "Your search didn't have any matches",
    },
    selectedItems: {
      type: Array as PropType<Array<string | number>>,
      required: true,
      default: () => [],
    },
  },
  emits: ["change", "close"],
  setup(props, ctx) {
    const { items } = toRefs(props);
    const areItemsSelected = computed(() => props.selectedItems.length > 0);

    const {
      areMenuItemsShown,
      selectedItemRef,
      menuItemsRefs,
      searchValue,
      searchInputRef,
      searchInputConfig,
      moveNext,
      movePrev,
      closeMenu,
      toggleMenu,
      onClose,
      popupWidth,
      width,
    } = useDropdownHelper(items, ref(undefined), ctx);
    const labelWidth = computed(() => width.value - 22 + "px");

    const filteredItemsBySearch = computed(() => {
      return items.value.filter(
        (item: DropdownItem) =>
          String(item.value)
            .toLowerCase()
            .indexOf(`${searchValue.value.toLowerCase()}`) === 0
      );
    });

    const selectedItemsInfo = computed(
      () => `${props.selectedItems.length} selected`
    );

    const isItemSelected = (item: DropdownItem) =>
      props.selectedItems.indexOf(item.value) > -1;

    const onSelectMenuItem = (item: DropdownItem) => {
      const index = props.selectedItems.indexOf(item.value);
      let items = [...props.selectedItems];
      if (index === -1) {
        items.push(item.value);
      } else {
        items.splice(index, 1);
      }

      ctx.emit("change", items);
    };

    const onSearchInputUpdate = (value: string) => {
      searchValue.value = value;
    };

    return {
      areMenuItemsShown,
      selectedItemsInfo,
      areItemsSelected,
      selectedItemRef,
      menuItemsRefs,
      searchValue,
      searchInputRef,
      filteredItemsBySearch,
      searchInputConfig,
      isItemSelected,
      moveNext,
      movePrev,
      closeMenu,
      toggleMenu,
      onSelectMenuItem,
      onSearchInputUpdate,
      onClose,
      popupWidth,
      labelWidth,
    };
  },
});
</script>

<style scoped>
.custom-dropdown__dropdown-items {
  --dropdown-width: v-bind(popupWidth);
}
</style>
