<template>
  <router-view></router-view>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useEditorStore } from "@/stores/editor";

export default defineComponent({
  setup() {
    useEditorStore().initProjectsData();
  },
});
</script>
