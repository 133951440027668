import { computed, ref, type Ref } from "vue";
import { api } from "@/api";
import type { AxiosRequestConfig } from "axios";
import { useCommonEmailRegistrationStore } from "@cna/common";
import type {
  EmailRegistrationField,
  PostEmailRegistrationFieldInput,
  PostEnableEmailRegistrationFieldsInput,
  PostReorderEmailRegistrationFieldsInput,
  PutEmailRegistrationFieldInput,
} from "@cna/api/editor";

type SortedEmailRegistrationFields = {
  form_fields: EmailRegistrationField[];
  consent_checkboxes: EmailRegistrationField[];
  other: EmailRegistrationField[];
};

export const useEmailRegistrationStore = (
  currentProjectId: Ref<number | null>
) => {
  const commonEmailRegistration = useCommonEmailRegistrationStore();

  const allEmailRegistrationFields: Ref<EmailRegistrationField[]> = ref([]);

  const sortedEmailRegistrationFields = computed(() => {
    const sortedFields: SortedEmailRegistrationFields = {
      form_fields: [],
      consent_checkboxes: [],
      other: [],
    };
    const formFieldsTypes = ["email", "text", "phone", "dropdown"];
    const consentsCheckboxesTypes = ["checkbox"];
    const otherFieldsTypes = ["other_text"];

    allEmailRegistrationFields.value.forEach((field) => {
      if (formFieldsTypes.includes(field.kind)) {
        sortedFields.form_fields.push(field);
      }
      if (consentsCheckboxesTypes.includes(field.kind)) {
        sortedFields.consent_checkboxes.push(field);
      }
      if (otherFieldsTypes.includes(field.kind)) {
        sortedFields.other.push(field);
      }
    });

    return sortedFields;
  });

  const fetchAllProjectFields = async (config?: AxiosRequestConfig) => {
    commonEmailRegistration.allProjectFields = (
      await api.v1.projects
        .project(currentProjectId.value!)
        .emailRegistration.fields.get(config)
    ).data.result;
  };

  const fetchAllEmailRegistrationFields = async (
    config?: AxiosRequestConfig
  ) => {
    allEmailRegistrationFields.value = (
      await api.v1.projects
        .project(currentProjectId.value!)
        .emailRegistration.fields.getAll(config)
    ).data.result;
  };

  const reorderFields = async (
    orderedFields: PostReorderEmailRegistrationFieldsInput
  ) => {
    await api.v1.projects
      .project(currentProjectId.value!)
      .emailRegistration.fields.postReorder(orderedFields);

    await fetchAllProjectFields();
  };

  const updateField = async (
    fieldKey: string,
    item: PutEmailRegistrationFieldInput
  ) => {
    await api.v1.projects
      .project(currentProjectId.value!)
      .emailRegistration.fields.put(fieldKey, item);

    await fetchAllProjectFields();
  };

  const toggleField = async (fieldKey: string, isEnabled: boolean) => {
    await api.v1.projects
      .project(currentProjectId.value!)
      .emailRegistration.fields.postEnabled([
        {
          key: fieldKey,
          enabled: isEnabled,
        },
      ]);
    await fetchAllProjectFields();
    await fetchAllEmailRegistrationFields();
  };

  const toggleFields = async (
    fields: PostEnableEmailRegistrationFieldsInput
  ) => {
    await api.v1.projects
      .project(currentProjectId.value!)
      .emailRegistration.fields.postEnabled(fields);

    await fetchAllProjectFields();
    await fetchAllEmailRegistrationFields();
  };

  const deleteField = async (fieldKey: string) => {
    await api.v1.projects
      .project(currentProjectId.value!)
      .emailRegistration.fields.delete(fieldKey);

    await fetchAllEmailRegistrationFields();
  };

  const createNewField = async (data: PostEmailRegistrationFieldInput) => {
    await api.v1.projects
      .project(currentProjectId.value!)
      .emailRegistration.fields.post(data);
    await fetchAllEmailRegistrationFields();
  };

  return {
    allEmailRegistrationFields,
    sortedEmailRegistrationFields,
    fetchAllProjectFields,
    fetchAllEmailRegistrationFields,
    reorderFields,
    updateField,
    toggleField,
    toggleFields,
    deleteField,
    createNewField,
  };
};
