<template>
  <LanguageDropdown
    :items="dropdownOptions"
    :on-change="onSelectLanguage"
    :current-option-key="currentLanguageKey"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LanguageDropdown from "../../components/dropdown/LanguageDropdown.vue";
import { getInjectedHooks } from "../../hooks";

export default defineComponent({
  name: "WithLeftAlignedLogo",
  components: {
    LanguageDropdown,
  },
  setup: () => getInjectedHooks().useLanguageDropdown(),
});
</script>
