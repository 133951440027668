import { type Language } from "@cna/api/editor";
import { computed, type Ref } from "vue";
import { useEditorStore } from "@/stores/editor";
import { storeToRefs } from "pinia";

export default function useAvailableFoSelectionLanguageTags(
  selectedLanguages: Ref<Array<Language>>
) {
  const editorStore = useEditorStore();
  const { allLanguageTags } = storeToRefs(editorStore);

  const availableForSelectionLanguageTags = computed(() => {
    const selectedLanguagesTags = selectedLanguages.value.map(
      ({ lang_tag }) => lang_tag
    );
    const languages = Object.entries(allLanguageTags.value)
      .filter(([tag]) => {
        if (!selectedLanguagesTags.includes(tag)) {
          return tag;
        }
      })
      .map(([tag, value]) => ({
        value: value.isoname,
        key: tag,
      }))
      .sort((a, b) => a.value.localeCompare(b.value));

    return languages;
  });

  return {
    availableForSelectionLanguageTags,
  };
}
