<template>
  <CheckboxFormField
    v-if="!texts.login_text_terms_rich_text"
    :label="texts.login_text_terms_label"
  >
    <template #default>
      <component
        :is="termsLinkElementType"
        class="page__t-a-c-link"
        to="/terms-and-conditions"
      >
        {{ texts.login_text_terms_link }}
      </component>
    </template>
  </CheckboxFormField>
  <CommonCheckbox class="page__login_text_terms_rich_text" v-else>
    <Markdown
      :data="addRequiredFieldToHTML(texts.login_text_terms_rich_text)"
      field="login_text_terms_rich_text"
    />
  </CommonCheckbox>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import CommonButton from "../../../components/button/CommonButton.vue";
import CheckboxFormField from "../../../components/checkbox/CheckboxFormField.vue";
import CommonCheckbox from "../../../components/checkbox/CommonCheckbox.vue";
import EmailRegistrationFormElement from "../../../components/emailRegistrationFormElement/EmailRegistrationFormElement.vue";
import { useCommonGeneralStore } from "../../../stores/general";
import { addRequiredFieldToHTML } from "../../../utils/requiredFields";

export default defineComponent({
  name: "TermsAndConditionsCheckbox",
  components: {
    CheckboxFormField,
    CommonButton,
    CommonCheckbox,
    EmailRegistrationFormElement,
  },
  setup() {
    const commonGeneral = useCommonGeneralStore();
    const texts = computed(() => commonGeneral.bodyTexts);
    const termsLinkElementType = computed(
      () => commonGeneral.typesOfLinksBlocks.bodyTermsLink
    );

    return {
      texts,
      termsLinkElementType,
      addRequiredFieldToHTML,
    };
  },
});
</script>
