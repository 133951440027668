<template>
  <CommonCheckbox
    @update:checked="$emit('update:checked', $event)"
    :required="required"
    :field="field"
  >
    <div>
      <span>{{ label }} </span>
      <slot />
      <span v-if="required" class="required-field"> *</span>
    </div>
  </CommonCheckbox>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CommonCheckbox from "./CommonCheckbox.vue";

export default defineComponent({
  components: {
    CommonCheckbox,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: true,
    },
    field: {
      type: String,
      default: "",
    },
  },
  emits: {
    "update:checked": (_: boolean) => true,
  },
});
</script>
