import {
  inject,
  provide,
  ref,
  type ComputedRef,
  type InjectionKey,
  type Ref,
  type WritableComputedRef,
} from "vue";
import type { Maybe } from "../types";
import { capitalizeFirstLetter } from "../utils";

type UseProviderReturn1<K extends string, V> = {
  [_K in K as `provide${Capitalize<K>}`]: V;
};
type UseProviderReturn2<K extends string, V> = {
  [_K in K as `inject${Capitalize<K>}`]: V;
};

export const useProvider = <
  K extends string,
  P extends any[],
  R,
  O extends Maybe<{ default: R }>
>(
  key: K,
  fn: (...args: P) => R,
  opts?: O
) => {
  const injectionKey: InjectionKey<R> = Symbol(key);

  const p = (...args: P) => {
    provide(injectionKey, fn(...args));
  };

  const i = (): O extends undefined ? R | undefined : R => {
    // @ts-ignore
    return inject(injectionKey, opts ? opts.default : undefined);
  };

  return {
    [`provide` + capitalizeFirstLetter(key)]: p,
    [`inject` + capitalizeFirstLetter(key)]: i,
  } as UseProviderReturn1<K, typeof p> & UseProviderReturn2<K, typeof i>;
};

export const useBooleanProvider = <K extends string>(
  key: K,
  defaultValue: boolean
) =>
  useProvider(
    key,
    (
      bool: Ref<boolean> | ComputedRef<boolean> | WritableComputedRef<boolean>
    ) => bool,
    {
      default: ref(defaultValue),
    }
  );
