import { computed } from "vue";
import { useRoute } from "vue-router";

export const useIsPreview = () => {
  const route = useRoute();

  const isPreview = computed(() => route.meta.key === "preview");

  return isPreview;
};
