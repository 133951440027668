<template>
  <div v-if="!isDeletingRow" class="settings-section__row draggable">
    <span>{{ data.display_name }}</span>
    <div class="flex-row-center" v-if="projectformwrite">
      <EditorCheckbox
        v-if="isShowMandatoryCheckbox"
        class="settings-section__is-mandatory"
        title="Mandatory field"
        :value="data.required"
        @update:option="onChangeIsMandatory"
      />
      <span class="icon-delete" @click="isDeletingRow = true" />
      <span class="icon-reorder" />
    </div>
  </div>

  <div v-else class="settings-section__row">
    <SettingsConfirmationRow
      @cancel="onCancelConfirmation"
      @delete="onDeleteConfirmed"
    >
      You’re about to delete a field. Are you sure?
    </SettingsConfirmationRow>
  </div>
</template>

<script lang="ts">
import EditorCheckbox from "@/components/checkbox/EditorCheckbox.vue";
import { usePermissions } from "@/hooks/usePermissions";
import SettingsConfirmationRow from "@/routes/settings/sectionElements/SettingsConfirmationRow.vue";
import { useEditorStore } from "@/stores/editor";
import type {
  EmailRegistrationField,
  PostEmailRegistrationFieldInput,
} from "@cna/api/editor";
import { computed, defineComponent, ref, type PropType } from "vue";

export default defineComponent({
  name: "FormsSectionRow",
  components: {
    EditorCheckbox,
    SettingsConfirmationRow,
  },
  props: {
    data: {
      type: Object as PropType<EmailRegistrationField>,
      required: true,
    },
  },
  setup(props) {
    const { projectformwrite } = usePermissions();
    const editorStore = useEditorStore();

    const isDeletingRow = ref(false);
    const isShowMandatoryCheckbox = computed(
      () => props.data.kind !== "other_text"
    );
    const onChangeIsMandatory = (value: boolean) =>
      editorStore.updateField(props.data.key, {
        ...props.data,
        required: value,
      } as PostEmailRegistrationFieldInput);
    const enableFormElement = () => {
      editorStore.toggleField(props.data.key, false);
    };

    const onCancelConfirmation = () => {
      isDeletingRow.value = false;
    };

    const onDeleteConfirmed = () => {
      enableFormElement();
      isDeletingRow.value = false;
    };

    return {
      onChangeIsMandatory,
      isDeletingRow,
      onCancelConfirmation,
      onDeleteConfirmed,
      isShowMandatoryCheckbox,
      projectformwrite,
    };
  },
});
</script>

<style lang="scss" scoped>
.flex-row-center span {
  margin: 0 10px;
}

.settings-section__row {
  position: relative;
}

.icon-delete {
  font-size: 20px;
}

.icon-reorder {
  font-size: 24px;
  color: $grey14;
}

.settings-section__is-mandatory {
  margin-right: 29px;
}
</style>
