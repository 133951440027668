import { type FilterSettings } from "@/components/vehicleEventsFiltering/types";
import type {
  GetPublishedDomainsResult,
  VehicleEventsResult,
} from "@cna/api/editor";
import { type Project } from "@cna/api/editor";
import { INITIAL_FEATURES, INITIAL_TEMPLATES_KEYS } from "@cna/common";

export const INITIAL_PROJECT_VEHICLES_EVENTS: VehicleEventsResult["result"] = {
  events_list: [],
};

export const INITIAL_DOMAINS_DATA: GetPublishedDomainsResult["result"] = {
  domains: {
    main: "",
    preview: "",
  },
  suffix: "",
};

export const INITIAL_FILTER_SETTINGS: FilterSettings = {
  system_id: [],
  gvc_version: [],
  timestamp: undefined,
  published_by: [],
  content_version: [],
  status: "",
};

export const INITIAL_PROJECT: Project = {
  id: 0,
  name: "",
  templates: { ...INITIAL_TEMPLATES_KEYS },
  portal_env: "local",
  features: {
    ...INITIAL_FEATURES,
  },
  api_type: "",
  domains: {},
  icomera_account_id: 0,
  icomera_customer_id: 0,
  portal_class: "",
};
export const INITIAL_SWATCHES: string[] = ["#000000", "#FFFFFF"];
