<template>
  <div class="popover" @mouseenter="onMouseenter" @mouseleave="onMouseleave">
    <slot name="trigger" :open="openSync" />
    <Transition>
      <div v-if="openSync" :class="['pop-wrapper', position]">
        <slot :open="openSync" />
      </div>
    </Transition>
  </div>
</template>

<script lang="ts">
import { useVModel } from "@vueuse/core";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    hoverable: Boolean,
    position: {
      type: String,
      default: "left",
    },
    open: {
      type: Boolean,
    },
  },
  emits: {
    "update:open": (_: boolean) => true,
  },
  setup(props, ctx) {
    const openSync = useVModel(props, "open", ctx.emit, { passive: true });

    const onMouseenter = () => {
      if (props.hoverable) {
        openSync.value = true;
      }
    };

    const onMouseleave = () => {
      if (props.hoverable) {
        openSync.value = false;
      }
    };

    return {
      openSync,
      onMouseenter,
      onMouseleave,
    };
  },
});
</script>

<style lang="scss" scoped>
.popover {
  position: relative;
}

.popover .pop-wrapper {
  position: absolute;
  z-index: 20000001;
  top: 100%;
  padding-top: var(--ico-spacing-xxs);
}

.popover .pop-wrapper.left {
  left: 0px;
}
.popover .pop-wrapper.right {
  right: 0px;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 120ms ease-in-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
