<template>
  <footer class="footer" v-if="templateName !== ''">
    <component :is="templateName" />
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useTemplateNameForLayout } from "../hooks/useTemplateNameForLayout";
import { getTemplateComponent } from "./getTemplateComponent";

const footerComponent = <C extends string>(component: C) =>
  getTemplateComponent("footer", component);

export default defineComponent({
  name: "FooterLayout",
  components: {
    ...footerComponent("WithContactTermsPrivacy"),
    ...footerComponent("WithContactTermsPrivacyFAQCustomSelect"),
  },
  setup() {
    const { templateName } = useTemplateNameForLayout("footer");

    return {
      templateName,
    };
  },
});
</script>
